import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { ImageGrid, Heading2, Image } from '@tso/tso-components';

import { useAuth } from '../../hooks/index';
import { imagePreview } from '../../fragments/index';
import { InfiniteScroll, ImageActions } from '../index';
import { paginate } from '../../actions/index';
import { description } from '../../validation/index';

const SUGGESTED_IMAGES = gql`
  query suggestImagesForUser($page: Int) {
    suggestImagesForUser(page: $page) {
      data {
        ...ImagePreview
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${imagePreview}
`;

const POPULAR_IMAGES = gql`
  query popularImages($page: Int) {
    popularImages(page: $page) {
      data {
        ...ImagePreview
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${imagePreview}
`;

const Grid = ({ children, heading }) => (
  <ImageGrid
    header={
      <>
        <Heading2>{heading}</Heading2>
      </>
    }
    mb={3}>
    {children}
  </ImageGrid>
);

Grid.propTypes = {
  children: PropTypes.any.isRequired,
  heading: PropTypes.string
};

const SuggestedImages = ({ heading = 'Today’s picks', paged = true }) => {
  const { data, loading, error, fetchMore } = useQuery(SUGGESTED_IMAGES, {
    variables: { page: 0 },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.suggestImagesForUser.page;

    if (!hasNextPage || !paged) {
      return;
    }

    return fetchMore({
      variables: { page: currentPage + 1 },
      updateQuery: paginate('suggestImagesForUser')
    });
  };

  if (loading || error) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handleBottom}>
      <Grid heading={heading}>
        {data.suggestImagesForUser.data.map(
          ({ id, is_unidentified, src, revision, ...rest }) => (
            <Image
              Actions={ImageActions}
              key={id}
              id={id}
              name={revision.name}
              description={description(revision)}
              image={{ url: src }}
              unidentified={is_unidentified}
              orientation={revision.orientation}
              {...rest}
            />
          )
        )}
      </Grid>
    </InfiniteScroll>
  );
};

SuggestedImages.propTypes = {
  heading: PropTypes.string,
  paged: PropTypes.bool
};

const PopularImages = ({ heading = 'Today’s picks', paged = true }) => {
  const { data, loading, error, fetchMore } = useQuery(POPULAR_IMAGES, {
    variables: { page: 0 },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.popularImages.page;

    if (!hasNextPage || !paged) {
      return;
    }

    return fetchMore({
      variables: { page: currentPage + 1 },
      updateQuery: paginate('popularImages')
    });
  };

  if (loading || error) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handleBottom}>
      <Grid heading={heading}>
        {data.popularImages.data.map(
          ({ id, is_unidentified, src, revision, ...rest }) => (
            <Image
              Actions={ImageActions}
              key={id}
              id={id}
              name={revision.name}
              description={description(revision)}
              image={{ url: src }}
              sx={{ mb: 2 }}
              unidentified={is_unidentified}
              orientation={revision.orientation}
              {...rest}
            />
          )
        )}
      </Grid>
    </InfiniteScroll>
  );
};

PopularImages.propTypes = {
  heading: PropTypes.string,
  paged: PropTypes.bool
};

const TodaysPick = props => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <SuggestedImages {...props} />;
  }

  return <PopularImages {...props} />;
};

export default TodaysPick;
export { SuggestedImages, PopularImages, SUGGESTED_IMAGES, POPULAR_IMAGES };
