import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { Cards } from '@tso/tso-components';

import { useSearch } from '../../hooks/index';

const POPULAR_CATEGORIES = gql`
  query popularCategories($limit: Int) {
    popularCategories(limit: $limit) {
      id
      name
      image_count
    }
  }
`;

const PopularCategories = () => {
  const { data, loading, error } = useQuery(POPULAR_CATEGORIES, {
    variables: { limit: 6 },
    fetchPolicy: 'network-only'
  });
  const history = useHistory();

  const { searchCategory } = useSearch();

  if (loading || !data || error) {
    return null;
  }

  const handleCategoryClick = async event => {
    const id = event.target.id.split('card-')[1];
    await searchCategory({ variables: { category_id: parseInt(id) } });
    history.push('/results');
  };

  return (
    <Cards
      name="Popular categories"
      py={3}
      cards={data.popularCategories.map(({ name, id }) => ({
        id,
        onClick: handleCategoryClick,
        name
      }))}
    />
  );
};

export { POPULAR_CATEGORIES };
export default PopularCategories;
