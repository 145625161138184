import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

import authLink, { isUnauthorised, handleUnauthorised } from './auth';

const ERROR_MESSAGES = {
  INTERNAL_SERVER_ERROR:
    "Sorry we're having issues processing your request. Please try again later.",
  BAD_USER_INPUT:
    'There was a problem with your submission. Please make sure you have filled out all fields correctly',
  INVALID_IMAGE_FORMAT:
    'The format of your image is not supported. Please try and use JPEG or PNG formats',
  IMAGE_TOO_BIG:
    'Your image file size was too big. Please keep it within the size limits.',
  BAD_SIGNUP:
    'There was a problem with your submission. Have you already got an account?'
};

const canHandle = ([error]) =>
  Object.keys(ERROR_MESSAGES).includes(error.extensions.code);

const handleAlert = ([error], { cache }) => {
  cache.writeData({
    data: {
      alert: {
        __typename: 'Alert',
        status: 'warning',
        message:
          ERROR_MESSAGES[error.extensions.code] || 'Sorry there was a problem'
      }
    }
  });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      // Log the user out.
      if (
        isUnauthorised(graphQLErrors) &&
        operation.operationName !== 'login'
      ) {
        if (response) {
          response.errors = null;
        }

        return handleUnauthorised(operation.getContext());
      }

      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      // Globally handle notifications.
      if (canHandle(graphQLErrors)) {
        if (response) {
          response.errors = null;
        }

        handleAlert(graphQLErrors, operation.getContext());
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
});

export default ApolloLink.from([errorLink, authLink, uploadLink]);

export { uploadLink, ERROR_MESSAGES, handleAlert };
