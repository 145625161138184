import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { Button } from '@tso/tso-components';

const UNFOLLOW_COLLECTION = gql`
  mutation unfollowCollection($id: Int!) {
    unfollowCollection(id: $id) {
      id
      following
    }
  }
`;

const UnfollowButton = ({ id }) => {
  const [unfollow, { loading }] = useMutation(UNFOLLOW_COLLECTION, {
    variables: { id }
  });

  return (
    <Button isLoading={loading} onClick={unfollow} appearance="secondary">
      Unfollow
    </Button>
  );
};

UnfollowButton.propTypes = {
  id: PropTypes.number.isRequired
};

export default UnfollowButton;
export { UNFOLLOW_COLLECTION };
