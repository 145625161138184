import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import AdminDelete from './AdminDelete';

const DELETE_COLLECTIONS = gql`
  mutation deleteCollections($ids: [Int!]!) {
    deleteCollections(ids: $ids)
  }
`;

const AdminDeleteCollection = ({ ids, ...rest }) => {
  const [deleteCollections] = useMutation(DELETE_COLLECTIONS, {
    refetchQueries: ['collections', 'privateCollections', 'userCollections']
  });

  return (
    <AdminDelete
      ids={ids}
      query={deleteCollections}
      type="collection"
      {...rest}
    />
  );
};

export default AdminDeleteCollection;
