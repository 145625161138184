import React from 'react';

import { Box } from '@theme-ui/components';
import PropTypes from 'prop-types';

const boxStyle = {
  height: 'calc(100vh - 150px)',
  display: 'flex',
  minHeight: 400,
  flexDirection: 'column',
  justifyContent: 'center'
};

const CenteredBox = ({ __css, variant = 'layout.form', ...props }) => (
  <Box variant={variant} __css={{ ...boxStyle, ...__css }} {...props} />
);

CenteredBox.propTypes = {
  __css: PropTypes.object,
  variant: PropTypes.string
};

export default CenteredBox;
