import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import GoogleAnalytics from 'react-ga';
import { ThemeProvider } from 'theme-ui';
import { Flex } from '@theme-ui/components';
import { BrowserRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import axios from 'axios';

import { defaultTheme as theme } from '@tso/tso-components';

import App from './App';
import { GoogleProvider } from './Providers/index';
import * as serviceWorker from './serviceWorker';

dayjs.extend(relativeTime);

if (process.env.REACT_APP_GA_ID) {
  GoogleAnalytics.initialize(process.env.REACT_APP_GA_ID);
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Flex
      __css={{ flexDirection: 'column', width: '100%', minHeight: '100vh' }}>
      <GoogleProvider>
        <Router>
          <App />
        </Router>
      </GoogleProvider>
    </Flex>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
