import { WebSocketLink } from 'apollo-link-ws';

const setToken = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return {};
  }

  return {
    Authorization: `Bearer ${token}`
  };
};

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SUBSCRIPTIONS_URL,
  options: {
    // Connect on first usage of subscriptions.
    // Hacky fix to prevent setToken() to be called when the user is
    // unauthed.
    lazy: true,
    reconnect: true,
    connectionParams: setToken
  }
});

export default wsLink;
export { setToken };
