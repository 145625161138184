import React from 'react';

const TableHeading = ({ children, ...rest }) => (
  <th
    style={{ verticalAlign: 'middle', lineHeight: '48px', padding: '0.5rem' }}
    {...rest}>
    {children}
  </th>
);

export default TableHeading;
