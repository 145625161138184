import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { Button } from '@tso/tso-components';

const FOLLOW_COLLECTION = gql`
  mutation followCollection($id: Int!) {
    followCollection(id: $id) {
      id
      following
    }
  }
`;

const FollowButton = ({ id }) => {
  const [follow, { loading }] = useMutation(FOLLOW_COLLECTION, {
    variables: { id }
  });

  return (
    <Button isLoading={loading} onClick={follow}>
      Follow
    </Button>
  );
};

FollowButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default FollowButton;
export { FOLLOW_COLLECTION };
