import gql from 'graphql-tag';

const imageResult = gql`
  fragment ImageResult on Image {
    id
    file_name
    src
    high_quality_src
    is_unidentified
    revision {
      name
      description
      date
      day
      month
      year
      year_start
      year_end
      building_number
      street_address_1
      street_address_2
      city
      region
      postcode
      country
      latitude
      longitude
      credit
      orientation
    }
    user {
      id
    }
  }
`;

const imagePreview = gql`
  fragment ImagePreview on Image {
    id
    src
    high_quality_src
    is_unidentified
    revision {
      name
      description
      date
      day
      month
      year
      year_start
      year_end
      building_number
      street_address_1
      street_address_2
      city
      region
      postcode
      country
      orientation
    }
    user {
      id
    }
  }
`;

const imageDetail = gql`
  fragment ImageDetail on Image {
    id
    file_name
    src
    high_quality_src
    is_unidentified
    revision {
      name
      description
      date
      day
      month
      year
      year_start
      year_end
      building_number
      street_address_1
      street_address_2
      city
      region
      postcode
      country
      credit
      orientation
      latitude
      longitude
    }
    user {
      id
      name
      email
      profile_name
      profile_src
    }
  }
`;

const imageEdit = gql`
  fragment ImageEdit on Image {
    id
    is_unidentified
    file_name
    src
    high_quality_src
    revision {
      name
      credit
      description
      date
      day
      month
      year
      year_start
      year_end
      building_number
      street_address_1
      street_address_2
      city
      region
      postcode
      country
      latitude
      longitude
      orientation
    }
  }
`;

export { imageResult, imagePreview, imageDetail, imageEdit };
