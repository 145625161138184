import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory as useBrowserHistory
} from 'react-router-dom';
import PropTypes from 'prop-types';
import GoogleAnalytics from 'react-ga';

import { PrivateRoute, AdminRoute } from '../Components/index';
import { useHistory } from '../hooks/index';
import appRoutes from './app';
import adminRoutes from './admin';
import authRoutes from './auth';

const routes = [...appRoutes, ...adminRoutes, ...authRoutes];

const TsoRoute = ({ auth = false, admin = false, ...props }) => {
  if (admin) {
    return <AdminRoute {...props} />;
  }

  if (auth) {
    return <PrivateRoute {...props} />;
  }

  return <Route {...props} />;
};

TsoRoute.propTypes = {
  needAuth: PropTypes.bool,
  admin: PropTypes.bool
};

const Routes = () => {
  const history = useBrowserHistory();
  const { addToHistory } = useHistory();

  /**
   * Keep an app store of the users history. Allowing us to
   * programmatically redirect to previous places in the app.
   */
  useEffect(() => {
    history.listen(({ pathname }) => addToHistory(pathname));
  }, [history, addToHistory]);

  /**
   * Track each page view in Google Analytics on route change.
   */
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      history.listen(({ pathname }) => GoogleAnalytics.pageview(pathname));
    }
  }, [history]);

  /**
   * Track the initial page in Google Analytics.
   */
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      GoogleAnalytics.pageview(window.location.pathname);
    }
  }, []);

  return (
    <Switch>
      {routes.map((route, key) => (
        <TsoRoute key={key} exact {...route} />
      ))}
    </Switch>
  );
};

export default Routes;
