import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

const SearchArea = ({ children, ariaLabel }) => {
  return (
    <Box
      aria-label={ariaLabel}
      __css={{
        position: 'absolute',
        bg: 'background',
        boxShadow: 'default',
        top: '100%',
        left: 0,
        right: 0,
        zIndex: 1
      }}>
      {children}
    </Box>
  );
};

SearchArea.propTypes = {
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string
};

export default SearchArea;
