"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* eslint prefer-destructuring: 0 */
var fontSizes = [12, 14, 16, 20, 24, 28, 32, 40];
fontSizes.body = fontSizes[2];
var _default = fontSizes;
exports["default"] = _default;