import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Box } from '@theme-ui/components';

import {
  Button,
  Feedback,
  Field,
  Heading4,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ImageRotate,
  ModalHeader,
  Textarea,
  ActionButton
} from '@tso/tso-components';

import { useAlert, useDate } from '../../hooks/index';
import { GoogleSearch, WhitelistedCategories, DatePicker } from '../index';
import { EDIT_IMAGE } from './EditImage';
import { getAddress } from '../Address';
import { GET_IMAGE } from '../../Pages/App/Enrichment';
import { length } from '../../validation/index';

const ImageDetail = ({ handleImageEdit, revision, toggleModal, ...image }) => {
  const [name, setName] = useState(revision.name || '');
  const [description, setDescription] = useState(revision.description || '');
  const [credit, setCredit] = useState(revision.credit || '');
  const [orientation, setOrientation] = useState(revision.orientation || 0);
  const [category, setCategory] = useState(null);
  const [location, setLocation] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const { date, builtDate, setDate } = useDate(revision);

  const handleNameChange = event => setName(event.target.value);

  const handleDescriptionChange = event => setDescription(event.target.value);

  const handleCategoryChange = ({ value }) => setCategory(value);

  const handleLocationChange = value => setLocation(value);

  const handleOrientationChange = value => setOrientation(value);

  const handleCreditChange = event => setCredit(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();

    const { valid, error } = length(name, {
      min: 2,
      max: 100,
      type: 'Name'
    });

    if (!valid) {
      return setErrorMessage(error);
    }

    handleImageEdit({
      name,
      description,
      orientation,
      credit: credit || null,
      category_id: category || null,
      place_id: location ? location.value : null,
      ...builtDate
    });
  };

  const valid = Boolean(name);

  return (
    <>
      <ModalBody>
        <Box mb={4}>
          <ImageRotate
            {...image}
            orientation={orientation}
            setOrientation={handleOrientationChange}
          />
        </Box>

        <Field
          name="photo_name"
          label="Name"
          hideLabel
          placeholder="Photo name"
          onChange={handleNameChange}
          value={name}
          mb={3}
        />
        {errorMessage && <Feedback>{errorMessage}</Feedback>}

        <Field
          as={Textarea}
          name="photo_description"
          label="Description"
          hideLabel
          placeholder="Photo description"
          onChange={handleDescriptionChange}
          value={description}
          mb={3}
        />

        <Field
          name="credit"
          label="Credit"
          hideLabel
          placeholder="Photographer Name"
          onChange={handleCreditChange}
          value={credit}
          mb={3}
        />

        <Box mb={4}>
          <WhitelistedCategories
            defaultText="No Category"
            value={category}
            handleChange={handleCategoryChange}
          />
        </Box>

        <Box mb={4}>
          <Heading4 as="h2">Location</Heading4>
          <GoogleSearch
            defaultInputValue={getAddress(revision).join(', ')}
            onChange={handleLocationChange}
          />
        </Box>

        <Box>
          <Heading4 as="h2">Date</Heading4>
          <DatePicker date={date} setDate={setDate} />
        </Box>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleSubmit} isDisabled={!valid}>
          Submit
        </Button>
        <Button onClick={toggleModal} appearance="link">
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

ImageDetail.propTypes = {
  handleImageEdit: PropTypes.func.isRequired,
  revision: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
    year_start: PropTypes.string,
    year_end: PropTypes.string,
    orientation: PropTypes.number
  })
};

const EditImage = ({ id, handleImageEdit, ...props }) => {
  const { data, loading, error } = useQuery(GET_IMAGE, { variables: { id } });

  if (loading || error) {
    return null;
  }

  return (
    <ImageDetail {...data.image} handleImageEdit={handleImageEdit} {...props} />
  );
};

EditImage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleImageEdit: PropTypes.func.isRequired
};

const EditImageModal = ({ id, buttonType = 'listItem' }) => {
  const [open, setOpen] = useState(false);

  const { showSuccess } = useAlert();

  const [editImage] = useMutation(EDIT_IMAGE, {
    refetchQueries: ['userImages', 'images']
  });

  const toggleModal = () => setOpen(open => !open);

  const handleImageEdit = async variables => {
    await editImage({ variables: { id, ...variables } });
    toggleModal();
    showSuccess('Successfully updated image');
  };

  return (
    <>
      <Modal isOpen={open} toggle={toggleModal} disableClickOff>
        <ModalHeader toggle={toggleModal}>Edit photo details</ModalHeader>
        <EditImage
          id={id}
          handleImageEdit={handleImageEdit}
          toggleModal={toggleModal}
        />
      </Modal>

      {buttonType === 'actionButton' && (
        <ActionButton
          icon="edit"
          onClick={toggleModal}
          label="Edit photo information">
          Edit photo information
        </ActionButton>
      )}
      {buttonType === 'listItem' && (
        <ListItem onClick={toggleModal}>Edit image</ListItem>
      )}
    </>
  );
};

EditImageModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  buttonType: PropTypes.string
};

export default EditImageModal;
export { GET_IMAGE, EditImage, ImageDetail };
