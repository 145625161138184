import { GET_SEARCH } from '../../hooks/useSearch.js';
import { defaultCache } from '../cache';

const updateSearch = (_root, variables, { cache }) => {
  const { search } = cache.readQuery({ query: GET_SEARCH });

  const newSearch = { ...search };

  // Replace search params with new values.
  for (const key in variables) {
    if (variables[key] !== undefined) {
      newSearch[key] = variables[key];
    }
  }

  // Update search params.
  cache.writeData({
    data: {
      search: {
        __typename: 'Search',
        ...newSearch
      }
    }
  });

  return null;
};

const searchLocation = (_root, newLocation, { cache }) => {
  cache.writeData({
    data: {
      search: {
        ...defaultCache().search,
        ...newLocation
      }
    }
  });

  return null;
};

const searchCategory = (_root, { category_id }, { cache }) => {
  cache.writeData({
    data: {
      search: {
        ...defaultCache().search,
        category_id
      }
    }
  });

  return null;
};

export { updateSearch, searchLocation, searchCategory };
