import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@theme-ui/components';
import { useQuery } from '@apollo/react-hooks';

import { Heading2, Promo2, Button, Cards } from '@tso/tso-components';

import { TodaysPick } from '../Image/index';
import { POPULAR_LOCATIONS } from '../Search/SearchPopularLocations';
import { useSearch } from '../../hooks/index';

const NoResults = ({ input }) => {
  const { updateSearch } = useSearch();

  const { data, loading, error } = useQuery(POPULAR_LOCATIONS, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  const handleClick = name => () => {
    // Search for the popular location, using the global search state.
    updateSearch({ variables: { global_input: name } });
  };

  return (
    <Box variant="layout.baz">
      <Box sx={{ maxWidth: 960, mx: 'auto', mb: 4 }}>
        <Heading2 as="h1">
          No results for {input ? `${input}` : 'your search'}
        </Heading2>

        <Promo2
          name={`Have some photos ${input ? `of ${input}?` : 'to help?'}`}
          description="Submit them and help us cover more places."
          mb={3}>
          <Button as={Link} to="/uploads">
            Add photos
          </Button>
        </Promo2>
      </Box>
      <Cards
        name={'Discover places nearby'}
        cards={data.popularLocations.slice(0, 3).map(({ name, hits }) => ({
          name,
          images: { total: hits },
          onClick: handleClick(name)
        }))}
        mb={3}
      />
      <TodaysPick heading={'Popular Content'} subHeading={''} />
    </Box>
  );
};

NoResults.propTypes = {
  input: PropTypes.string.isRequired
};

export default NoResults;
