import gql from 'graphql-tag';

const loggedInUser = gql`
  fragment LoggedInUser on User {
    id
    name
    email
    is_admin
    website_url
    profile_name
    profile_src
  }
`;

export { loggedInUser };
