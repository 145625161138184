"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var sizes = {
  form: 500,
  container: '100%',
  xxl: 2380,
  modal: {
    small: 375,
    medium: 575
  },
  headerHeightXXS: 93,
  headerHeightXS: 55,
  dropdown: {
    menu: {
      small: {
        width: 150
      },
      medium: {
        width: 200
      },
      large: {
        width: 320
      }
    }
  }
};
var _default = sizes;
exports["default"] = _default;