import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import gql from 'graphql-tag';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Text,
  ListItem
} from '@tso/tso-components';

import { useAlert } from '../../hooks/index';

const DELETE_IMAGE = gql`
  mutation deleteImage($id: Int!) {
    deleteImage(id: $id)
  }
`;

const DeleteImage = ({ id }) => {
  const [modal, setModal] = useState(false);

  const { showSuccess } = useAlert();

  const location = useLocation();
  const history = useHistory();
  const [deleteImage] = useMutation(DELETE_IMAGE, {
    refetchQueries: [
      'image',
      'userImages',
      'images',
      'unidentified',
      'suggestImagesForUser',
      'popularImages',
      'period'
    ]
  });

  const handleDeleteImage = async () => {
    await deleteImage({ variables: { id } });

    if (location.pathname === `/images/${id}`) {
      history.push('/profile');
    }

    showSuccess('Photo deleted successfully');
    toggleModal();
  };

  const toggleModal = () => setModal(modal => !modal);

  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Are you sure you want to delete this photo?
        </ModalHeader>
        <ModalBody>
          <Text>
            By deleting this request, you will lose all the comments and
            contributions attached to this image and it will not be discoverable
            on the platform anymore.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleDeleteImage}>Delete</Button>
          <Button onClick={toggleModal} appearance="link">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ListItem onClick={toggleModal}>Delete</ListItem>
    </>
  );
};

DeleteImage.propTypes = {
  id: PropTypes.number.isRequired
};

export default DeleteImage;
export { DELETE_IMAGE };
