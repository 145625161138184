import React from 'react';
import { BaseStyles } from 'theme-ui';

import { Box } from '@theme-ui/components';
import { Container, Heading1, Heading3, Img } from '@tso/tso-components';

import { PopularImages } from '../../Components/Image/TodaysPick';
import { Meta } from '../../Components/index';

const About = () => (
  <>
    <Meta title="About" />
    <Box as="article" variant="layout.baz">
      <Container variant="large">
        <Box
          sx={{
            display: [null, null, 'flex'],
            justifyContent: [null, null, 'space-between']
          }}>
          <Box
            sx={{
              maxWidth: [null, null, 490]
            }}>
            <header>
              <Heading3 as="h1">About</Heading3>
              <Heading1 as="h2">
                The Story Of is a living visual history of the places that shape
                our lives.
              </Heading1>
            </header>
            <BaseStyles>
              <p>
                The Story Of is a celebration of our heritage you help us build
                by exploring, sharing and discussing vintage images of the
                locations that mean most to you.
              </p>

              <p>
                From iconic images of well-known landmarks to previously unseen
                snaps that have been hidden in attics for years, your photos
                will form a tapestry that tells The Story Of the world around
                us, bringing places to life through time.
              </p>

              <p>
                Join in by scanning and uploading your own archive photographs
                and help us in our mission to create the life story of any
                place, from a town to the butcher&apos;s shop on its high
                street.
              </p>

              <p>
                Before we officially launch The Story Of, we are asking for
                people who are passionate about historical photography to share
                their images from days gone by in the platform. We would like to
                have 2,000 contributions from local people across the county
                before we go live, so that when we release TSO online users are
                immediately greeted by rich and recognisable photos that conjure
                up memories of the past.
              </p>

              <p>
                With your help, TSO will become an entirely new way to explore
                history and a compelling resource for those interested in
                history and genealogy, or those curious to explore history in an
                entirely new way.
              </p>
              <p>&#169; 2020 Archant Community Media Ltd</p>
            </BaseStyles>
          </Box>
          <Box sx={{ maxWidth: [null, null, 588], pl: [null, null, 3] }}>
            <Img
              src="https://res.cloudinary.com/lance-guyatt/image/upload/v1586201078/about_odsxps.jpg"
              sx={{ display: 'block', width: '100%' }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
    <PopularImages heading={'Start exploring'} subHeading={''} />
  </>
);

export default About;
