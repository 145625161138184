import {
  Login,
  Logout,
  Signup,
  RequestNewPassword,
  ResetPassword
} from '../Pages/Auth/index';

const routes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/signup', component: Signup },
  { path: '/password/request', component: RequestNewPassword },
  { path: '/password/reset', component: ResetPassword }
];

export default routes;
