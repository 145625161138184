import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

import {
  Button,
  Feedback,
  Field,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Textarea
} from '@tso/tso-components';

const GET_REPORTABLE_PROBLEMS = gql`
  query problems($type: String!) {
    problems(type: $type) {
      id
      name
    }
  }
`;

const Report = ({
  handleReport,
  header = 'Report inappropriate content',
  submitText = 'Report',
  reportType,
  Submit = Button
}) => {
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState({ problem: '', message: '' });
  const [errorMessage, setErrorMessage] = useState();

  const { data, loading, error } = useQuery(GET_REPORTABLE_PROBLEMS, {
    variables: { type: reportType }
  });

  const handleMessageChange = event =>
    setReport({ ...report, message: event.target.value });

  const handleProblemSelect = problem => {
    setReport({ ...report, problem: problem });
  };

  const toggleModal = () => setOpen(open => !open);

  const handleSubmit = async () => {
    if (report.message.length < 1) {
      return setErrorMessage('Please enter a report message');
    }

    if (!report.problem.value) {
      return setErrorMessage('Please select a problem');
    }

    await handleReport(report.message, report.problem.value);
    toggleModal();
  };

  if (loading || error) {
    return null;
  }

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
        <ModalBody>
          <Box mb={3}>
            <Select
              id="problems-select"
              name="problems"
              aria-label="problems"
              value={report.problem_id}
              onChange={handleProblemSelect}
              options={data.problems.map(({ id, name }) => ({
                label: name,
                value: id
              }))}
            />
          </Box>

          <Field
            as={Textarea}
            name="report_message"
            label="Report Message"
            hideLabel
            placeholder="Explain why you would like to report this content. We will review it and make sure it is best dealt with."
            onChange={handleMessageChange}
            value={report.message}>
            {errorMessage && (
              <Feedback status="warning" mt={2}>
                {errorMessage}
              </Feedback>
            )}
          </Field>
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleSubmit}>Report</Button>
          <Button onClick={toggleModal} appearance="link">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Submit onClick={toggleModal}>{submitText}</Submit>
    </div>
  );
};

Report.propTypes = {
  handleReport: PropTypes.func.isRequired,
  header: PropTypes.string,
  submitText: PropTypes.string,
  reportType: PropTypes.string.isRequired,
  Submit: PropTypes.any
};

export default Report;
export { GET_REPORTABLE_PROBLEMS };
