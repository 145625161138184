import gql from 'graphql-tag';

const userCollection = gql`
  fragment UserCollection on Collection {
    id
    name
    description
    is_private
    header {
      id
      file_name
      src
      high_quality_src
      revision {
        orientation
      }
    }
    user {
      id
      name
      email
      profile_name
      profile_src
    }
  }
`;

const detailedCollection = gql`
  fragment DetailedCollection on Collection {
    id
    name
    description
    is_private
    following
    header {
      id
      file_name
      src
      high_quality_src
      revision {
        orientation
      }
    }
    user {
      id
      name
      email
      profile_name
      profile_src
    }
  }
`;

export { userCollection, detailedCollection };
