import React, { useState } from 'react';
import { Flex } from '@theme-ui/components';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { ImageGrid, Image, Text0Bold, Text1Bold } from '@tso/tso-components';

import SortBy, { options } from '../SortBy';
import { InfiniteScroll, ImageActions } from '../index';
import { imageResult } from '../../fragments/index';
import { paginate } from '../../actions/index';
import { description } from '../../validation/index';

const GET_USER_IMAGES = gql`
  query userImages($id: Int!, $page: Int, $sort: Sort) {
    userImages(id: $id, page: $page, sort: $sort) {
      data {
        ...ImageResult
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${imageResult}
`;

const UserImages = ({ id }) => {
  const [sort, setSort] = useState({ ...options[0] });

  const { data, loading, error, fetchMore } = useQuery(GET_USER_IMAGES, {
    variables: { id, sort: sort.value },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.userImages.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id, page: currentPage + 1 },
      updateQuery: paginate('userImages')
    });
  };

  const handleSort = option => setSort(option);

  if (loading || error) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handleBottom}>
      {data.userImages.data.length > 0 && (
        <Flex
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            py: 2,
            px: 3,
            mb: 3
          }}>
          <Text0Bold>{data.userImages.total} photos</Text0Bold>
          <Flex sx={{ alignItems: 'center' }}>
            <Text1Bold sx={{ mr: 2 }}>Sort by</Text1Bold>
            <SortBy value={sort} handleChange={handleSort} />
          </Flex>
        </Flex>
      )}
      <ImageGrid>
        {data.userImages.data.map(
          ({ id, is_unidentified, src, revision, ...rest }) => (
            <Image
              {...rest}
              Actions={ImageActions}
              unidentified={is_unidentified}
              id={id}
              name={revision.name}
              description={description(revision)}
              image={{ url: src }}
              key={id}
              isPublic={true}
              orientation={revision.orientation}
            />
          )
        )}
      </ImageGrid>
    </InfiniteScroll>
  );
};

UserImages.propTypes = {
  id: PropTypes.number.isRequired
};

export default UserImages;
export { GET_USER_IMAGES };
