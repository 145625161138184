import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  List,
  ListItem
} from '@tso/tso-components';

import { POPULAR_CATEGORIES } from './PopularCategories';

const ResultsCategories = ({
  block,
  right,
  handleChange,
  value = null,
  defaultText = 'All Categories'
}) => {
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown(prevState => !prevState);

  const { loading, error, data } = useQuery(POPULAR_CATEGORIES, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  const categoryValues = [
    { id: null, name: defaultText },
    ...data.popularCategories
  ];

  const selected = categoryValues.find(({ id }) => id === value);

  const handleSelect = id => {
    handleChange(id);
    toggleDropdown();
  };

  return (
    <Dropdown isOpen={dropdown} toggle={toggleDropdown} block={block}>
      <DropdownToggle block={block}>{selected.name}</DropdownToggle>
      <DropdownMenu right={right} block={block}>
        <List>
          {categoryValues.map(({ id, name }) => (
            <ListItem key={name} onClick={() => handleSelect(id)}>
              {name}
            </ListItem>
          ))}
        </List>
      </DropdownMenu>
    </Dropdown>
  );
};

ResultsCategories.propTypes = {
  block: PropTypes.bool,
  right: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  defaultText: PropTypes.string
};

export default ResultsCategories;
