import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

import { Container } from '@tso/tso-components';

const AuthContainer = ({ children }) => (
  <Box
    __css={{
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      py: [2, 4]
    }}>
    <Container>{children}</Container>
  </Box>
);

AuthContainer.propTypes = {
  children: PropTypes.any.isRequired
};

export default AuthContainer;
