import { useMemo, useCallback } from 'react';

import { useGoogle } from '../hooks/index';
import { boundary, locations } from '../data/index';

const isWhitelisted = (description = '') => {
  return locations.some(location => {
    return description.toLowerCase().includes(location.toLowerCase());
  });
};

/**
 * The maximum distance (in meters) a search result can
 * be from the center of Norfolk.
 */
const MAX_DISTANCE = 46000;

/**
 * Lat and long of the center of Norfolk.
 */
const NOROFLK_CENTER = [52.614, 0.8864];

const useFuzzyLocation = () => {
  const google = useGoogle();

  const { places, bounds, token, origin } = useMemo(
    () => ({
      places: new google.places.AutocompleteService(),
      bounds: new google.LatLngBounds(
        new google.LatLng(boundary[0], boundary[1]),
        new google.LatLng(boundary[2], boundary[3])
      ),
      origin: new google.LatLng(...NOROFLK_CENTER),
      token: new google.places.AutocompleteSessionToken()
    }),
    [google]
  );

  const predict = useCallback(
    input =>
      new Promise(resolve => {
        places.getPlacePredictions(
          {
            input,
            bounds,
            origin,
            componentRestrictions: { country: 'uk' },
            sessionToken: token
          },
          item => resolve(item)
        );
      }),
    [places, bounds, token, origin]
  );

  const search = useCallback(
    async input => {
      const results = await predict(input);

      if (!results) {
        return [];
      }

      return (
        results
          // Filter out any results not within the range of Norfolk.
          // We currently are biased towards Norfolk, but the Google API
          // doesn't currently support completely restricting to a location.
          // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
          .filter(({ distance_meters, description = '' }) => {
            // If Google gives us a distance from the origin
            // (Norwich) and is within our limit, let it through.
            if (distance_meters && distance_meters < MAX_DISTANCE) {
              return true;
            }

            // Google doesn't always give us a distance, so fallback
            // to a whitelist of all Norfolk locations
            return isWhitelisted(description);
          })
          .map(({ place_id, description }) => ({
            label: description,
            value: place_id
          }))
      );
    },
    [predict]
  );

  return { predict, search };
};

export default useFuzzyLocation;
export { MAX_DISTANCE, NOROFLK_CENTER };
