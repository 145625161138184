import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Flex, Box } from '@theme-ui/components';

import {
  Dropdown,
  DropdownToggle,
  IconButton,
  DropdownMenu,
  Notification as DumbNotification,
  Link0Medium
} from '@tso/tso-components';

import { useAuth } from '../hooks/index';
import { userNotification } from '../fragments/index';
import { paginate } from '../actions/index';
import InfiniteScroll from './InfiniteScroll';

const USER_NOTIFIED = gql`
  subscription userNotified {
    userNotified {
      ...UserNotification
    }
  }
  ${userNotification}
`;

const GET_NOTIFICATIONS = gql`
  query notifications($page: Int) {
    notifications(page: $page) {
      data {
        ...UserNotification
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${userNotification}
`;

const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation markAsRead($ids: [Int!]!) {
    markAsRead(ids: $ids) {
      ...UserNotification
    }
  }
  ${userNotification}
`;

const CLEAR_NOTIFICATIONS = gql`
  mutation clearNotifications {
    clearNotifications {
      id
    }
  }
`;

const NotificationWrapper = () => {
  const { isGuest } = useAuth();

  if (isGuest) {
    return null;
  }

  return <Notification />;
};

const Notification = () => {
  const [open, setOpen] = useState(null);

  const {
    data,
    error,
    loading,
    fetchMore,
    refetch,
    subscribeToMore
  } = useQuery(GET_NOTIFICATIONS, {
    variables: { page: 0 },
    fetchPolicy: 'network-only'
  });

  const [markAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);
  const [clearNotifications] = useMutation(CLEAR_NOTIFICATIONS);

  const toggleOpen = async () => {
    // On close, mark any notifications as read.
    if (open) {
      const unreadNotifications = data.notifications.data
        .filter(({ seen }) => !seen)
        .map(({ id }) => id);

      if (unreadNotifications.length > 0) {
        await markAsRead({ variables: { ids: unreadNotifications } });
      }
    }

    setOpen(!open);
  };

  const handleBottom = useCallback(async () => {
    await markAsRead({
      variables: { ids: data.notifications.data.map(({ id }) => id) }
    });

    const { currentPage, hasNextPage } = data.notifications.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { page: currentPage + 1 },
      updateQuery: paginate('notifications')
    });
  }, [data, markAsRead, fetchMore]);

  useEffect(() => {
    subscribeToMore({ document: USER_NOTIFIED, updateQuery: refetch });
  }, [subscribeToMore, refetch]);

  if (error || loading) {
    return null;
  }

  const hasUnreadNotifications = Boolean(
    data.notifications.data.find(({ seen }) => !seen)
  );

  const handleClearNotifications = async () => {
    const { data } = await clearNotifications();

    if (data) {
      refetch();
    }
  };

  return (
    <Dropdown isOpen={open} toggle={toggleOpen}>
      <Box
        sx={{
          position: 'relative',
          ...(hasUnreadNotifications && {
            '&::before': {
              content: '""',
              display: 'block',
              size: 6,
              borderRadius: 'circle',
              position: 'absolute',
              top: '6px',
              right: 2,
              bg: 'primary',
              zIndex: 1
            }
          })
        }}>
        <DropdownToggle
          as={IconButton}
          icon="bell"
          appearance={open ? 'tertiaryActive' : 'tertiary'}
          label="Notificatons"
        />
        <DropdownMenu size="large" right>
          <Flex
            sx={{
              px: 2,
              py: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: 1,
              borderBottomColor: 'gray.4'
            }}>
            <Flex sx={{ alignItems: 'center' }}>
              <Box sx={{ fontWeight: 'bold', mr: 1, flex: 'none' }}>
                Notifications
              </Box>
              <Box
                sx={{
                  fontSize: 12,
                  fontWeight: 'regular',
                  flex: '0 1 auto'
                }}>
                {data.notifications.total} new
              </Box>

              {data.notifications.total > 0 && (
                <Link0Medium
                  style={{ marginLeft: '0.5rem' }}
                  as="button"
                  onClick={handleClearNotifications}
                  variant="link0.medium"
                  color="primary">
                  Clear
                </Link0Medium>
              )}
            </Flex>
            <IconButton icon="close" onClick={toggleOpen} appearance="muted" />
          </Flex>
          <InfiniteScroll
            useWindow={false}
            onBottom={handleBottom}
            style={{ height: '300px' }}>
            {data.notifications.data.map(
              ({ image_src, trigger, ...notification }) => (
                <DumbNotification
                  key={notification.id}
                  {...notification}
                  image={{ url: image_src }}
                  user={trigger}
                />
              )
            )}
          </InfiniteScroll>
        </DropdownMenu>
      </Box>
    </Dropdown>
  );
};

export default NotificationWrapper;
export { USER_NOTIFIED, MARK_NOTIFICATIONS_AS_READ, GET_NOTIFICATIONS };
