"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var appearances = {
  primary: {
    bg: 'primary',
    borderColor: 'primary',
    color: 'background',
    '&:focus, &:hover, &:active': {
      bg: 'red.0',
      color: 'background'
    },
    '&:focus': {
      boxShadow: function boxShadow(theme) {
        return "0 0 1px 2px ".concat(theme.colors.red[2]);
      }
    }
  },
  secondary: {
    bg: 'secondary',
    borderColor: 'secondary',
    color: 'background',
    '&:focus, &:hover, &:active': {
      bg: 'gray.1',
      color: 'background'
    },
    '&:focus': {
      boxShadow: function boxShadow(theme) {
        return "0 0 1px 2px ".concat(theme.colors.gray[2]);
      }
    }
  },
  tertiary: {
    borderColor: 'text',
    bg: 'text',
    color: 'background',
    '&:focus, &:hover, &:active': {
      bg: 'gray.1',
      color: 'background'
    },
    '&:focus': {
      boxShadow: function boxShadow(theme) {
        return "0 0 1px 2px ".concat(theme.colors.gray[2]);
      }
    }
  },
  outline: {
    borderColor: 'gray.2',
    color: 'gray.2',
    '&:focus, &:hover, &:active': {
      bg: 'gray.2',
      color: 'background'
    },
    '&:focus': {
      boxShadow: function boxShadow(theme) {
        return "0 0 1px 2px ".concat(theme.colors.gray[2]);
      }
    }
  },
  outlineTertiary: {
    borderColor: 'text',
    color: 'text'
  },
  link: {
    bg: 'transparent',
    color: 'text',
    '&:focus, &:hover, &:active': {
      bg: 'gray.4'
    }
  },
  linkActive: {
    bg: 'gray.4',
    color: 'text'
  }
};
var sizes = {
  medium: {
    fontSize: [0, 2],
    lineHeight: 'body',
    py: [8, 9],
    px: [3, 4],
    borderRadius: [18, 22]
  },
  small: {
    fontSize: 12,
    lineHeight: 'body',
    py: '5px',
    px: 3,
    borderRadius: 15
  }
};
var buttons = {
  primary: {
    medium: _objectSpread({}, appearances.primary, {}, sizes.medium),
    small: _objectSpread({}, appearances.primary, {}, sizes.small)
  },
  secondary: {
    medium: _objectSpread({}, appearances.secondary, {}, sizes.medium),
    small: _objectSpread({}, appearances.secondary, {}, sizes.small)
  },
  tertiary: {
    medium: _objectSpread({}, appearances.tertiary, {}, sizes.medium),
    small: _objectSpread({}, appearances.tertiary, {}, sizes.small)
  },
  outline: {
    medium: _objectSpread({}, appearances.outline, {}, sizes.medium),
    small: _objectSpread({}, appearances.outline, {}, sizes.small)
  },
  outlineTertiary: {
    medium: _objectSpread({}, appearances.outlineTertiary, {}, sizes.medium),
    small: _objectSpread({}, appearances.outlineTertiary, {}, sizes.small)
  },
  link: {
    medium: _objectSpread({}, appearances.link, {}, sizes.medium),
    small: _objectSpread({}, appearances.link, {}, sizes.small)
  },
  linkActive: {
    medium: _objectSpread({}, appearances.linkActive, {}, sizes.medium),
    small: _objectSpread({}, appearances.linkActive, {}, sizes.small)
  }
};
var _default = buttons;
exports["default"] = _default;