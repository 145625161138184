import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@theme-ui/components';
import { useQuery } from '@apollo/react-hooks';
import { Button } from '@tso/tso-components';

import { WHITELISTED_CATEGORIES } from './WhitelistedCategories';

const CategoryPills = ({ categories, handleCategorySelect }) => {
  const { loading, error, data } = useQuery(WHITELISTED_CATEGORIES, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  return (
    <>
      <Flex __css={{ flexWrap: 'wrap', mx: -1 }}>
        {data.whitelistedCategories.map(({ id, name }) => (
          <Button
            key={id}
            aria-label={
              categories.includes(id)
                ? `button-selected-${id}`
                : `button-deselected-${id}`
            }
            appearance={categories.includes(id) ? 'linkActive' : 'outline'}
            onClick={() => handleCategorySelect(id)}
            sx={{ mb: 2, mx: 1 }}>
            {name}
          </Button>
        ))}
      </Flex>
    </>
  );
};

CategoryPills.propTypes = {
  categories: PropTypes.array,
  handleCategorySelect: PropTypes.func.isRequired
};

export default CategoryPills;
