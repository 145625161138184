import React, { useState } from 'react';

import { Button } from '@tso/tso-components';

import { useAlert } from '../../hooks/index';

const AdminDelete = ({ ids, query, type, ...rest }) => {
  const [askedForConfirmation, setAskedForConfirmation] = useState(false);

  const { showSuccess } = useAlert();

  const handleDelete = async () => {
    if (!askedForConfirmation) {
      return setAskedForConfirmation(true);
    }

    if (askedForConfirmation) {
      const response = await query({ variables: { ids: ids } });
      if (response.data.query) {
        showSuccess(`Successfully deleted ${type}s`);
        setAskedForConfirmation(false);
      }
    }
  };

  return (
    <>
      <Button
        size={'small'}
        onClick={handleDelete}
        appearance={askedForConfirmation ? 'primary' : 'link'}
        {...rest}>
        {askedForConfirmation ? 'Confirm' : 'Delete'}
      </Button>
      {askedForConfirmation && (
        <Button
          size={'small'}
          style={{ marginLeft: '1rem' }}
          onClick={() => setAskedForConfirmation(false)}
          appearance={'link'}>
          Cancel
        </Button>
      )}
    </>
  );
};

export default AdminDelete;
