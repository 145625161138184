import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

import { Feedback, Text1Regular, Text0Light } from '@tso/tso-components';

import { SearchArea } from './index';
import { useFuzzyLocation } from '../../hooks/index';

const SearchAutofill = ({ searchValue, handleLocationSelect }) => {
  const [results, setResults] = useState();
  const { search } = useFuzzyLocation();

  const handleSearch = useCallback(async () => {
    const results = await search(searchValue);
    setResults(results);
  }, [search, searchValue]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  if (!results) {
    return null;
  }

  return (
    <SearchArea ariaLabel="autofill-results">
      {results &&
        results.map(({ label, value }, key) => (
          <Box
            as="button"
            key={key}
            __css={{
              appearance: 'none',
              display: 'block',
              minWidth: '100%',
              textAlign: 'left',
              bg: 'transparent',
              border: 0,
              borderRadius: 0,
              cursor: 'pointer',
              outline: 0,
              p: 2,
              '&:focus, &:hover, &:active': {
                bg: 'gray.4'
              }
            }}
            onClick={() => handleLocationSelect({ label, value })}>
            <Text1Regular as="div">{label}</Text1Regular>
            <Text0Light as="div" color="gray.2">
              Norfolk, United Kingdom
            </Text0Light>
          </Box>
        ))}

      {results.length === 0 && (
        <Feedback status="highlight">
          This location is not currently available on The Story Of. Please
          search for a location in Norfolk.
        </Feedback>
      )}
    </SearchArea>
  );
};

SearchAutofill.propTypes = {
  searchValue: PropTypes.string.isRequired,
  handleLocationSelect: PropTypes.func.isRequired
};

export default SearchAutofill;
