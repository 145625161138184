"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var badges = {
  positive: {
    bg: 'success',
    color: 'background'
  },
  negative: {
    bg: 'red.3',
    color: 'red.0'
  },
  neutral: {
    bg: 'gray.3',
    color: 'gray.0'
  },
  error: {
    bg: 'red.0',
    color: 'background'
  },
  warning: {
    bg: 'warning',
    color: 'background'
  }
};
var _default = badges;
exports["default"] = _default;