import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Heading1,
  Heading4,
  Button,
  FormGroup,
  FormFeedback,
  Textarea,
  ActionButton
} from '@tso/tso-components';

import DatePicker from '../DatePicker';
import { GoogleSearch } from '../index';
import { getAddress } from '../Address';
import { requestDetail } from '../../fragments/index';
import { length } from '../../validation/index';
import { useDate } from '../../hooks/index';

const GET_REQUEST = gql`
  query request($id: Int!) {
    request(id: $id) {
      ...RequestDetail
    }
  }
  ${requestDetail}
`;

const EDIT_REQUEST = gql`
  mutation editRequest(
    $id: Int!
    $place_id: String
    $description: String!
    $day: String
    $month: String
    $year: String
    $year_start: String
    $year_end: String
  ) {
    editRequest(
      id: $id
      place_id: $place_id
      description: $description
      day: $day
      month: $month
      year: $year
      year_start: $year_start
      year_end: $year_end
    ) {
      ...RequestDetail
    }
  }
  ${requestDetail}
`;

const EditRequest = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState({
    location: null,
    description: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { date, setDate, builtDate, givenYearRange, givenFullDate } = useDate();

  const { data, loading, error: queryError } = useQuery(GET_REQUEST, {
    variables: { id },
    onCompleted: data => {
      setRequest({ description: data.request.description });
      setDate({ setup: true, ...date, ...data.request });
    }
  });
  const [editRequest] = useMutation(EDIT_REQUEST, {
    refetchQueries: ['userRequests', 'requests']
  });

  const toggleModal = () => setOpen(open => !open);

  const handleLocationChange = location => setRequest({ ...request, location });

  const handleDescriptionChange = event =>
    setRequest({ ...request, description: event.target.value });

  const handleSubmit = async event => {
    event.preventDefault();

    const { valid, error } = length(request.description, {
      min: 2,
      max: 255,
      type: 'Description'
    });

    if (!valid) {
      return setErrorMessage(error);
    }

    if (!givenFullDate && !givenYearRange) {
      return setErrorMessage('Please enter a full date or a year range');
    }

    await editRequest({
      variables: {
        id,
        description: request.description.trim(),
        ...(request.location && { place_id: request.location.value }),
        ...builtDate
      }
    });

    toggleModal();
  };

  if (loading || queryError) {
    return null;
  }

  return (
    <>
      <ActionButton icon="edit" onClick={() => toggleModal()}>
        Edit
      </ActionButton>

      <Modal isOpen={open} toggle={() => toggleModal()}>
        <ModalHeader as={Heading1} toggle={() => toggleModal()}>
          Edit your request
        </ModalHeader>
        <ModalBody>
          <FormGroup style={{ marginTop: '2rem' }}>
            <Heading4>Where do you want to tell the story of? *</Heading4>
            <GoogleSearch
              placeholder={getAddress(data.request).join(', ')}
              onChange={handleLocationChange}
            />
          </FormGroup>

          <FormGroup style={{ marginTop: '2rem' }}>
            <Heading4>What time period are you focusing on? *</Heading4>
            <DatePicker date={date} setDate={setDate} />
          </FormGroup>

          <FormGroup style={{ marginTop: '2rem' }}>
            <Heading4>
              Tell our community about your project and how they can help bring
              it to life!
            </Heading4>
            <Textarea
              placeholder="Explain what you need help with"
              onChange={handleDescriptionChange}
              value={request.description}
              variant={errorMessage ? 'invalid' : 'default'}
              type="text"
              name="description"
              aria-label="requestDescription"
            />
            {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
          </FormGroup>

          <Button type="submit" onClick={handleSubmit} block>
            Save
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

EditRequest.propTypes = {
  id: PropTypes.number.isRequired
};

export default EditRequest;
