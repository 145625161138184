import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import {
  Container,
  Heading,
  ImageUpload,
  Link0Medium,
  Note,
  Text
} from '@tso/tso-components';

import { Uploading, Meta } from '../../Components';

const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      id
    }
  }
`;

const Upload = () => {
  const [imageId, setImageId] = useState();

  const handleUpload = (cache, { data: { uploadImage } }) => {
    setImageId(uploadImage.id);
  };

  const [upload] = useMutation(UPLOAD_IMAGE, {
    update: handleUpload
  });

  const handleImageUpload = event => {
    const [file] = event.target.files;
    upload({ variables: { image: file } });
  };

  return (
    <>
      <Meta title="Upload" />
      <Box variant="layout.baz">
        <Container>
          <Box variant="layout.form">
            <Heading>Add a photo</Heading>

            <Text mb={3}>
              Every photo adds to the rich living history of the places around
              us. Upload your historic gems here.
            </Text>

            <Text mb={3}>
              Make sure each upload has a date and location. These can be broad
              - a date range or a village rather than a specific street or exact
              point in time.
            </Text>

            <Text mb={3}>
              Photos without both will end up in the Unidentified section. You
              can always share your uploads with friends, family or other users
              for more specific details.
            </Text>

            <Box mb={3}>
              {!imageId && <ImageUpload onChange={handleImageUpload} />}
              {imageId && <Uploading id={imageId} />}
              <Note>
                Please upload a photo in JPEG or PNG formats and aim to keep the
                size below 20mb.{' '}
                <Link0Medium as={Link} to="/help">
                  More information
                </Link0Medium>
              </Note>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Upload;
export { UPLOAD_IMAGE };
