"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var links = {
  "default": {
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    bg: 'transparent',
    border: 0,
    textDecoration: 'underline',
    p: 0,
    cursor: 'pointer'
  },
  plain: {
    color: 'inherit',
    textDecoration: 'none',
    lineHeight: 'tight',
    appearance: 'none',
    cursor: 'pointer',
    p: 0,
    border: 0,
    bg: 'transparent',
    fontFamily: 'inherit',
    '&:focus, &:hover, &:active': {
      color: 'primary'
    }
  },
  link0: {
    light: {
      fontSize: 0,
      fontWeight: 'light',
      variant: 'links.plain'
    },
    regular: {
      fontSize: 0,
      fontWeight: 'regular',
      variant: 'links.plain'
    },
    medium: {
      fontSize: 0,
      fontWeight: 'medium',
      variant: 'links.plain'
    }
  },
  link1: {
    light: {
      fontSize: 1,
      fontWeight: 'light',
      variant: 'links.plain'
    },
    regular: {
      fontSize: 1,
      fontWeight: 'regular',
      variant: 'links.plain'
    },
    medium: {
      fontSize: 1,
      fontWeight: 'medium',
      variant: 'links.plain'
    }
  },
  link2: {
    light: {
      fontSize: 2,
      fontWeight: 'light',
      variant: 'links.plain'
    },
    medium: {
      fontSize: 2,
      fontWeight: 'medium',
      variant: 'links.plain'
    },
    bold: {
      fontSize: 2,
      fontWeight: 'bold',
      variant: 'links.plain'
    }
  }
};
var _default = links;
exports["default"] = _default;