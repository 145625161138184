import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Box } from '@theme-ui/components';
import dayjs from 'dayjs';

import {
  Container,
  Promo2,
  Heading1,
  Button,
  Request,
  Link,
  Text0Bold
} from '@tso/tso-components';

import {
  MapPreview,
  RequestSearch,
  InfiniteScroll,
  NoResults,
  Meta
} from '../../Components/index';
import { paginate } from '../../actions/index';
import { requestPreview } from '../../fragments/index';
import { getAddress } from '../../Components/Address';
import { title } from './Request';

const GET_REQUESTS = gql`
  query requests($page: Int, $sort: Sort, $latitude: Float, $longitude: Float) {
    requests(page: $page, sort: $sort, lat: $latitude, long: $longitude) {
      data {
        ...RequestPreview
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${requestPreview}
`;

const Requests = () => {
  const [query, setQuery] = useState({
    latitude: null,
    longitude: null,
    sort: 'NEWEST_CREATED'
  });

  const { data, error, fetchMore } = useQuery(GET_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: { page: 0, ...query }
  });

  const requestQuery = (newQuery = {}) => {
    setQuery(oldQuery => ({ ...oldQuery, ...newQuery }));
  };

  const handlePagination = () => {
    const { currentPage, hasNextPage } = data.requests.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { page: currentPage + 1, ...query },
      updateQuery: paginate('requests')
    });
  };

  if (!data) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handlePagination}>
      <Meta title="Requests" />
      <Box variant="layout.baz">
        <Box sx={{ pb: 3, mb: 4, borderBottom: 1, borderColor: 'gray.4' }}>
          <Container variant="large">
            <Heading1>Requests</Heading1>
            <Box
              sx={{
                display: [null, 'flex'],
                alignItems: [null, 'center'],
                justifyContent: [null, 'space-between']
              }}>
              <Box>
                {data.requests.data.length !== 0 && (
                  <Text0Bold sx={{ mb: [2, 0] }}>
                    {data.requests.data.length} request
                    {data.requests.data.length >= 1 && 's'}
                  </Text0Bold>
                )}
              </Box>
              <RequestSearch {...query} handleRequestQuery={requestQuery} />
            </Box>
          </Container>
        </Box>

        <Container variant="large">
          <Box sx={{ maxWidth: 960, mx: 'auto' }}>
            <Promo2
              name="Keen to tell the story of somewhere that matters to you?"
              description="Post a request to our community so they can help you gather the
              photos and stories you need."
              mb={3}>
              <RouterLink to={'/requests/create'}>
                <Button as={Link}>Post a question</Button>
              </RouterLink>
            </Promo2>

            {error && <NoResults input={getAddress(query)[0]} />}

            {data && (!data.requests || data.requests.data.length === 0) && (
              <NoResults input={'Norfolk'} />
            )}

            {!error &&
              data.requests &&
              data.requests.data.map(
                ({
                  id,
                  latitude,
                  longitude,
                  image_count,
                  created_at,
                  user,
                  description,
                  ...rest
                }) => (
                  <Request
                    key={id}
                    id={id}
                    date={dayjs(created_at).format('D MMM YYYY')}
                    user={user}
                    latitude={latitude}
                    longitude={longitude}
                    name={title(rest)}
                    count={image_count}
                    description={description}
                    mb={3}>
                    <MapPreview
                      latitude={latitude}
                      longitude={longitude}
                      sx={{
                        flex: 'none',
                        width: ['100%', 200],
                        height: [190, 130]
                      }}
                    />
                  </Request>
                )
              )}
          </Box>
        </Container>
      </Box>
    </InfiniteScroll>
  );
};

export default Requests;
export { GET_REQUESTS };
