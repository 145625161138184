import { useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';

const GET_SCROLL = gql`
  {
    scroll @client
  }
`;

const ADD_SCROLL = gql`
  mutation addScroll($scroll: Float) {
    addScroll(scroll: $scroll) @client
  }
`;

const useScrollPosition = () => {
  const { data = {} } = useQuery(GET_SCROLL);
  const [addScrollMutation] = useMutation(ADD_SCROLL);

  const { scroll = {} } = data;

  const { pathname } = useLocation();
  const { listen } = useHistory();

  const addScroll = useCallback(async () => {
    console.log(window.scrollY);
    addScrollMutation({ variables: { scroll: window.scrollY } });
  }, [addScrollMutation]);

  useEffect(() => {
    const listener = listen(async location => {
      // Save scroll position if the route changes
      if (location.pathname !== pathname) {
        await addScroll(window.scrolllY);
        // Calling stored listen function stops listening
        listener();
      }
    });
  }, [pathname, listen, addScroll, scroll]);

  return scroll;
};

export default useScrollPosition;
