import dayjs from 'dayjs';

const defaultDate = {
  year: '',
  month: '',
  day: '',
  year_start: '',
  year_end: ''
};

const months = [
  { value: null, label: 'None' },
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const years = [
  { value: null, label: 'None' },
  ...Array.from(new Array(120), (_, i) => {
    const date = new Date().getFullYear() - i;
    return { value: date.toString(), label: date.toString() };
  })
];

const days = ([month, year]) => {
  let days = [{ value: null, label: 'None' }];

  // Can only give correct day if month and year is given.
  if (month && year) {
    days = days.concat(
      Array.from(new Array(dayjs(`${month}-${year}`).daysInMonth()), (_, i) => {
        const day = i + 1;
        return { value: day.toString(), label: day.toString() };
      })
    );
  }

  return days;
};

export { months, years, days, defaultDate };
