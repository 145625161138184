import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import {
  Heading5,
  Heading3,
  Text,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from '@tso/tso-components';

import { useAuth } from '../../hooks/index';

const DELETE_ACCOUNT = gql`
  mutation deleteAccount {
    deleteAccount
  }
`;

const DeleteAccount = () => {
  const [open, setOpen] = useState(false);

  const { logout } = useAuth();

  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const history = useHistory();

  const toggleModal = () => setOpen(open => !open);

  const handleDelete = async () => {
    try {
      await deleteAccount();
      logout('Account successfully deleted');
      history.push('/');
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Heading5 as="h2" caps color="gray.1" mb={3}>
        Account settings
      </Heading5>

      <Heading3 as="h3">Delete your account and all associated data</Heading3>

      <Text mb={3}>
        Please note that any photos added to The Story Of will still be
        discoverable even if you delete your account
      </Text>

      <Button onClick={toggleModal}>Delete</Button>
      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Are you sure you want to delete your account?
        </ModalHeader>
        <ModalBody>
          <Text center>
            The deletion is permanent and you will loose any data associated
            with your account.
          </Text>
        </ModalBody>
        <ModalFooter vertical>
          <Button onClick={handleDelete}>Yes, delete</Button>
          <Button onClick={toggleModal} appearance="link">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteAccount;
export { DELETE_ACCOUNT };
