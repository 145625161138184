import { GET_HISTORY } from '../../hooks/useHistory';

const addToHistory = (_root, { url }, { cache }) => {
  const { history } = cache.readQuery({ query: GET_HISTORY });

  // Add the current URL to the history.
  cache.writeData({
    data: {
      history: [
        // Keep a max of 10 items in history.
        ...history.slice(Math.max(history.length - 9, 0)),
        url
      ]
    }
  });

  return null;
};

export { addToHistory };
