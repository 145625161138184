import React from 'react';

import { Results as ResultsComponent, Meta } from '../../Components/index';

const Results = () => (
  <>
    <Meta title="Results" />
    <ResultsComponent />
  </>
);

export default Results;
