import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';

import {
  Pagination,
  Button,
  Checkbox,
  FormGroup,
  Label
} from '@tso/tso-components';

import { SortToggle, Tableheading as Heading, TableCell } from '../index';
import {
  PromoteUser,
  DemoteUser,
  DeleteUser,
  AdminPage
} from '../../Components/index';
import { usePage } from '../../hooks/index';

const GET_USERS = gql`
  query getUsers(
    $page: Int
    $sort: Sort
    $tso_newsletter_opt_in: Boolean
    $archant_newsletter_opt_in: Boolean
    $either_opt_in: Boolean
  ) {
    getUsers(
      page: $page
      sort: $sort
      tso_newsletter_opt_in: $tso_newsletter_opt_in
      archant_newsletter_opt_in: $archant_newsletter_opt_in
      either_opt_in: $either_opt_in
    ) {
      data {
        id
        name
        email
        is_admin
        tso_newsletter_opt_in
        archant_newsletter_opt_in
        joined_on
        created_at
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
`;

const getOptIn = ({ archant_newsletter_opt_in, tso_newsletter_opt_in }) => {
  if (archant_newsletter_opt_in && tso_newsletter_opt_in) {
    return 'Opted into the Archant and TSO newsletters.';
  }

  if (tso_newsletter_opt_in) {
    return 'Opted into the TSO newsletter';
  }

  if (archant_newsletter_opt_in) {
    return 'Opted into the Archant newsletter';
  }

  return 'Not opted into any newsletters.';
};

const Users = () => {
  const [page, setPage] = usePage();
  const [sort, setSort] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    tso_newsletter_opt_in: false,
    archant_newsletter_opt_in: false,
    either_opt_in: false
  });

  const { loading, error, data } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    variables: { page, sort, ...filters }
  });

  const handlePagination = page => setPage(parseInt(page) - 1);

  const toggleShowFilter = () => setShowFilter(show => !show);

  const handleTsoNewsletter = () => {
    setFilters(filters => ({
      ...filters,
      tso_newsletter_opt_in: !filters.tso_newsletter_opt_in,
      either_opt_in: false
    }));
  };

  const handleArchantNewsletter = () => {
    setFilters(filters => ({
      ...filters,
      archant_newsletter_opt_in: !filters.archant_newsletter_opt_in,
      either_opt_in: false
    }));
  };

  const handleEitherNewsletter = () => {
    setFilters(filters => ({
      archant_newsletter_opt_in: false,
      tso_newsletter_opt_in: false,
      either_opt_in: !filters.either_opt_in
    }));
  };

  const handleExport = async () => {
    const { data } = await axios.get(`users/export`);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `export-${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  if (loading || error) {
    return null;
  }

  const { currentPage, lastPage } = data.getUsers.page;

  return (
    <AdminPage
      title="Users"
      actions={
        <div>
          <Button
            style={{ marginRight: '1rem' }}
            size="small"
            onClick={handleExport}>
            Export users
          </Button>
          <Button onClick={toggleShowFilter} size="small">
            {showFilter ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </div>
      }>
      {showFilter && (
        <div>
          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                id="tso-newsletter"
                checked={filters.tso_newsletter_opt_in}
                onChange={handleTsoNewsletter}
                aria-label="tso-newsletter"
              />
              <div>Signed up for TSO newsletter</div>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                checked={filters.archant_newsletter_opt_in}
                onChange={handleArchantNewsletter}
                aria-label="archant-newsletter"
              />
              <div>Signed up for Archant newsletter</div>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                checked={filters.either_opt_in}
                onChange={handleEitherNewsletter}
                aria-label="either-newsletter"
              />
              <div>Signed up for any newsletter</div>
            </Label>
          </FormGroup>
        </div>
      )}
      <table style={{ width: '100%', minWidth: 700 }}>
        <thead>
          <tr>
            <Heading>
              Name
              <SortToggle
                sort={{
                  current: sort,
                  up: 'USER_ASC',
                  down: 'USER_DESC'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>Email</Heading>
            <Heading>Created Account</Heading>
            <Heading>
              <span>Created At</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'OLDEST_CREATED',
                  down: 'NEWEST_CREATED'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>Opt In</Heading>
            <Heading>Actions</Heading>
          </tr>
        </thead>
        <tbody>
          {data.getUsers.data.map(
            (
              { id, name, is_admin, email, joined_on, created_at, ...rest },
              index
            ) => (
              <tr key={index}>
                <TableCell>{name}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  {joined_on ? dayjs(joined_on).fromNow() : 'N/A'}
                </TableCell>
                <TableCell>{dayjs(created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{getOptIn(rest)}</TableCell>
                <TableCell>
                  <Button
                    size={'small'}
                    appearance={'link'}
                    as={Link}
                    to={`/users/${id}`}>
                    View
                  </Button>
                  {is_admin ? <DemoteUser id={id} /> : <PromoteUser id={id} />}
                  <DeleteUser id={id} />
                </TableCell>
              </tr>
            )
          )}
        </tbody>
        <Pagination
          paginate={{
            currentPage: currentPage + 1,
            totalPages: lastPage + 1
          }}
          handlePageChange={handlePagination}
        />
      </table>
    </AdminPage>
  );
};

export default Users;
