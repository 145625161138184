"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var GoogleMapContext = _react["default"].createContext({
  center: {
    lat: 52.6326,
    lng: 1.293
  },
  zoom: 15,
  setMap: function setMap(_ref) {
    var center = _ref.center,
        zoom = _ref.zoom;
    return center || zoom;
  }
});

var _default = GoogleMapContext;
exports["default"] = _default;