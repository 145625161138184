import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import {
  Button,
  Divider,
  Heading,
  Input,
  Link1Medium,
  Text1Regular,
  FormFeedback
} from '@tso/tso-components';

import { useAuth } from '../../hooks/index';
import { AuthContainer, AuthRedirect } from '../../Components/index';
import { loggedInUser } from '../../fragments/index';
import { length } from '../../validation/index';

const LOGIN = gql`
  mutation login($email: Email!, $password: String!) {
    login(email: $email, password: $password) {
      ...LoggedInUser
      token {
        token
      }
    }
  }
  ${loggedInUser}
`;

const handleLogin = (cache, { data: { login } }) => {
  if (!login) {
    return cache.writeData({
      data: {
        alert: {
          __typename: 'Alert',
          status: 'warning',
          message: 'Not valid credentials.'
        }
      }
    });
  }

  const { token, ...user } = login;

  localStorage.setItem('token', token.token);
  localStorage.setItem('user', JSON.stringify(user));

  cache.writeData({
    data: {
      user,
      isLoggedIn: true,
      alert: {
        __typename: 'Alert',
        status: 'positive',
        message: 'You have been successfully logged in.'
      }
    }
  });
};

const Login = () => {
  const [creds, setCreds] = useState({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState();

  const { isNotGuest } = useAuth();

  const [login] = useMutation(LOGIN, { update: handleLogin });

  const handleEmailChange = event => {
    setCreds({ ...creds, email: event.target.value });
  };

  const handlePasswordChange = event => {
    setCreds({ ...creds, password: event.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { valid, error } = length(creds.email, {
      min: 5,
      max: 50,
      type: 'Email'
    });

    const { valid: validPassword, error: passwordError } = length(
      creds.password,
      {
        min: 8,
        max: 50,
        type: 'Password'
      }
    );

    if (!valid) {
      return setErrorMessage(error);
    }

    if (!validPassword) {
      return setErrorMessage(passwordError);
    }

    try {
      await login({
        variables: {
          email: creds.email,
          password: creds.password
        }
      });
    } catch (error) {
      setErrorMessage(
        'Sorry, something went wrong. Please check your password and try again.'
      );
    }
  };

  return (
    <AuthContainer>
      {isNotGuest && <AuthRedirect />}
      <Box variant="layout.form">
        <form onSubmit={handleSubmit}>
          <Heading>Log in</Heading>
          <Box mb={3}>
            <Input
              onChange={handleEmailChange}
              type="email"
              placeholder="Email *"
              name="email"
              aria-label="email"
              value={creds.email}
            />
          </Box>

          <Box mb={3}>
            <Input
              onChange={handlePasswordChange}
              type="password"
              placeholder="Password *"
              name="password"
              aria-label="password"
              value={creds.password}
            />
            {errorMessage && <FormFeedback mt={2}>{errorMessage}</FormFeedback>}
          </Box>

          <Text1Regular mb={3}>
            <Link1Medium as={Link} to="/password/request" color="primary">
              Forgot password?
            </Link1Medium>
          </Text1Regular>

          <Button type="submit" aria-label="submit" isBlock>
            Log in
          </Button>

          <Divider />

          <Text1Regular>
            Don&apos;t have an account?{' '}
            <Link1Medium as={Link} to="/signup">
              Sign up
            </Link1Medium>
          </Text1Regular>
        </form>
      </Box>
    </AuthContainer>
  );
};

export default Login;
export { LOGIN, handleLogin };
