import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useAlert } from '../../hooks/index';
import Report from '../Report';

const REPORT_IMAGE = gql`
  mutation reportImage($id: Int!, $problem_id: Int!, $message: String!) {
    reportImage(id: $id, problem_id: $problem_id, message: $message) {
      id
      user {
        id
      }
    }
  }
`;

const ReportImage = ({ id, ...rest }) => {
  const [reportImage] = useMutation(REPORT_IMAGE);

  const { showSuccess } = useAlert();

  const handleReport = async (message, problemId) => {
    await reportImage({
      variables: { id: parseInt(id), problem_id: parseInt(problemId), message }
    });
    showSuccess('Report submitted');
  };

  return (
    <Report
      reportType={'Image'}
      header="Report inappropriate content"
      handleReport={handleReport}
      {...rest}
    />
  );
};

ReportImage.propTypes = {
  id: PropTypes.number.isRequired
};

export default ReportImage;
export { REPORT_IMAGE };
