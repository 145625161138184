import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import AdminDelete from './AdminDelete';

const DELETE_IMAGES = gql`
  mutation deleteImages($ids: [Int!]!) {
    deleteImages(ids: $ids)
  }
`;

const AdminDeleteImage = ({ ids, ...rest }) => {
  const [deleteImage] = useMutation(DELETE_IMAGES, {
    refetchQueries: [
      'allImages',
      'image',
      'userImages',
      'images',
      'unidentified',
      'suggestImagesForUser',
      'popularImages',
      'period'
    ]
  });

  return <AdminDelete ids={ids} query={deleteImage} type="image" {...rest} />;
};

export default AdminDeleteImage;
