import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@theme-ui/components';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import {
  Avatar,
  Container,
  CreateButton,
  Heading1,
  Link1Medium,
  Link2Light,
  Link,
  TabLink,
  Text
} from '@tso/tso-components';

import { useAuth } from '../../hooks/index';
import {
  UserImages,
  UserMap,
  UserCollections,
  UserRequests,
  ReportUser,
  PrivateCollections
} from './index';

import { Meta } from '../index';
import { loggedInUser } from '../../fragments/index';

const GET_USER_DATA = gql`
  query getUser($id: Int!) {
    getUser(id: $id) {
      ...LoggedInUser
      photoCount
      collectionCount
      requestCount
    }
  }
  ${loggedInUser}
`;

const Profile = ({ id }) => {
  const [currentTab, setCurrentTab] = useState('PHOTOS');

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: { id: parseInt(id) },
    fetchPolicy: 'network-only'
  });

  const { user: loggedInUser, isGuest } = useAuth();

  const handleViewChange = tab => setCurrentTab(tab);

  if (loading || error) {
    return null;
  }

  const onLoggedInUserProfile = loggedInUser.id === data.getUser.id;

  return (
    <Box pt={[3, 4]}>
      <Meta title="Profile" />
      <Box sx={{ borderBottom: 1, borderColor: 'gray.4' }}>
        <Container>
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              mb: 3,
              maxWidth: 450,
              mx: 'auto',
              alignItems: 'center'
            }}>
            <Avatar
              id={parseInt(id)}
              name={data.getUser.name}
              image={{ url: data.getUser.profile_src }}
              size={180}
              sx={{ flex: 'none', mb: [3, 0], mr: [null, 3] }}
            />
            <Box sx={{ flex: '1 1 auto' }}>
              <Heading1 mb={2}>{data.getUser.name}</Heading1>

              {data.getUser.website_url && (
                <Box mb={2}>
                  <Link2Light
                    style={{ marginBottom: '20px' }}
                    href={data.getUser.website_url}
                    external>
                    {data.getUser.website_url}
                  </Link2Light>
                </Box>
              )}

              {!onLoggedInUserProfile && !isGuest && <ReportUser id={id} />}

              {onLoggedInUserProfile && (
                <Link1Medium as={ReactRouterDomLink} to="/settings">
                  Edit profile
                </Link1Medium>
              )}
            </Box>
          </Flex>

          <Box sx={{ overflowY: 'auto' }}>
            <Flex
              sx={{ whiteSpace: 'nowrap', justifyContent: [null, 'center'] }}>
              <Box role="tablist">
                <TabLink
                  onClick={() => handleViewChange('PHOTOS')}
                  active={currentTab === 'PHOTOS'}>
                  {`${onLoggedInUserProfile ? 'Your ' : ''}Photos (${
                    data.getUser.photoCount
                  })`}
                </TabLink>
                <TabLink
                  onClick={() => handleViewChange('COLLECTIONS')}
                  active={currentTab === 'COLLECTIONS'}>
                  {`${onLoggedInUserProfile ? 'Your ' : ''}Collections (${
                    data.getUser.collectionCount
                  })`}
                </TabLink>
                <TabLink
                  onClick={() => handleViewChange('REQUESTS')}
                  active={currentTab === 'REQUESTS'}>
                  {`${onLoggedInUserProfile ? 'Your ' : ''}Requests (${
                    data.getUser.requestCount
                  })`}
                </TabLink>
                {onLoggedInUserProfile && data.getUser.photoCount > 0 && (
                  <TabLink
                    onClick={() => handleViewChange('MAP')}
                    active={currentTab === 'MAP'}>
                    Your Map
                  </TabLink>
                )}
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>

      {currentTab === 'PHOTOS' && (
        <Box pt={3}>
          {Boolean(onLoggedInUserProfile && data.getUser.photoCount === 0) && (
            <Container variant="xxl">
              <CreateButton
                text="Add photo"
                to="/upload"
                sx={{ mx: 'auto', mb: 3 }}
              />
              <Text center>
                You haven’t posted any images yet.{' '}
                <Link as={ReactRouterDomLink} to="/upload" color="primary">
                  Add a photo
                </Link>
              </Text>
            </Container>
          )}

          <UserImages id={parseInt(id)} />
        </Box>
      )}

      {currentTab === 'COLLECTIONS' && (
        <Box pt={3}>
          <UserCollections id={parseInt(id)} />
          {onLoggedInUserProfile && <PrivateCollections />}
        </Box>
      )}

      {currentTab === 'REQUESTS' && (
        <Box pt={3}>
          <Container variant="xxl">
            {Boolean(
              onLoggedInUserProfile && data.getUser.requestCount === 0
            ) && (
              <>
                <CreateButton
                  text="Post a request"
                  to="/requests/create"
                  sx={{ mx: 'auto', mb: 3 }}
                />
                <Text center>
                  You haven’t posted any request yet.{' '}
                  <Link
                    as={ReactRouterDomLink}
                    to="/requests/create"
                    color="primary">
                    Post a request
                  </Link>
                </Text>
              </>
            )}
            <UserRequests id={parseInt(id)} />
          </Container>
        </Box>
      )}

      {currentTab === 'MAP' && <UserMap id={parseInt(id)} />}
    </Box>
  );
};

Profile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Profile;
export { GET_USER_DATA };
