"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var tabs = {
  small: {
    fontSize: 0,
    lineHeight: 'none',
    py: [14, null, 25],
    px: 1,
    fontWeight: 'regular',
    textTransform: 'uppercase',
    borderWidth: function borderWidth(theme) {
      return "".concat(theme.borderWidths[2], "px 0");
    },
    height: [44, null, 64]
  },
  medium: {
    fontSize: 2,
    lineHeight: 'body',
    // 24px
    fontWeight: 'medium',
    p: 2,
    borderWidth: function borderWidth(theme) {
      return "".concat(theme.borderWidths[3], "px 0");
    }
  }
};
var _default = tabs;
exports["default"] = _default;