import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Box } from '@theme-ui/components';

import { GoogleMapProvider, Text } from '@tso/tso-components';

import { ResultMap } from '../index';
import { GET_USER_IMAGES } from './UserImages';

const UserMap = ({ id }) => {
  const { data, loading, error } = useQuery(GET_USER_IMAGES, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  if (data.userImages.total === 0) {
    return (
      <Box py={3}>
        <Text center sx={{ mx: 'auto', maxWidth: 480 }}>
          We haven’t identified any location in your photos. Add a location to
          your images to see them appear on your map.
        </Text>
      </Box>
    );
  }

  return (
    <>
      <GoogleMapProvider
        initalCenter={{
          lat: data.userImages.data[0].revision.latitude,
          lng: data.userImages.data[0].revision.longitude
        }}
        intialZoom={12}>
        <ResultMap showSidebar={false} results={data.userImages.data} />
      </GoogleMapProvider>
    </>
  );
};

UserMap.propTypes = {
  id: PropTypes.number.isRequired
};

export default UserMap;
