import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';

import { ImagesDay } from '@tso/tso-components';

import { getAddress } from '../Address';
import { imageDetail } from '../../fragments/index';

const ON_THIS_PERIOD = gql`
  query fromPeriod {
    fromPeriod {
      ...ImageDetail
    }
  }
  ${imageDetail}
`;

const OnThisPeriod = props => {
  const { data, loading, error } = useQuery(ON_THIS_PERIOD, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  if (data.fromPeriod.length === 0) {
    return null;
  }

  return (
    <ImagesDay
      name={`${dayjs().format('MMMM')} throughout time`}
      description="Explore these remarkable stories of places near you"
      images={data.fromPeriod.map(({ revision, id, is_unidentified, src }) => ({
        id,
        unidentified: is_unidentified,
        name: revision.name,
        year: revision.year,
        date: dayjs(revision.date).format('D MMM YYYY'),
        image: { url: src },
        url: `/images/${id}`,
        location: getAddress(revision)[0],
        orientation: revision.orientation
      }))}
      {...props}
    />
  );
};

OnThisPeriod.propTypes = {
  period: PropTypes.oneOf(['DAY', 'MONTH'])
};

export default OnThisPeriod;
export { ON_THIS_PERIOD };
