import React from 'react';
import PropTypes from 'prop-types';

import { ImageSelect } from '@tso/tso-components';

const SelectableImage = ({
  id,
  src,
  selected = false,
  revision,
  handleImageSelect,
  ...rest
}) => (
  <ImageSelect
    onChange={handleImageSelect}
    style={{ border: selected && '1px solid red' }}
    id={id}
    image={{ url: src }}
    checked={selected}
    {...revision}
    {...rest}
  />
);

SelectableImage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  src: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  revision: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  handleImageSelect: PropTypes.func.isRequired
};

export default SelectableImage;
