import React, { useState } from 'react';
import { Box } from '@theme-ui/components';
import gql from 'graphql-tag';
import { Redirect, Link as RouterLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import {
  Button,
  Container,
  FormFeedback,
  Heading1,
  Heading4,
  Text,
  Textarea
} from '@tso/tso-components';

import { GoogleSearch, DatePicker } from '../../Components/index';
import { requestDetail } from '../../fragments/index';
import { length } from '../../validation/index';
import { useDate } from '../../hooks/index';

const CREATE_REQUEST = gql`
  mutation createRequest(
    $place_id: String!
    $description: String!
    $day: String
    $month: String
    $year: String
    $year_start: String
    $year_end: String
  ) {
    createRequest(
      place_id: $place_id
      description: $description
      day: $day
      month: $month
      year: $year
      year_start: $year_start
      year_end: $year_end
    ) {
      ...RequestDetail
    }
  }
  ${requestDetail}
`;

const CreateRequest = () => {
  const [request, setRequest] = useState({
    location: null,
    description: ''
  });
  const [errorMessage, setErrorMessage] = useState();

  const { date, setDate, builtDate, givenFullDate, givenYearRange } = useDate();

  const [createRequest, { data: createdRequest }] = useMutation(CREATE_REQUEST);
  const history = useHistory();

  const handleSubmit = async event => {
    event.preventDefault();

    const { valid, error } = length(request.description, {
      min: 2,
      max: 255,
      type: 'Description'
    });

    if (!valid) {
      return setErrorMessage(error);
    }

    if (!givenFullDate && !givenYearRange) {
      return setErrorMessage('Please enter a full date or a year range');
    }

    if (!request.location) {
      return setErrorMessage('Please enter a location');
    }

    await createRequest({
      variables: {
        place_id: request.location.value,
        description: request.description.trim(),
        ...builtDate
      }
    });
  };

  const handleLocationChange = location => setRequest({ ...request, location });

  const handleDescriptionChange = event =>
    setRequest({ ...request, description: event.target.value });

  return (
    <Box variant="layout.baz">
      <Container>
        <form>
          <Box variant="layout.form">
            {createdRequest && (
              <Redirect
                to={`/requests/create/${createdRequest.createRequest.id}/success`}
              />
            )}

            <Heading1>How would you like our community to help you?</Heading1>

            <Text mb={4}>
              Keen to tell the story of somewhere that matters to you? Post a
              request to our community so they can help you gather the photos
              and stories you need.
            </Text>

            <Box mb={5}>
              <Heading4>Where do you want to tell the story of? *</Heading4>
              <GoogleSearch
                value={request.location}
                onChange={handleLocationChange}
                placeholder={'Landmark, City, Region'}
              />
            </Box>

            <Box mb={5}>
              <Heading4>What time period are you focusing on? *</Heading4>
              <DatePicker date={date} setDate={setDate} />
            </Box>

            <Box mb={5}>
              <Heading4>
                Tell our community about your project and how they can help
                bring it to life!
              </Heading4>
              <Textarea
                placeholder="Explain what you need help with"
                onChange={handleDescriptionChange}
                value={request.description}
                variant={errorMessage ? 'invalid' : 'default'}
                type="text"
                name="description"
                aria-label="requestDescription"
              />
              {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
            </Box>

            <Button type="submit" onClick={handleSubmit} block>
              Post your request
            </Button>

            <Button as={RouterLink} onClick={history.goBack} appearance="link">
              Cancel
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default CreateRequest;
