import gql from 'graphql-tag';

const requestDetail = gql`
  fragment RequestDetail on Request {
    id
    description
    date
    day
    month
    year
    year_start
    year_end
    street_address_1
    city
    postcode
    country
    latitude
    longitude
    created_at
    user {
      id
      name
      profile_src
    }
  }
`;

const requestPreview = gql`
  fragment RequestPreview on Request {
    id
    description
    date
    day
    month
    year
    year_start
    year_end
    street_address_1
    latitude
    longitude
    created_at
    image_count
    user {
      id
      name
      profile_src
    }
  }
`;

export { requestDetail, requestPreview };
