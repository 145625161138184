import React, { useEffect, useRef } from 'react';
import { Box } from '@theme-ui/components';

import { Alert as AlertComponent, IconButton } from '@tso/tso-components';
import { useAlert } from '../hooks/index';

const Alert = () => {
  const { message, status, hasAlert, closeAlert } = useAlert();

  const clearRef = useRef();

  /**
   * Whenever a notification is shown, clear it after 10 seconds.
   */
  useEffect(() => {
    if (clearRef.current) {
      clearTimeout(clearRef.current);
    }

    if (hasAlert) {
      clearRef.current = setTimeout(closeAlert, 10000);
    }
  }, [hasAlert, closeAlert]);

  /**
   * Clear the clear notification timeout on unmount.
   */
  useEffect(() => {
    return () => {
      if (clearRef.current) {
        clearTimeout(clearRef.current);
      }
    };
  }, []);

  if (!hasAlert) {
    return null;
  }

  return (
    <AlertComponent
      variant={status}
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: [89, 62],
        left: 0,
        right: 0,
        zIndex: 4
      }}>
      <Box sx={{ flex: '1 1 auto', mr: 3 }}>{message}</Box>

      <IconButton
        onClick={closeAlert}
        icon="close"
        sx={{
          flex: 'none',
          mr: -2,
          color: 'inherit',
          '&:focus, &:hover, &:active': {
            bg: 'transparent',
            color: 'inherit'
          }
        }}
      />
    </AlertComponent>
  );
};

export default Alert;
