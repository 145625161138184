"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _breakpoints = _interopRequireDefault(require("./breakpoints"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mediaQueries = {
  xs: "@media screen and (min-width: ".concat(_breakpoints["default"][0], ")"),
  s: "@media screen and (min-width: ".concat(_breakpoints["default"][1], ")"),
  m: "@media screen and (min-width: ".concat(_breakpoints["default"][2], ")"),
  l: "@media screen and (min-width: ".concat(_breakpoints["default"][3], ")"),
  xl: "@media screen and (min-width: ".concat(_breakpoints["default"][4], ")"),
  xxl: "@media screen and (min-width: ".concat(_breakpoints["default"][5], ")")
};
var _default = mediaQueries;
exports["default"] = _default;