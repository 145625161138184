import { useContext } from 'react';

import { GoogleContext } from '../Contexts/index';

const useGoogle = () => {
  const google = useContext(GoogleContext);

  return google;
};

export default useGoogle;
