"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var layout = {
  foo: {
    maxWidth: 800,
    mx: 'auto'
  },
  bar: {
    maxWidth: 600,
    mx: 'auto',
    my: [2, null, 4],
    textAlign: 'center'
  },
  baz: {
    py: [2, 60]
  },
  comments: {
    maxWidth: 800,
    mx: 'auto'
  },
  form: {
    maxWidth: 'form',
    mx: 'auto'
  },
  text: {
    maxWidth: 770,
    mx: 'auto'
  },
  xxl: {
    maxWidth: 'xxl',
    mx: 'auto'
  },
  storybook: {
    minHeight: 'auto'
  },
  section: {
    pt: [4, 5, 6],
    py: [3, 4, 5]
  },
  mx: {
    mx: [null, null, 4]
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    py: 4,
    minHeight: function minHeight(theme) {
      return ["calc(100vh - ".concat(theme.sizes.headerHeightXXS, "px)"), "calc(100vh - ".concat(theme.sizes.headerHeightXS, "px)")];
    }
  }
};
var _default = layout;
exports["default"] = _default;