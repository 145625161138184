import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

import { Slider } from '@tso/tso-components';

import Background from './Background';

const defaultValues = [1910, new Date().getFullYear() - 10];
const defaultDomain = [1900, new Date().getFullYear().toString()];

const WrappedSlider = ({
  domain = defaultDomain,
  values = defaultValues,
  ...rest
}) => (
  <Box sx={{ position: 'relative', px: '28px' }}>
    <Background range={domain} />
    <Slider domain={domain} values={values} {...rest} />
  </Box>
);

WrappedSlider.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number),
  values: PropTypes.arrayOf(PropTypes.number),
  handleValueChange: PropTypes.func.isRequired
};

export default WrappedSlider;
export { defaultDomain, defaultValues };
