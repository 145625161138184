import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@theme-ui/components';
import PropTypes from 'prop-types';

import {
  Heading1,
  ImageGrid,
  Text,
  Button,
  Container,
  Toolbar
} from '@tso/tso-components';

import { SelectableImage, CenteredBox, InfiniteScroll } from '../index';

const SelectFromImages = ({
  images,
  selected = [],
  handleSubmit,
  handleBottom,
  handleImageSelect
}) => {
  const selectedImage = localStorage.getItem('selectedImage');
  const headingText = selectedImage
    ? 'Want to add some of your existing images to this collection?'
    : 'Select photos for your collection';
  return (
    <Container>
      <Toolbar
        style={{
          display: 'flex',
          jusitfyContent: 'space-between',
          position: 'sticky',
          top: '3rem',
          width: '100%',
          zIndex: '5'
        }}>
        <Button as={RouterLink} to="/collections" appearance="link">
          Back
        </Button>
        <Button
          disabled={!selectedImage && selected.length === 0}
          onClick={handleSubmit}>
          Confirm & publish
        </Button>
      </Toolbar>
      {images.length === 0 && (
        <CenteredBox __css={{ textAlign: 'center' }}>
          <Heading1>Ready to add photos?</Heading1>
          <Text>
            You haven&apos;t uploaded any photos yet.
            <br />
            To create or edit a collection, first upload photos on The Story Of.
          </Text>
          <Button as={RouterLink} to="/upload" appearance="link">
            Add Photos
          </Button>
        </CenteredBox>
      )}
      {images.length > 0 && (
        <>
          <Box __css={{ padding: 50, textAlign: 'center' }}>
            <Heading1>{headingText}</Heading1>
          </Box>
          <InfiniteScroll onBottom={handleBottom}>
            <ImageGrid>
              {images.map((images, key) => (
                <SelectableImage
                  {...images}
                  key={key}
                  selected={selected.includes(images.id)}
                  handleImageSelect={handleImageSelect(images.id)}
                />
              ))}
            </ImageGrid>
          </InfiniteScroll>
        </>
      )}
    </Container>
  );
};

SelectFromImages.propTypes = {
  images: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBottom: PropTypes.func.isRequired,
  handleImageSelect: PropTypes.func.isRequired
};

export default SelectFromImages;
