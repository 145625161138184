import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Routes from './Routes/index';
import { Alert, Header } from './Components/index';
import { link, mutations as Mutation, defaultCache } from './apollo/index';

const App = () => {
  const [client, setClient] = useState();

  const setupClient = async () => {
    const cache = new InMemoryCache();
    cache.writeData({ data: defaultCache() });

    const client = new ApolloClient({
      link,
      cache,
      resolvers: { Mutation }
    });

    setClient(client);
  };

  useEffect(() => {
    setupClient();
  }, []);

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Header />
      <Alert />
      <Routes />
    </ApolloProvider>
  );
};

export default App;
