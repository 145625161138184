import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import {
  Button,
  Checkbox,
  Divider,
  Feedback,
  FormGroup,
  Heading,
  Input,
  Label,
  Link,
  Link1Medium,
  Text1Regular
} from '@tso/tso-components';

import { useAuth, useEmailToken } from '../../hooks/index';
import { isValidEmail, isValidFullName } from '../../validation/index';
import { AuthContainer, AuthRedirect } from '../../Components/index';
import { loggedInUser } from '../../fragments/index';

const SIGNUP = gql`
  mutation register(
    $email: Email!
    $password: String!
    $first_name: String!
    $last_name: String!
    $token: String
    $tso_newsletter_opt_in: Boolean
    $archant_newsletter_opt_in: Boolean
  ) {
    register(
      email: $email
      password: $password
      first_name: $first_name
      last_name: $last_name
      token: $token
      tso_newsletter_opt_in: $tso_newsletter_opt_in
      archant_newsletter_opt_in: $archant_newsletter_opt_in
    ) {
      ...LoggedInUser
      token {
        token
      }
    }
  }
  ${loggedInUser}
`;

const handleSignup = (cache, { data: { register } }) => {
  const { token, ...user } = register;

  localStorage.setItem('token', token.token);
  localStorage.setItem('user', JSON.stringify(user));

  cache.writeData({
    data: {
      user,
      isLoggedIn: true,
      alert: {
        __typename: 'Alert',
        status: 'positive',
        message: 'You have successfully signed up for an account'
      }
    }
  });
};

const Signup = ({ location }) => {
  const { isNotGuest } = useAuth();
  const { email, token } = useEmailToken(location);

  const [errorMessage, setErrorMessage] = useState('');
  const [creds, setCreds] = useState({
    fullName: '',
    email,
    password: '',
    termsAccepted: false,
    policyAccepted: false,
    tsoNewsletterOptIn: false,
    archantNewsletterOptIn: false
  });

  const [signup] = useMutation(SIGNUP, { update: handleSignup });

  const handleNameChange = event => {
    setCreds({ ...creds, fullName: event.target.value });
  };

  const handleEmailChange = event => {
    setCreds({ ...creds, email: event.target.value });
  };

  const handlePasswordChange = event => {
    setCreds({ ...creds, password: event.target.value });
  };

  const handleTermsChange = () => {
    setCreds({ ...creds, termsAccepted: !creds.termsAccepted });
  };

  const handlePolicyChange = () => {
    setCreds({ ...creds, policyAccepted: !creds.policyAccepted });
  };

  const handleTsoNewsletterOptIn = () => {
    setCreds({ ...creds, tsoNewsletterOptIn: !creds.tsoNewsletterOptIn });
  };

  const handleArchantNewsletterOptIn = () => {
    setCreds({
      ...creds,
      archantNewsletterOptIn: !creds.archantNewsletterOptIn
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!isValidFullName(creds.fullName)) {
      return setErrorMessage('Please enter your full name e.g. Joe Bloggs');
    }

    if (!isValidEmail(creds.email)) {
      return setErrorMessage("The email you've provided is not valid");
    }

    if (creds.password.length < 8) {
      return setErrorMessage(
        "The password you've provided is not valid, ensure it's at least 8 characters"
      );
    }

    if (!creds.termsAccepted || !creds.policyAccepted) {
      return setErrorMessage('Please agree to the terms of use');
    }

    signup({
      variables: {
        first_name: creds.fullName.split(' ')[0],
        last_name: creds.fullName.split(' ')[1],
        email: creds.email,
        password: creds.password,
        tso_newsletter_opt_in: creds.tsoNewsletterOptIn,
        archant_newsletter_opt_in: creds.archantNewsletterOptIn,
        ...(token && { token })
      }
    });
  };

  return (
    <AuthContainer>
      {isNotGuest && <AuthRedirect />}
      <Box variant="layout.form">
        <form>
          <Heading>Create Account</Heading>
          <FormGroup>
            <Input
              value={creds.fullName}
              onChange={handleNameChange}
              type="text"
              placeholder="Full Name *"
              name="full_name"
              aria-label="full_name"
            />
          </FormGroup>

          <FormGroup>
            <Input
              value={creds.email}
              onChange={handleEmailChange}
              type="email"
              placeholder="Email *"
              name="email"
              aria-label="email"
            />
          </FormGroup>

          <FormGroup>
            <Input
              value={creds.password}
              onChange={handlePasswordChange}
              type="password"
              placeholder="Password *"
              name="password"
              aria-label="password"
            />
            {errorMessage && (
              <Feedback status="warning" mt={2}>
                {errorMessage}
              </Feedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                checked={creds.termsAccepted}
                onChange={handleTermsChange}
                aria-label="termsCheckbox"
              />
              <div>
                I agree with{' '}
                <Link
                  external
                  href="https://www.archant.co.uk/articles/terms-conditions">
                  The Story Of Terms & conditions
                </Link>{' '}
                *
              </div>
            </Label>
          </FormGroup>

          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                checked={creds.policyAccepted}
                onChange={handlePolicyChange}
                aria-label="policyCheckbox"
              />
              <div>
                I agree with{' '}
                <Link
                  external
                  href="https://www.archant.co.uk/articles/privacy-policy/">
                  The Story Of Policy statement
                </Link>{' '}
                *
              </div>
            </Label>
          </FormGroup>

          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                checked={creds.tsoNewsletterOptIn}
                onChange={handleTsoNewsletterOptIn}
                aria-label="tsoNewsletterOptIn"
              />
              <div>Sign up to receive The Story Of email newsletter</div>
            </Label>
          </FormGroup>

          <FormGroup>
            <Label variant="checkbox">
              <Checkbox
                checked={creds.archantNewsletterOptIn}
                onChange={handleArchantNewsletterOptIn}
                aria-label="archantNewsletterOptIn"
              />
              <div>
                Keep me up to date on special promotions, products and services
                from Archant Community Media Limited
              </div>
            </Label>
          </FormGroup>

          <Button type="submit" onClick={handleSubmit} isBlock>
            Sign up
          </Button>

          <Divider />

          <Text1Regular>
            Already have an account?{' '}
            <Link1Medium as={RouterLink} to="/login">
              Log in
            </Link1Medium>
          </Text1Regular>
        </form>
      </Box>
    </AuthContainer>
  );
};

Signup.propTypes = {
  location: PropTypes.object.isRequired
};

export default Signup;
export { SIGNUP, handleSignup };
