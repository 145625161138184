import React from 'react';

import { ListItem } from '@tso/tso-components';

import { useAlert, useAuth } from '../hooks/index';

const AuthListItem = ({ children, onClick, ...props }) => {
  const { showWarningAndRedirect } = useAlert();
  const { isGuest } = useAuth();

  const handleClick = (...params) => {
    if (isGuest) {
      return showWarningAndRedirect({
        message: 'You need to be logged in to perform that action',
        to: '/login'
      });
    }

    onClick(...params);
  };

  return (
    <ListItem onClick={handleClick} {...props}>
      {children}
    </ListItem>
  );
};

export default AuthListItem;
