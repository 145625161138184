import {
  Users,
  Images,
  Collection,
  Reports,
  Requests,
  Comments
} from '../Pages/Admin/index';

const routes = [
  { path: '/admin', component: Images, admin: true },
  {
    path: '/admin/images',
    component: Images,
    admin: true,
    name: 'Manage Images'
  },
  {
    path: '/admin/images/:id/comments',
    component: Comments,
    admin: true
  },
  { path: '/admin/users', component: Users, admin: true, name: 'Manage Users' },
  {
    path: '/admin/collections',
    component: Collection,
    admin: true,
    name: 'Manage Collections'
  },
  {
    path: '/admin/requests',
    component: Requests,
    admin: true,
    name: 'Manage Requests'
  },
  {
    path: '/admin/reports',
    component: Reports,
    admin: true,
    name: 'View Reports'
  }
];

export default routes;
