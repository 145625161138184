import gql from 'graphql-tag';

const UserInterests = gql`
  fragment UserInterests on Interests {
    categories {
      id
    }
  }
`;

export { UserInterests };
