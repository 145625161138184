"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var containers = {
  container: {
    width: '100%',
    mx: 'auto',
    px: [2, 3]
  },
  "default": {
    variant: 'containers.container',
    maxWidth: '100%'
  },
  xxl: {
    variant: 'containers.container',
    maxWidth: 'xxl' // 2380,

  },
  large: {
    variant: 'containers.container',
    maxWidth: 1196 // http://gridcalculator.dk/#/1196/12/20/20

  },
  comments: {
    variant: 'containers.container',
    maxWidth: 800
  },
  text: {
    variant: 'containers.container',
    maxWidth: 770
  },
  form: {
    variant: 'containers.container',
    maxWidth: 500
  }
};
var _default = containers;
exports["default"] = _default;