import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import AdminDelete from './AdminDelete';

const DELETE_IMAGES = gql`
  mutation deleteReports($ids: [Int!]!) {
    deleteReports(ids: $ids)
  }
`;

const AdminDeleteReport = ({ ids, ...rest }) => {
  const [deleteReports] = useMutation(DELETE_IMAGES, {
    refetchQueries: ['reports']
  });

  return (
    <AdminDelete ids={ids} query={deleteReports} type="report" {...rest} />
  );
};

export default AdminDeleteReport;
