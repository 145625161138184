import React from 'react';
import { Box } from '@theme-ui/components';
import { Link } from 'react-router-dom';

import {
  Button,
  Container,
  Heading1,
  Promo1,
  Text3Light
} from '@tso/tso-components';

import {
  TodaysPick,
  OnThisPeriod,
  SearchInput,
  PopularCategories
} from '../../Components/index';

const Home = () => (
  <>
    <Box variant="layout.baz" sx={{ position: 'relative', zIndex: 4 }}>
      <Container variant="xxl">
        <Heading1 mb={3}>Discover the story of any place</Heading1>
        <Box>
          <SearchInput />
        </Box>
      </Container>
    </Box>

    <Container variant="large">
      <Text3Light>
        Every story needs a setting. The Story Of... is a project dedicated to
        uncovering, sharing and exploring thousands of vintage photographs so we
        can preserve the past and build a living history of the places all
        around us.
      </Text3Light>
    </Container>

    <OnThisPeriod />

    <Box py={3}>
      <Container variant="xxl">
        <Promo1
          name="Every place has a history and every photo adds to it"
          sx={{ mx: 'auto', maxWidth: 800 }}>
          <Button as={Link} to="/upload">
            Add photos
          </Button>
        </Promo1>
      </Container>
    </Box>

    <TodaysPick paged={false} />

    <PopularCategories />
  </>
);

export default Home;
