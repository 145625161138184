import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

function getParam(location, name) {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(location.search);

  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const defaultPage = 0;
const defaultStart = dayjs()
  .subtract(1, 'month')
  .format('YYYY-MM-DD');
const defaultEnd = dayjs()
  .add(1, 'month')
  .format('YYYY-MM-DD');

const useImageParams = () => {
  const location = useLocation();

  const [page, setPage] = useState(
    parseInt(getParam(location, 'start')) || defaultPage
  );
  const [start, setStart] = useState(
    getParam(location, 'start') || defaultStart
  );
  const [end, setEnd] = useState(getParam(location, 'end') || defaultEnd);

  /**
   * Whenever the user selects a page, sync it with the URL.
   */
  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?page=${page + 1}&start=${start}&end=${end}`;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [page, start, end]);

  useEffect(() => {
    const page = getParam(location, 'page');

    setPage(page ? parseInt(page) - 1 : defaultPage);
    setStart(getParam(location, 'start') || defaultStart);
    setEnd(getParam(location, 'end') || defaultEnd);
  }, [location]);

  return { page, setPage, start, setStart, end, setEnd };
};

export default useImageParams;
