"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var text = {
  test: {},
  zero: {
    fontSize: 0,
    lineHeight: 'tight',
    variant: 'text.test'
  },
  one: {
    fontSize: 1,
    lineHeight: 'tight',
    variant: 'text.test'
  },
  two: {
    fontSize: 2,
    lineHeight: 'body',
    variant: 'text.test'
  },
  three: {
    fontSize: 3,
    lineHeight: 'tight',
    variant: 'text.test'
  },
  four: {
    fontSize: 4,
    lineHeight: 'tight',
    variant: 'text.test'
  },
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    mt: 0
  },
  caps: {
    textTransform: 'uppercase'
  },
  h00: {
    variant: 'text.heading',
    fontSize: [44, null, 60],
    mb: 3
  },
  h0: {
    variant: 'text.heading',
    fontSize: [36, null, 44],
    mb: 3
  },
  h1: {
    variant: 'text.heading',
    fontSize: [24, 36],
    mb: 15
  },
  h2: {
    variant: 'text.heading',
    fontSize: [18, 24],
    mb: 15
  },
  h3: {
    variant: 'text.heading',
    fontSize: [14, null, 18],
    mb: 2
  },
  h4: {
    variant: 'text.heading',
    fontSize: [14, null, 16],
    mb: 2
  },
  h5: {
    variant: 'text.heading',
    fontSize: 14,
    mb: 2
  },
  h6: {
    variant: 'text.heading',
    fontSize: 12,
    mb: 1
  },
  lead: {
    fontSize: 18,
    fontWeight: 'regular',
    lineHeight: 'body'
  },
  text0: {
    light: {
      variant: 'text.zero',
      fontWeight: 'light',
      strong: {
        fontWeight: 'medium'
      }
    },
    regular: {
      variant: 'text.zero',
      fontWeight: 'regular'
    },
    medium: {
      variant: 'text.zero',
      fontWeight: 'medium'
    },
    bold: {
      variant: 'text.zero',
      fontWeight: 'bold'
    }
  },
  text1: {
    light: {
      variant: 'text.one',
      fontWeight: 'light'
    },
    regular: {
      variant: 'text.one',
      fontWeight: 'regular'
    },
    medium: {
      variant: 'text.one',
      fontWeight: 'medium'
    },
    bold: {
      variant: 'text.one',
      fontWeight: 'bold'
    }
  },
  text2: {
    light: {
      variant: 'text.two',
      fontWeight: 'light'
    },
    regular: {
      variant: 'text.two',
      fontWeight: 'regular'
    },
    medium: {
      variant: 'text.two',
      fontWeight: 'medium'
    },
    bold: {
      variant: 'text.two',
      fontWeight: 'bold'
    }
  },
  text3: {
    light: {
      variant: 'text.three',
      fontWeight: 'light'
    },
    regular: {
      variant: 'text.three',
      fontWeight: 'regular'
    },
    medium: {
      variant: 'text.three',
      fontWeight: 'medium'
    },
    bold: {
      variant: 'text.three',
      fontWeight: 'bold'
    }
  }
};
var _default = text;
exports["default"] = _default;