import React, { useState } from 'react';
import { Box } from '@theme-ui/components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';

import { Container } from '@tso/tso-components';

import { EditImage } from '../../Components/index';
import { imageEdit } from '../../fragments/index';

const GET_IMAGE = gql`
  query image($id: Int!) {
    image(id: $id) {
      ...ImageEdit
    }
  }
  ${imageEdit}
`;

const Enrichment = props => {
  const [imageEdited, setImageEdited] = useState(false);

  const { loading, error, data } = useQuery(GET_IMAGE, {
    variables: { id: parseInt(props.match.params.id) },
    fetchPolicy: 'network-only'
  });

  const handleEdit = () => setImageEdited(true);

  if (loading || error) {
    return null;
  }

  return (
    <Box variant="layout.baz">
      <Container>
        {imageEdited && (
          <Redirect to={`/upload/${props.match.params.id}/complete`} />
        )}
        <EditImage {...data.image} onEdit={handleEdit} />
      </Container>
    </Box>
  );
};

Enrichment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default Enrichment;
export { GET_IMAGE };
