import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Collections } from '@tso/tso-components';

import { userCollection } from '../../fragments/index';
import { PublishCollection, CollectionActions } from '../Collection/index';

const GET_PRIVATE_COLLECTIONS = gql`
  query privateCollections {
    privateCollections {
      ...UserCollection
    }
  }
  ${userCollection}
`;

const PrivateCollections = () => {
  const { data, loading, error } = useQuery(GET_PRIVATE_COLLECTIONS, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  return (
    <>
      {data.privateCollections.length > 0 && (
        <Collections
          collections={data.privateCollections.map(
            ({ id, name, description, user, header }) => ({
              Actions: CollectionActions,
              Publish: PublishCollection,
              id,
              name,
              description,
              ...(header && { image: { url: header.src } }),
              user: {
                id: user.id,
                name: user.name,
                image: {
                  url: user.profile_src
                }
              },
              is_private: true
            })
          )}
        />
      )}
    </>
  );
};

export default PrivateCollections;
export { GET_PRIVATE_COLLECTIONS };
