import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@theme-ui/components';

import {
  ActionButton,
  Button,
  Heading4,
  ListItem,
  Modal,
  ModalBody,
  ModalHeader,
  ShareButtonEmail,
  ShareButtonFacebook,
  ShareButtonPinterest,
  ShareButtonTwitter,
  ShareButtonWhatsapp
} from '@tso/tso-components';
import { Link0Medium } from '@tso/tso-components/build/Components/Link';

const Share = ({ shareData, buttonType = 'image', onShare = () => {} }) => {
  const linkRef = useRef();
  const [open, setOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const closeModal = () => {
    onShare();
    setOpen(false);
  };

  const openModal = () => setOpen(true);

  const copyLink = () => {
    linkRef.current.select();
    document.execCommand('copy');
    setCopySuccess(true);
  };

  return (
    <>
      {buttonType === 'image' && (
        <ActionButton
          icon="share"
          label="Share"
          onClick={openModal}
          aria-label="Share"
          mobile>
          Share
        </ActionButton>
      )}
      {buttonType === 'request' && (
        <Button label="Share" onClick={openModal} aria-label="Share">
          Share request
        </Button>
      )}
      {buttonType === 'list' && (
        <ListItem label="Share" onClick={openModal} aria-label="Share">
          Share
        </ListItem>
      )}

      <Modal isOpen={open} toggle={closeModal} size="small">
        <ModalHeader as={Heading4} toggle={closeModal} size="small">
          Share
        </ModalHeader>
        <ModalBody>
          <ShareButtonFacebook {...shareData} mb={2} />

          <ShareButtonTwitter {...shareData} mb={2} />

          <ShareButtonPinterest {...shareData} mb={2} />

          <ShareButtonWhatsapp {...shareData} mb={2} />

          <ShareButtonEmail {...shareData} mb={2} />

          <Flex
            sx={{
              alignItems: 'center',
              position: 'relative',
              borderBottom: theme =>
                `${theme.borderWidths[1]}px solid ${theme.colors.gray[2]}`
            }}>
            <Box
              as="input"
              ref={linkRef}
              readOnly
              defaultValue={shareData.url}
              sx={{
                fontSize: 'body', // 16px
                fontFamily: 'inherit',
                fontWeight: 'regular',
                lineHeight: 'body', // 24px
                px: 0,
                py: 2,
                bg: 'transparent',
                border: 0,
                borderRadius: 0,
                outline: 0,
                appearance: 'none',
                flex: '1 1 auto',
                mr: 1
              }}
            />
            <Link0Medium aria-label="CopyLink" onClick={copyLink}>
              {copySuccess ? 'Copied!' : 'Copy Link'}
            </Link0Medium>
          </Flex>
        </ModalBody>
      </Modal>
    </>
  );
};

Share.propTypes = {
  shareData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string.isRequired
  }),
  buttonType: PropTypes.oneOf(['image', 'request', 'list']),
  onShare: PropTypes.func
};

export default Share;
