import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@theme-ui/components';

import { Select, TabContent, TabLink, TabPane } from '@tso/tso-components';

import { days, months, years, defaultDate } from '../data/index';

const DatePicker = ({ date = {}, setDate }) => {
  const [activeTab, setActiveTab] = useState(date.year_start ? 2 : 1);

  const handleYearChange = event => setDate({ ...date, year: event.value });
  const handleMonthChange = event => setDate({ ...date, month: event.value });
  const handleDayChange = event => setDate({ ...date, day: event.value });

  const handleYearStartChange = event =>
    setDate({ ...date, year_start: event.value });
  const handleYearEndChange = event =>
    setDate({ ...date, year_end: event.value });

  const toggleTab = tab => event => {
    event.preventDefault();
    setDate({ ...defaultDate });

    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Box mb={3}>
        <TabLink onClick={toggleTab(1)} active={activeTab === 1}>
          DATE
        </TabLink>
        <TabLink onClick={toggleTab(2)} active={activeTab === 2}>
          YEAR RANGE
        </TabLink>
      </Box>

      <TabContent activeTab={activeTab} style={{ marginBottom: '3rem' }}>
        <TabPane tabId={1}>
          <Grid columns={[0, 3]}>
            <Select
              name="year"
              placeholder="Year"
              options={years}
              onChange={handleYearChange}
              value={date.year && { label: date.year, value: date.year }}
            />
            <Select
              name="month"
              placeholder="Month"
              options={months}
              onChange={handleMonthChange}
              value={date.month && { label: date.month, value: date.month }}
            />
            <Select
              name="day"
              placeholder="Day"
              value={date.day && { label: date.day, value: date.day }}
              options={days([date.year, date.month])}
              onChange={handleDayChange}
            />
          </Grid>
        </TabPane>
        <TabPane tabId={2}>
          <Grid columns={[0, 2]}>
            <Select
              name="from"
              placeholder="From"
              options={years}
              onChange={handleYearStartChange}
              value={
                date.year_start && {
                  label: date.year_start,
                  value: date.year_start
                }
              }
            />
            <Select
              name="to"
              placeholder="To"
              options={years}
              onChange={handleYearEndChange}
              isDisabled={!date.year_start}
              value={
                date.year_end && {
                  label: date.year_end,
                  value: date.year_end
                }
              }
            />
          </Grid>
        </TabPane>
      </TabContent>
    </>
  );
};

DatePicker.propTypes = {
  date: PropTypes.any,
  setDate: PropTypes.func.isRequired
};

export default DatePicker;
