import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@theme-ui/components';

import {
  Modal,
  ModalFooter,
  ModalHeader,
  Button,
  Container,
  ModalBody,
  Img
} from '@tso/tso-components';

import { GET_IMAGE } from './Enrichment';

const Confirmation = ({ history, match }) => {
  const { data, loading, error } = useQuery(GET_IMAGE, {
    variables: { id: parseInt(match.params.id) }
  });

  if (!data || loading || error) {
    return null;
  }

  const handleClose = () => history.push(`/images/${data.image.id}`);

  return (
    <Box variant="layout.baz">
      <Container>
        <Modal isOpen={true} toggle={handleClose}>
          <ModalHeader toggle={handleClose}>
            Your image has been uploaded
          </ModalHeader>
          <ModalBody>
            <Img
              src={data.image.src}
              orientation={data.image.revision.orientation}
            />
          </ModalBody>
          <ModalFooter vertical>
            <Button as={Link} to="/upload">
              Upload more
            </Button>
            <Button as={Link} to={`/images/${data.image.id}`} appearance="link">
              View
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </Box>
  );
};

Confirmation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Confirmation;
