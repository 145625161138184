import React from 'react';
import PropTypes from 'prop-types';

import { Collections } from '@tso/tso-components';

import CollectionActions from './CollectionActions';

const FeaturedIn = ({ collections }) => (
  <Collections
    name="Featured collections"
    collections={collections.map(({ id, name, description, user, header }) => ({
      Actions: CollectionActions,
      id,
      name,
      description,
      url: `/collections/${id}`,
      ...(header && { image: { url: header.src } }),
      user: {
        id: user.id,
        name: user.name,
        url: `users/${user.id}`,
        image: { url: user.profile_src }
      },
      is_private: false
    }))}
    py={3}
  />
);

FeaturedIn.propTypes = {
  collections: PropTypes.array.isRequired
};

export default FeaturedIn;
