import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import { ImageGrid, Image } from '@tso/tso-components';

import ImageActions from './ImageActions';
import InfiniteScroll from '../InfiniteScroll';
import { GET_IMAGES } from '../Results/Results';
import { paginate } from '../../actions/index';
import { useScrollPosition } from '../../hooks/index';

const ImagesByLocation = ({ latitude, longitude, between, on }) => {
  const [gridLoaded, setGridLoaded] = useState(false);
  const scroll = useScrollPosition();
  const { data, loading, error, fetchMore } = useQuery(GET_IMAGES, {
    variables: {
      page: 0,
      latitude,
      longitude,
      distance: 'LOCAL',
      ...(between && { year_start: between[0], year_end: between[1] }),
      ...(on && { date: on })
    },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (!loading && gridLoaded && scroll) {
      window.scrollTo(0, scroll);
    }
  }, [loading, scroll, gridLoaded]);

  if (loading || error) {
    return null;
  }

  const handlePagination = () => {
    const { currentPage, hasNextPage } = data.images.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { latitude, longitude, page: currentPage + 1 },
      updateQuery: paginate('images')
    });
  };

  if (data.images.total === 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{"This location doesn't any images yet"}</h2>
      </div>
    );
  }

  return (
    <InfiniteScroll onBottom={handlePagination}>
      <ImageGrid
        loadedCallback={() => setGridLoaded(true)}
        name={`${data.images.total} ${
          data.images.total > 1 ? 'photos' : 'photo'
        }`}>
        {data.images.data.map(
          ({ id, is_unidentified, src, revision, ...rest }) => (
            <Image
              Actions={ImageActions}
              unidentified={is_unidentified}
              orientation={revision.orientation}
              key={id}
              id={id}
              image={{ url: src }}
              {...revision}
              {...rest}
            />
          )
        )}
      </ImageGrid>
    </InfiniteScroll>
  );
};

ImagesByLocation.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  between: PropTypes.arrayOf(PropTypes.string),
  on: PropTypes.string
};

export default ImagesByLocation;
