import React, { useState } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Box } from '@theme-ui/components';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  ActionButton,
  ModalFooter
} from '@tso/tso-components';

import { useAlert } from '../../hooks/index';

const DELETE_REQUEST = gql`
  mutation deleteRequest($id: Int!) {
    deleteRequest(id: $id) {
      id
    }
  }
`;

const DeleteCollectionModal = ({ id }) => {
  const [open, setOpen] = useState(false);

  const { showSuccess } = useAlert();

  const [deleteRequest] = useMutation(DELETE_REQUEST, {
    refetchQueries: ['userRequests', 'requests']
  });
  const history = useHistory();
  const location = useLocation();

  const toggleOpen = () => setOpen(open => !open);

  const handleSubmit = async () => {
    await deleteRequest({ variables: { id } });

    // Only redirect if on the collections page.
    if (location.pathname === `/requests/${id}`) {
      history.push('/requests');
    }

    showSuccess('Request deleted successfully');
    toggleOpen();
  };

  return (
    <Box>
      <Modal isOpen={open}>
        <ModalHeader>Are you sure you want to delete this request?</ModalHeader>
        <ModalBody>
          <Text sx={{ textAlign: 'center' }}>
            Deleting will remove it from our community requests page, but any
            images you uploaded will still be part of The Story Of.
          </Text>
        </ModalBody>
        <ModalFooter sx={{ textAlign: 'center' }}>
          <Button onClick={toggleOpen} appearance="link">
            Cancel
          </Button>
          <Button onClick={handleSubmit} aria-label={'deleteButton'}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <ActionButton
        label="deleteRequest"
        onClick={toggleOpen}
        aria-label="Delete">
        Delete
      </ActionButton>
    </Box>
  );
};

DeleteCollectionModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export { DELETE_REQUEST };
export default DeleteCollectionModal;
