import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Link0Medium, Text0Light } from '@tso/tso-components';

const HelpOut = ({ onClick, text }) => (
  <>
    <Text0Light>{text}</Text0Light>
    <Link0Medium
      as="button"
      onClick={onClick}
      variant="link0.medium"
      color="primary">
      Know the exact date?
    </Link0Medium>
  </>
);

HelpOut.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

const ImageDate = ({
  date,
  month,
  year,
  year_start,
  year_end,
  onClick = () => {}
}) => {
  if (date) {
    return dayjs(date).format('D MMM YYYY');
  }

  if (month && year) {
    return (
      <HelpOut
        text={dayjs(new Date(year, month - 1)).format('D MMM')}
        onClick={onClick}
      />
    );
  }

  if (year) {
    return <HelpOut text={year} onClick={onClick} />;
  }

  if (year_start && year_end) {
    return (
      <HelpOut
        text={`Between ${year_start} and ${year_end}`}
        onClick={onClick}
      />
    );
  }

  return (
    <Link0Medium
      as="button"
      onClick={onClick}
      variant="link0.medium"
      color="primary">
      When was this photo taken?
    </Link0Medium>
  );
};

ImageDate.propTypes = {
  date: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  year_start: PropTypes.string,
  year_end: PropTypes.string,
  onClick: PropTypes.func
};

export default ImageDate;
