import React, { useEffect } from 'react';
import { Box } from '@theme-ui/components';

import { Container, Heading1, Promo2 } from '@tso/tso-components';
import {
  AllCollections,
  CreateCollectionModal,
  Meta
} from '../../Components/index';

const Collection = () => {
  useEffect(() => localStorage.removeItem('selectedImage'), []);
  return (
    <>
      <Meta title="Collections" />
      <Box variant="layout.baz">
        <Container>
          <Box sx={{ maxWidth: 960, mx: 'auto' }}>
            <Heading1>Collections</Heading1>

            <Promo2
              name="Tell a story of somewhere that matters to you"
              description="Create collections to organise your photos and tell amazing
                stories about the places you love.">
              <CreateCollectionModal />
            </Promo2>
          </Box>
        </Container>
      </Box>

      <AllCollections />
    </>
  );
};

export default Collection;
