import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  List,
  IconButton
} from '@tso/tso-components';

import ReportComment from './ReportComment';
import { useAuth } from '../../../hooks/index';
import AuthListItem from '../../AuthListItem';

const CommentActions = ({ id, user }) => {
  const [options, setOptions] = useState(false);

  const { isGuest, user: loggedInUser } = useAuth();

  const toggleOptions = () => setOptions(prevState => !prevState);

  if (isGuest) {
    return null;
  }

  const isUsersComment = loggedInUser.id === user.id;

  if (isUsersComment) {
    return null;
  }

  return (
    <Box sx={{ display: ['none', 'block'], flex: 'none' }}>
      <Dropdown isOpen={options} toggle={toggleOptions}>
        <DropdownToggle
          as={IconButton}
          icon="more"
          aria-label="Comment options"
          appearance="muted"
        />
        <DropdownMenu right>
          <List>
            <ReportComment Submit={AuthListItem} id={id} />
          </List>
        </DropdownMenu>
      </Dropdown>
    </Box>
  );
};

CommentActions.propTypes = {
  id: PropTypes.number,
  user: PropTypes.object
};

export default CommentActions;
