import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';

import { useQuery } from '@apollo/react-hooks';
import { Checkbox, Label } from '@tso/tso-components';
import { Box, Flex } from '@theme-ui/components';
import { Link } from 'react-router-dom';

import {
  Button,
  Container,
  Heading1,
  Image,
  ImageGrid,
  Promo2,
  Text0Bold,
  Text1Bold
} from '@tso/tso-components';

import SortBy from '../../Components/SortBy';
import { InfiniteScroll, ImageActions, Meta } from '../../Components/index';
import { paginate } from '../../actions/index';
import { imageResult, loggedInUser } from '../../fragments/index';
import { description } from '../../validation/index';
import { useScrollPosition, useSortSelection } from '../../hooks';

const GET_UNIDENTIFIED_IMAGES = gql`
  query unidentified($page: Int, $sort: Sort, $user_id: Int) {
    unidentified(page: $page, sort: $sort, user_id: $user_id) {
      data {
        ...ImageResult
      }
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
      total
    }
  }
  ${imageResult}
`;

const GET_LOGGED_IN = gql`
  {
    user @client {
      ...LoggedInUser
    }
    isLoggedIn @client
  }
  ${loggedInUser}
`;

const Unidentified = () => {
  const { sortBy, addSort } = useSortSelection();
  const [gridLoaded, setGridLoaded] = useState(false);
  const scroll = useScrollPosition();
  const {
    data: { user }
  } = useQuery(GET_LOGGED_IN);
  const { id: userId } = user;

  const [userOnly, setUserOnly] = useState(false);

  const handleUserToggle = () => setUserOnly(!userOnly);

  const { data, loading, error, fetchMore } = useQuery(
    GET_UNIDENTIFIED_IMAGES,
    {
      fetchPolicy: 'cache-first',
      variables: {
        page: 0,
        sort: sortBy?.value,
        user_id: userOnly ? userId : null
      }
    }
  );

  useEffect(() => {
    if (!loading && gridLoaded && scroll) {
      window.scrollTo(0, scroll);
    }
  }, [loading, scroll, gridLoaded]);

  const handlePagination = () => {
    const { currentPage, hasNextPage } = data.unidentified.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: {
        page: currentPage + 1,
        sort: sortBy.value,
        user_id: userOnly ? userId : null
      },
      updateQuery: paginate('unidentified')
    });
  };

  const handleSort = async option => {
    await addSort(option);
  };

  if (loading || error) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handlePagination}>
      <Meta title="Unidentified" />
      <Box variant="layout.baz">
        <Container variant="xxl">
          <Heading1 sx={{ maxWidth: 768 }}>
            Help us identify a location or a date for these images.
          </Heading1>
          <Flex
            sx={{
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              py: 2,
              mb: 3,
              borderTop: 1,
              borderBottom: 1,
              borderColor: 'gray.4'
            }}>
            <Text0Bold>{data.unidentified.total} photos</Text0Bold>
            <Flex sx={{ alignItems: 'center' }}>
              <Label variant="checkbox" sx={{ mr: 2, flex: 1 }}>
                <Text1Bold sx={{ mr: 2 }}>My Images</Text1Bold>
                <Checkbox
                  checked={userOnly}
                  onChange={handleUserToggle}
                  aria-label={'isPrivateToggle'}
                />
              </Label>
              <Text1Bold sx={{ mr: 2 }}>Sort by</Text1Bold>
              <SortBy value={sortBy} handleChange={handleSort} />
            </Flex>
          </Flex>
          <Promo2
            name="Do you have some photos you can't identify?"
            description="Add them to The Story Of and our community will help to identify them."
            sx={{ maxWidth: 960, mx: 'auto', mb: 3 }}>
            <Button as={Link} to="/upload">
              Add photos
            </Button>
          </Promo2>
        </Container>
      </Box>

      <ImageGrid loadedCallback={() => setGridLoaded(true)}>
        {data.unidentified.data.map(
          ({ id, is_unidentified, src, revision, ...rest }) => (
            <Image
              Actions={ImageActions}
              unidentified={is_unidentified}
              key={id}
              id={id}
              isPublic={true}
              image={{ url: src }}
              name={revision.name}
              description={description(revision)}
              orientation={revision.orientation}
              {...rest}
            />
          )
        )}
      </ImageGrid>
    </InfiniteScroll>
  );
};

export default Unidentified;
export { GET_UNIDENTIFIED_IMAGES };
