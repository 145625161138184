import { useState } from 'react';

/**
 * Build the given date, returning either the date,
 * the year range, or nothing.
 */
const builtDate = ({ year_start, year_end, ...dateParts }) => {
  const passedYearRange = Boolean(year_start && year_end);

  let date = {};

  if (passedYearRange) {
    date = { year_start, year_end };
  } else {
    date = { ...dateParts };
  }

  let cleanDate = {};

  // Remove any empty values.
  for (const key in date) {
    if (date[key]) {
      cleanDate[key] = date[key];
    }
  }

  return cleanDate;
};

const useDate = ({ year_start, year_end, day, month, year } = {}) => {
  const [date, setDate] = useState({ year_start, year_end, day, month, year });

  /**
   * Set the date, removing any values that fail validation. Date can have the
   * following values:
   * - All null values
   * - day month year
   * - month year
   * - year
   * - year_start, year_end
   */
  const handleDateChange = ({ setup = false, day, month, year, ...rest }) => {
    const yearChanged = !setup && year !== date.year;

    setDate({
      ...rest,
      year,
      ...(yearChanged && { day: '', month: '' }),
      ...(!yearChanged && {
        month: year ? month : '',
        day: month && year ? day : ''
      })
    });
  };

  return {
    date,
    setDate: handleDateChange,
    givenFullDate: Boolean(date.day && date.month && date.year),
    givenYearRange: Boolean(date.year_start && date.year_end),
    builtDate: builtDate(date)
  };
};

export default useDate;
