import React, { useState } from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@tso/tso-components';

import { useAlert } from '../../hooks/index';

const DELETE_OTHER_ACCOUNT = gql`
  mutation deleteOtherAccount($id: Int!) {
    deleteOtherAccount(id: $id)
  }
`;

const DeleteUser = ({ id, ...rest }) => {
  const [askedForConfirmation, setAskedForConfirmation] = useState(false);

  const { showSuccess } = useAlert();

  const [deleteUser] = useMutation(DELETE_OTHER_ACCOUNT, {
    refetchQueries: ['getUsers']
  });

  const handleDelete = async () => {
    if (!askedForConfirmation) {
      return setAskedForConfirmation(true);
    }

    if (askedForConfirmation) {
      const response = await deleteUser({ variables: { id } });
      if (response.data.deleteOtherAccount === true) {
        showSuccess('Successfully deleted user');
        setAskedForConfirmation(false);
      }
    }
  };

  return (
    <>
      <Button
        size={'small'}
        onClick={handleDelete}
        appearance={askedForConfirmation ? 'primary' : 'link'}
        {...rest}>
        {askedForConfirmation ? 'Confirm' : 'Delete'}
      </Button>
      {askedForConfirmation && (
        <Button
          size={'small'}
          style={{ marginLeft: '1rem' }}
          onClick={() => setAskedForConfirmation(false)}
          appearance={'link'}>
          Cancel
        </Button>
      )}
    </>
  );
};

export default DeleteUser;
