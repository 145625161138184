import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Collections } from '@tso/tso-components';

import { userCollection } from '../../fragments/index';
import InfiniteScroll from '../InfiniteScroll.js';
import { paginate } from '../../actions/index';
import CollectionActions from './CollectionActions';

const GET_COLLECTIONS = gql`
  query collections($page: Int, $sort: Sort) {
    collections(page: $page, sort: $sort) {
      data {
        ...UserCollection
        created_at
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${userCollection}
`;

const AllCollections = () => {
  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTIONS, {
    variables: { page: 0 },
    fetchPolicy: 'network-only'
  });

  const handlePagination = () => {
    const { currentPage, hasNextPage } = data.collections.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { page: currentPage + 1 },
      updateQuery: paginate('collections')
    });
  };

  if (loading || error) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handlePagination}>
      <Collections
        collections={data.collections.data.map(
          ({ id, name, description, user, header }) => ({
            Actions: CollectionActions,
            id,
            name,
            description,
            url: `/collections/${id}`,
            ...(header && {
              image: { url: header.src },
              orientation: header.revision.orientation
            }),
            user: {
              id: user.id,
              name: user.name,
              url: `users/${user.id}`,
              image: { url: user.profile_src }
            },
            is_private: false
          })
        )}
      />
    </InfiniteScroll>
  );
};

export default AllCollections;
export { GET_COLLECTIONS };
