"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.baseColors = void 0;
var baseColors = {
  white: '#fff',
  red: ['#CA2D13', '#F7451B', '#FA886D', '#FEE6E0'],
  gray: ['#111111', '#6C6A7A', '#A9A8B3', '#CCCBD1', '#F5F4F8'],
  // blue: '#007bff',
  // indigo: '#6610f2',
  purple: '#6f42c1',
  // pink: '#e83e8c',
  // red: '#dc3545',
  // orange: '#fd7e14',
  yellow: ['#F7A91B', '#fed388'],
  green: ['#00C000', '#a8eeaf'] // teal: '#20c997'
  // cyan: '#17a2b8',
  // lime: '#47f2bb'

};
exports.baseColors = baseColors;
var colors = {
  text: baseColors.gray[0],
  background: baseColors.white,
  primary: baseColors.red[1],
  secondary: baseColors.gray[2],
  accent: baseColors.purple,
  gray: baseColors.gray,
  red: baseColors.red,
  muted: baseColors.gray[3],
  highlight: '#F6F3C2',
  darken: '#333',
  success: '#00C000',
  info: baseColors.cyan,
  warning: baseColors.yellow[0],
  danger: baseColors.red[0],
  facebook: '#3355A4',
  twitter: '#1DA1F2',
  pinterest: '#FF0026',
  whatsapp: '#25D366',
  email: baseColors.gray[2],
  modes: {
    dark: {
      text: '#fff',
      background: '#000',
      primary: '#0cf'
    }
  }
};
var _default = colors;
exports["default"] = _default;