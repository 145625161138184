"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
// import prismOkaidia from '@theme-ui/prism/presets/prism-okaidia.json'
var styles = {
  p: {
    mt: 0,
    fontSize: [1, 2],
    mb: 3
  },
  a: {
    color: 'inherit',
    '&:focus, &:hover, &:active': {
      color: 'primary',
      textDecoration: 'underline'
    }
  },
  h1: {
    variant: 'text.h1'
  },
  h2: {
    variant: 'text.h2'
  },
  h3: {
    variant: 'text.h3'
  },
  h4: {
    variant: 'text.h4'
  },
  h5: {
    variant: 'text.h5'
  },
  h6: {
    variant: 'text.h6'
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    display: 'inline-block',
    verticalAlign: 'middle' // width: '100%',

  },
  pre: {
    // ...nightOwl,
    // ...prismTomorrow,
    // ...prismDark,
    // ...prismOkaidia,
    // ...prismTwilight,
    // ...prism,
    p: 3,
    mt: 0,
    mb: 3,
    // bg: 'text',
    // color: 'background',
    // fontFamily: 'monospace',
    // fontSize: 'inherit',
    overflow: 'auto',
    whiteSpace: 'pre-wrap',
    WebkitOverflowScrolling: 'touch' // '&::-webkit-scrollbar-thumb': {
    //   background: '#f4d1c6',
    // },
    // '&::-webkit-scrollbar-track': {
    //   background: '#faede5',
    // },

  },
  code: {
    fontFamily: 'monospace',
    fontSize: 'inherit'
  },
  ol: {},
  ul: {
    mt: 0,
    pl: 3,
    // listStylePosition: 'outside',
    mb: 3
  },
  li: {},
  blockquote: {
    fontSize: 3,
    mb: 3
  },
  hr: {
    border: 0,
    borderTopWidth: 'default',
    borderStyle: 'solid',
    borderColor: 'gray.2',
    mt: 4,
    mb: 3
  },
  em: {},
  table: {
    // todo
    width: '100%',
    tableLayout: 'fixed',
    mb: 4,
    // fontSize: 1,
    borderCollapse: 'collapse',
    borderSpacing: 0,
    borderColor: 'transparent',
    borderTop: function borderTop(theme) {
      return "".concat(theme.borderWidths[1], "px ").concat(theme.borderStyles["default"], " ").concat(theme.colors.gray[3]);
    }
  },
  tr: {},
  th: {
    verticalAlign: 'top',
    py: 2,
    px: 0,
    fontWeight: 'bold',
    borderBottom: function borderBottom(theme) {
      return "".concat(theme.borderWidths[1], "px ").concat(theme.borderStyles["default"], " ").concat(theme.colors.gray[3]);
    },
    textAlign: 'inherit',
    lineHeight: 'heading' // fontSize: 1

  },
  td: {
    borderBottom: function borderBottom(theme) {
      return "".concat(theme.borderWidths[1], "px ").concat(theme.borderStyles["default"], " ").concat(theme.colors.gray[3]);
    },
    py: 2,
    px: 0,
    verticalAlign: 'top',
    ul: {
      m: 0,
      '&:not(:last-of-type)': {
        mb: 3
      }
    }
  },
  strong: {
    fontWeight: 'regular'
  },
  "delete": {},
  b: {},
  i: {},
  inlineCode: {
    color: 'pink'
  },
  thematicBreak: {},
  div: {},
  root: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    bg: 'background',
    color: 'text'
  }
};
var _default = styles;
exports["default"] = _default;