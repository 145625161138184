import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Box } from '@theme-ui/components';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Button,
  FormGroup,
  Input,
  Text1Regular,
  Link1Medium,
  Heading
} from '@tso/tso-components';

import { useAuth, useAlert } from '../../hooks/index';
import { AuthContainer } from '../../Components/index';

const REQUEST_NEW_PASSWORD = gql`
  mutation requestNewPassword($email: Email!) {
    requestNewPassword(email: $email)
  }
`;

const RequestNewPassword = () => {
  const [email, setEmail] = useState('');

  const { isNotGuest } = useAuth();
  const { showSuccess } = useAlert();

  const [request] = useMutation(REQUEST_NEW_PASSWORD);

  const handleEmailChange = event => setEmail(event.target.value);

  const handleSubmit = async event => {
    event.preventDefault();
    await request({ variables: { email } });
    showSuccess('If you have an account, you will receive an email shortly.');
    setEmail('');
  };

  return (
    <AuthContainer>
      {isNotGuest && <Redirect push to="/" />}
      <Box variant="layout.form">
        <form onSubmit={handleSubmit}>
          <Heading>Request New Password</Heading>
          <FormGroup>
            <Input
              onChange={handleEmailChange}
              type="email"
              placeholder="Email *"
              name="email"
              aria-label="email"
              value={email}
            />
          </FormGroup>

          <Text1Regular mb={3}>
            <Link1Medium as={Link} to="/login" color="primary">
              Know your password?
            </Link1Medium>
          </Text1Regular>

          <Button disabled={!email} type="submit" aria-label="submit" isBlock>
            Submit
          </Button>
        </form>
      </Box>
    </AuthContainer>
  );
};

export default RequestNewPassword;
export { REQUEST_NEW_PASSWORD };
