import { useState } from 'react';

const getParam = (location, param) => {
  const serachParam = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + serachParam + '=([^&#]*)');
  const results = regex.exec(location.search);

  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const useEmailToken = location => {
  const [token] = useState(getParam(location, 'token'));
  const [email] = useState(getParam(location, 'email'));

  const isValid = Boolean(token && email);

  return { token, email, isValid, isNotValid: !isValid };
};

export default useEmailToken;
export { getParam };
