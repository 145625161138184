const locations = [
  'Acle',
  'Alburgh',
  'Alby',
  'Aldborough',
  'Aldeby',
  'Alderford',
  'Alethorpe',
  'Alpington',
  'Anmer',
  'Antingham',
  'Arminghall',
  'Ashby',
  'Ashby St Mary',
  'Ashill',
  'Ashmanhaugh',
  'Ashwellthorpe',
  'Ashwicken',
  'Aslacton',
  'Attleborough',
  'Attlebridge',
  'Aylmerton',
  'Aylsham',
  'Babingley',
  'Baconsthorpe',
  'Bacton',
  'Bacton Green',
  'Bagthorpe',
  'Bale',
  'Banham',
  'Banningham',
  'Barford',
  'Barmer',
  'Barney',
  'Barnham Broom',
  'Barningham Green',
  'Barton Bendish',
  'Barton Turf',
  'Barwick',
  'Bastwick',
  'Bawburgh',
  'Bawdeswell',
  'Bawsey',
  'Beachamwell',
  'Beckett End',
  'Beckhithe',
  'Bedingham',
  'Beeston',
  'Beeston Regis',
  'Beeston St Andrew',
  'Beeston St Lawrence',
  'Beetley',
  'Beighton',
  'Belaugh',
  'Belton',
  'Bengate',
  'Bergh Apton',
  'Berney Arms',
  'Bessingham',
  'Besthorpe',
  'Bexfield',
  'Bexwell',
  'Billingford (South Norfolk)',
  'Billingford (Breckland)',
  'Billockby',
  'Binham',
  'Bintree',
  'Bircham Newton',
  'Bircham Tofts',
  'Bittering',
  'Blackborough End',
  'Blakeney',
  'Blakeney Point',
  'Blickling',
  'Blofield',
  'Bodham',
  'Bodney',
  'Booton',
  'Boughton',
  'Bowthorpe',
  'Bracon Ash',
  'Bradenham',
  'Bradfield',
  'Bradwell',
  'Brakefield Green',
  'Bramerton',
  'Brampton',
  'Brancaster',
  'Brancaster Staithe',
  'Brandiston',
  'Brandon Bank',
  'Brandon Creek',
  'Brandon Parva',
  'Breckles',
  'Brettenham',
  'Bressingham',
  'Breydon Water',
  'Bridgham',
  'Briningham',
  'Brinton',
  'Brisley',
  'Briston',
  'Brooke',
  'Broom Green',
  'Broome',
  'Broomholm',
  'Broomsthorpe',
  'Browston Green',
  'Brumstead',
  'Brundall',
  'Buckenham',
  "Bull's Green",
  'Bunkers Hill',
  'Bunwell',
  'Burgh-next-Aylsham',
  'Burgh Castle',
  'Burgh St Margaret',
  'Burgh St Peter',
  'Burlingham',
  'Burlingham Green',
  'Burnham Deepdale',
  'Burnham Market',
  'Burnham Norton',
  'Burnham Overy',
  'Burnham Thorpe',
  'Burston',
  'Bush Green',
  "Bustard's Green",
  'Buxton',
  'Bylaugh',
  'Caister-on-Sea',
  'Caistor St Edmund',
  'California',
  'Calthorpe Street',
  'Cantley',
  'Carbrooke',
  'Cargate Green',
  'Carleton Forehoe',
  'Carleton Rode',
  'Castle Acre',
  'Castle Rising',
  'Caston',
  'Catfield',
  '(Old) Catton',
  'Cawston',
  'Cess',
  'Chedgrave',
  'Choseley',
  'Claxton',
  'Clenchwarton',
  'Cley next the Sea',
  'Clint Green',
  'Clippesby',
  "Cobbler's Green",
  'Cobholm Island',
  'Cockley Cley',
  'Cockthorpe',
  'Colby',
  'Coldham Green',
  'Colkirk',
  'Colney',
  'Coltishall',
  'Colton',
  'Congham',
  'Cordwell',
  'Corpusty',
  'Costessey',
  'Coston',
  'Crabgate',
  'Cranwich',
  'Cranworth',
  'Crimplesham',
  'Cringleford',
  'Cromer',
  'Crossdale Street',
  'Crostwick',
  'Crostwight',
  'Crowgate Street',
  'Crownthorpe',
  'Crowshill',
  'Croxton',
  'Damgate',
  'Darrow Green',
  'Denton',
  'Denver',
  'Deopham',
  'Deopham Green',
  'Dereham',
  'Dersingham',
  'Dickleburgh',
  'Didlington',
  'Dilham',
  'Diss',
  'Ditchingham',
  'Docking',
  'Downham Market',
  'Drabblegate',
  'Drayton',
  'Drury Square',
  'Dumpling Green',
  'Dunston',
  'Dunton',
  'Earlham',
  'Earsham',
  'East Barsham',
  'East Beckham',
  'East Bradenham',
  'East Dereham',
  'East Harling',
  'East Lexham',
  'East Raynham',
  'East Rudham',
  'East Runton',
  'East Ruston',
  'East Somerton',
  'East Tuddenham',
  'East Walton',
  'East Wretham',
  'East Winch',
  'Eastgate',
  'Eastmoor',
  'Easton',
  'Eaton',
  'Eccles On Sea',
  'Edingthorpe',
  'Egmere',
  'Erpingham',
  'Etling Green',
  'Fairstead',
  'Fakenham',
  'Felbrigg',
  'Felthorpe',
  'Feltwell',
  'Fen Row',
  'Fersfield',
  'Fiddler’s Green',
  'Field Dalling',
  'Filby',
  'Fishley',
  'Fleggburgh',
  'Flitcham',
  'Flordon',
  'Forncett St Mary',
  'Forncett St Peter',
  'Forncett End',
  'Foulden',
  'Foulsham',
  'Foxley',
  'Framingham Earl',
  'Freeland Corner',
  'Frettenham',
  'Friars Quay (Norwich)',
  'Fring',
  'Fritton (near Great Yarmouth)',
  'Fritton (near Morning Thorpe)',
  'Frogshall',
  'Frost Row',
  'Fulmodeston',
  'Fundenhall',
  'Garboldisham',
  'Gateley',
  'Gayton Thorpe',
  'Geldeston',
  'Gillingham',
  'Gimingham',
  'Gissing',
  'Glandford',
  'Gooderstone',
  'Gorleston-on-Sea',
  'Great Bircham',
  'Great Cressingham',
  'Great Dunham',
  'Great Fransham',
  'Great Hockham',
  'Great Massingham',
  'Great Moulton',
  'Great Plumstead',
  'Great Ryburgh',
  'Great Snoring',
  'Great Walsingham',
  'Great Yarmouth',
  'Greensgate',
  'Gressenhall Green',
  'Grimston',
  'Griston',
  'Guist',
  'Gunthorpe',
  'Hackford',
  'Hainford',
  'Hales',
  'Halvergate',
  'Hanworth',
  'Happisburgh',
  'Hapton',
  'Hardingham',
  'Hardwick',
  'Harleston',
  'Harpley',
  'Hassingham',
  'Haverscroft Street',
  'Hawes Green',
  'Hay Green',
  'Heacham',
  'Hedenham',
  'Hellesdon',
  'Hemblington',
  'Hempnall',
  'Hemsby',
  'Hethersett',
  'Hevingham',
  'Heydon',
  'Hickling',
  'High Green',
  'Highoak',
  'Hilgay',
  'Hillington',
  'Hindolveston',
  'Hindringham',
  'Hingham',
  'Hockering',
  'Hockwold cum Wilton',
  'Hoe',
  'Holkham',
  'Holme next the Sea',
  'Holt',
  'Honing',
  'Honingham',
  'Hopton-on-Sea',
  'Horning',
  'Horningtoft',
  'Horsey',
  'Horsford',
  'Horsham St Faith',
  'Horstead',
  'Houghton on the Hill',
  'Houghton St Giles',
  'Hoveton',
  'Howe',
  'Hunstanton',
  "Hunt's Corner",
  'Hunworth',
  'Illington',
  'Ingham',
  'Ingoldisthorpe',
  'Ingleborough',
  'Ingworth',
  'Intwood',
  'Irstead',
  'Irstead Street',
  'Ivy Todd',
  'Itteringham',
  'Ickburgh',
  "Johnson's Street",
  'Jordan Street',
  'Kenninghall',
  'Kelling',
  'Keswick (near Norwich)',
  'Keswick (near Bacton)',
  'Kettlestone',
  'Kimberley',
  "King's Lynn",
  'Kirby Bedon',
  'Kirby Row',
  'Kirstead Green',
  'Kempstone (nr Litcham)',
  'Lamas',
  'Langford',
  'Langham',
  'Langley Street',
  'Larling',
  'Lenwade',
  'Letheringsett',
  'Limpenhoe',
  'Lingwood',
  'Litcham',
  'Little Barningham',
  'Little Cressingham',
  'Little Ellingham',
  'Little Hautbois',
  'Little London',
  'Little Melton',
  'Little Ryburgh',
  'Little Snoring',
  'Little Thornage',
  'Little Walsingham',
  'Loddon',
  'Long Row',
  'Long Stratton',
  'Longham',
  'Low Street (Hardingham)',
  'Low Street (North Tuddenahm)',
  'Low Street (Rollesby)',
  'Low Street (Wortwell)',
  'Low Thurlton',
  'Lower Green (Freethorpe)',
  'Lower Green (Hindringham)',
  'Lower Stow Bedon',
  'Lower Street (Horning)',
  'Lower Street (Mundesley)',
  'Lower Tasburgh',
  'Lower Thurlton',
  'Ludham',
  'Lynch Green',
  'Lyng',
  'Lyngate',
  'Manson Green',
  'Marham',
  'Marlingford',
  'Marshland St James',
  'Martham',
  'Matlaske',
  'Mattishall',
  'Mautby',
  'Maypole Green',
  'Melton Constable',
  'Merton',
  'Methwold',
  'Methwold Hythe',
  'Metton',
  'Middleton',
  'Mileham',
  'Moorgate',
  'Morley St Botolph',
  'Morningthorpe',
  'Morston',
  'Morton',
  'Moulton St Mary',
  'Mousehold Heath',
  'Mulbarton',
  'Mundford',
  'Mundesley',
  'Narborough',
  'Narford',
  'Neatishead',
  'Necton',
  'Nethergate',
  'New Buckenham',
  'New Costessey',
  'New Holkham',
  'New Houghton',
  'New Rackheath',
  'Newgate',
  'Newton Greenways',
  'Nogdam End',
  'North Acre',
  'North Barsham',
  'North Burlingham',
  'North Creake',
  'North Elmham',
  'North Pickenham',
  'North Runcton',
  'North Tuddenham',
  'North Walsham',
  'North Wootton',
  'Northall Green',
  'Northwold',
  'Norton Corner',
  'Norton Subcourse',
  'Norwich',
  'Northrepps',
  'Nowhere',
  'Oby',
  'Old Buckenham',
  'Old Catton',
  'Old Costessey',
  'Old Hall Street',
  'Ongar Hill',
  'Ormesby St. Margaret',
  'Ormesby St. Michael',
  'Ostend',
  'Oulton',
  'Oulton Street',
  'Outwell',
  'Overstrand',
  'Overy Staithe',
  'Ovington',
  'Oxborough',
  'Oxwick',
  'Paston',
  'Pattesley',
  'Pedham',
  'Pennygate',
  'Pettywell',
  'Plumstead',
  'Pockthorpe',
  'Pollard Street',
  'Postwick',
  'Potter Heigham',
  'Potthorpe',
  'Pristow Green',
  'Puddledock',
  'Pulham Market',
  'Quarles',
  'Quidenham',
  'Rackheath',
  'Ranworth',
  'Reedham',
  'Reepham',
  'Repps',
  'Reymerston',
  'Ringland',
  'Ringstead',
  'Road Green',
  'Rockland St Mary',
  'Rockland St Peter',
  'Rollesby',
  'Rougham',
  'Roughton',
  'Rowley Corner',
  'Runcton Holme',
  'Runham',
  'Runhall',
  'Rushford',
  'Rushall',
  'Ryston',
  'Saham Toney',
  'Salle',
  'Salhouse',
  'Salthouse',
  'Sandringham',
  'Santon',
  'Saxlingham',
  'Saxlingham Nethergate',
  'Saxthorpe',
  'Scarning',
  'Scratby',
  'Sco Ruston',
  'Scolt Head Island',
  'Scottow',
  'Scoulton',
  'Sculthorpe',
  'Sea Palling',
  'Sedgeford',
  'Setchey',
  'Sharrington',
  'Shelfanger',
  'Shelton Green',
  'Shereford',
  'Sheringham',
  'Shernborne',
  'Shipdham',
  'Short Green',
  'Shouldham',
  'Sidestrand',
  'Silfield',
  'Silvergate',
  'Skeyton',
  'Sloley',
  'Smallburgh',
  'Smallworth',
  'Snetterton',
  'Snettisham',
  'South Burlingham',
  'Somerton',
  'South Acre',
  'South Creake',
  'South Denes',
  'South Green',
  'South Pickenham',
  'South Raynham',
  'South Runcton',
  'South Walsham',
  'South Wootton',
  'Southery',
  'Southrepps',
  'Southwood',
  'Sparham',
  'Sparrow Green',
  'Spixworth',
  'Spooner Row',
  'Sporle',
  'Sprowston',
  'St. Olaves',
  'Stacksford',
  'Stalham',
  'Stalham Green',
  'Stanford',
  'Stanhoe',
  'Stibbard',
  'Stiffkey',
  'Stody',
  'Stoke Holy Cross',
  'Stokesby',
  'Stonebridge',
  'Stow Bedon',
  'Stradsett',
  'Stratton Strawless',
  'Strumpshaw',
  "Stubb's Green",
  'Surlingham',
  'Sustead',
  'Sutton',
  'Swaffham',
  'Swainsthorpe',
  'Swannington',
  'Swanton Abbott',
  'Swanton Morley',
  'Swanton Novers',
  "Sweeting's Green",
  'Tacolneston',
  'Tasburgh',
  'Tatterford',
  'Tattersett',
  'Taverham',
  'Terrington St Clement',
  'Terrington St John',
  'Testerton',
  'Thetford',
  'Tilney All Saints',
  'Tilney High End',
  'Tilney St Lawrence',
  'Tittleshall',
  'Thornage',
  'Thornham',
  'Thorpe Abbotts',
  'Thorpe Hamlet',
  'Thorpe Market',
  'Thorpe Parva',
  'Thorpe St Andrew',
  'Thrigby',
  'Thurgarton',
  'Thurlton',
  'Thurne',
  'Thurning',
  'Thursford',
  'Thurton',
  'Thwaite',
  'Thwaite St Mary',
  'Titchwell',
  'Tivetshall St Margaret',
  'Toft Monks',
  'Toftwood',
  'Topcroft',
  'Topcroft Street',
  'Tottenhill',
  'Tottington',
  'Trimingham',
  'Trunch',
  'Tunstall',
  'Tunstead',
  'Tuttington',
  'Twyford',
  'Tyby',
  'Upgate',
  'Upper Guist',
  'Upper Sheringham',
  'Upper Street',
  'Upton',
  'Upton Green',
  'Wacton',
  'Walcott',
  'Walpole Cross Keys',
  'Walpole Highway',
  'Walpole Marsh',
  'Walpole St Andrew',
  'Walpole St Peter',
  'Walsingham',
  'Walsoken',
  'Warham',
  'Watlington',
  'Wattlefield',
  'Watton',
  'Waxham',
  'Weasenham All Saints',
  'Weasenham St Peter',
  'Weeting',
  'Welborne',
  'Wellingham',
  'Wells-next-the-Sea',
  'Welney',
  'Wendling',
  'Wereham',
  'West Acre',
  'West Barsham',
  'West Beckham',
  'West Bilney',
  'West Bradenham',
  'West Caister',
  'Westfield',
  'West Lexham',
  'West Lynn',
  'West Newton',
  'West Raynham',
  'West Rudham',
  'West Runton',
  'West Somerton',
  'West Walton',
  'West Winch',
  'Westgate Street',
  'Weston Green',
  'Weston Longville',
  'Westwick',
  'Weybourne',
  'Wheatacre',
  'Whinburgh',
  'Whissonsett',
  'White Horse Common',
  'Wicklewood',
  'Wiggenhall St Germans',
  'Wiggenhall St Mary Magdalen',
  'Wighton',
  'Wilby',
  'Wimbotsham',
  'Winfarthing',
  'Winterton-on-Sea',
  'Withergate',
  'Witton Bridge',
  'Witton (near Postwick)',
  'Witton (North Norfolk)',
  'Wiveton',
  'Wolferton',
  'Wood Dalling',
  'Wood Norton',
  'Woodbastwick',
  'Woodrising',
  'Woodton',
  'Wormegay',
  'Worstead',
  'Wortwell',
  'Wramplingham',
  'Wreningham',
  'Wretham',
  'Wretton',
  'Wroxham',
  'Wymondham',
  'Yaxham',
  'Yelverton'
];

export default locations;
