import { setContext } from 'apollo-link-context';

import { defaultCache } from './cache.js';

const isUnauthorised = errors => {
  return errors.some(error => error.extensions.code === 'UNAUTHENTICATED');
};

/**
 * This will be triggered when the user hits a restricted
 * graphql query / mutation. This will only fire if we're out
 * of sync with the API, or a someone is being malicious.
 * TODO: We need to reliably log them out and show a message.
 */
const handleUnauthorised = ({ cache }) => cache;

const handleLogout = ({ client, message }) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');

  client.cache.writeData({
    data: {
      ...defaultCache(),
      alert: {
        __typename: 'Alert',
        status: 'positive',
        message
      }
    }
  });
};

const setToken = (_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
};

const authLink = setContext(setToken);

export default authLink;
export { setToken, handleLogout, handleUnauthorised, isUnauthorised };
