"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Promo", {
  enumerable: true,
  get: function get() {
    return _Promo["default"];
  }
});
Object.defineProperty(exports, "Promo1", {
  enumerable: true,
  get: function get() {
    return _Promo2["default"];
  }
});
Object.defineProperty(exports, "Promo2", {
  enumerable: true,
  get: function get() {
    return _Promo3["default"];
  }
});

var _Promo = _interopRequireDefault(require("./Promo"));

var _Promo2 = _interopRequireDefault(require("./Promo1"));

var _Promo3 = _interopRequireDefault(require("./Promo2"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }