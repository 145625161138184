import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import AdminDelete from './AdminDelete';

const DELETE_COMMENTS = gql`
  mutation deleteComments($ids: [Int!]!) {
    deleteComments(ids: $ids)
  }
`;

const AdminDeleteComment = ({ ids, ...rest }) => {
  const [deleteComments] = useMutation(DELETE_COMMENTS, {
    refetchQueries: ['image']
  });

  return (
    <AdminDelete ids={ids} query={deleteComments} type="comment" {...rest} />
  );
};

export default AdminDeleteComment;
