import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link as RouterLink } from 'react-router-dom';
import { Flex } from '@theme-ui/components';

import { Link, Heading3, Collections } from '@tso/tso-components';

import { GET_COLLECTIONS } from './AllCollections';
import CollectionActions from './CollectionActions';

const OtherCollections = () => {
  const { data, loading, error } = useQuery(GET_COLLECTIONS, {
    variables: { page: 0 },
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  return (
    <Collections
      header={
        <Flex sx={{ alignItems: 'center' }}>
          <Heading3 mb={0} mr={2}>
            Continue the exploration
          </Heading3>
          <Link
            as={RouterLink}
            to="/collections"
            variant="link1.medium"
            color="primary">
            All collections
          </Link>
        </Flex>
      }
      collections={data.collections.data
        .slice(0, 6)
        .map(({ id, name, description, user, header }) => ({
          Actions: CollectionActions,
          id,
          name,
          description,
          url: `collections/${id}`,
          ...(header && {
            image: { url: header.src },
            orientation: header.revision.orientation
          }),
          user: {
            id: user.id,
            name: user.name,
            url: `users/${user.id}`,
            image: { url: user.profile_src }
          },
          is_private: false
        }))}
    />
  );
};

export default OtherCollections;
