import React from 'react';
import { useLocation } from 'react-router-dom';

import { Header as TsoHeader } from '@tso/tso-components';
import { SearchInput } from './Search/index';
import Notifications from './Notifications';
import { useAuth } from '../hooks/index';
import routes from '../Routes/admin';

const Header = () => {
  const location = useLocation();

  const { isLoggedIn, user, isAdmin } = useAuth();

  const onHomePage = location.pathname === '/';

  return (
    <TsoHeader
      isLoggedIn={isLoggedIn}
      user={{
        name: user.name,
        url: `/users/${user.id}`,
        image: { url: user.profile_src }
      }}
      Notifications={Notifications}
      adminRoutes={routes}
      isAdmin={isAdmin}>
      {!onHomePage && <SearchInput />}
    </TsoHeader>
  );
};

export default Header;
