import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQuery } from '@apollo/react-hooks';
import { Pagination, Checkbox, Label, Button } from '@tso/tso-components';

import { SortToggle, Tableheading as Heading, TableCell } from '../index';
import { AdminDeleteRequest, AdminPage } from '../../Components/index';
import { requestPreview } from '../../fragments/index';
import { useMultiSelect, usePage } from '../../hooks/index';

const GET_ALL_REQUESTS = gql`
  query allRequests($page: Int, $sort: Sort) {
    allRequests(page: $page, sort: $sort) {
      data {
        ...RequestPreview
      }
      total
      page {
        currentPage
        lastPage
      }
    }
  }
  ${requestPreview}
`;

const Requests = () => {
  const [page, setPage] = usePage();
  const [sort, setSort] = useState();

  const { loading, error, data } = useQuery(GET_ALL_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: { page, latitude: null, longitude: null, sort }
  });

  const {
    selected,
    handleSelect,
    handleAllSelected,
    allSelected
  } = useMultiSelect(data?.allRequests?.data);

  const handlePagination = page => setPage(parseInt(page) - 1);

  if (loading || error) {
    return null;
  }

  const { currentPage, lastPage } = data.allRequests.page;

  return (
    <AdminPage title="Requests">
      <table style={{ width: '100%', minWidth: 700 }}>
        <thead>
          <tr>
            <Heading>
              <div style={{ display: 'inline-flex' }}>
                <div>
                  <Label variant="checkbox">
                    <Checkbox
                      name="select-all-requests"
                      checked={allSelected}
                      onChange={handleAllSelected}
                    />
                  </Label>
                </div>
                {selected.length > 0 && <AdminDeleteRequest ids={selected} />}
              </div>
            </Heading>
            <Heading>Address</Heading>
            <Heading>Description</Heading>
            <Heading>
              <span>Created At</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'OLDEST_CREATED',
                  down: 'NEWEST_CREATED'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>
              <span>User</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'USER_ASC',
                  down: 'USER_DESC'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>Actions</Heading>
          </tr>
        </thead>
        <tbody>
          {data.allRequests.data.map(
            (
              { id, street_address_1, user, description, created_at },
              index
            ) => (
              <tr key={index}>
                <TableCell>
                  <Label variant="checkbox">
                    <Checkbox
                      checked={selected.includes(id)}
                      onChange={() => handleSelect(id)}
                      aria-label="selectRequest"
                    />
                  </Label>
                </TableCell>
                <TableCell>{street_address_1}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{dayjs(created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  <a href={`/users/${user.id}`}>{user.name}</a>
                </TableCell>
                <TableCell>
                  <Button
                    size={'small'}
                    appearance={'link'}
                    as={Link}
                    to={`/requests/${id}`}>
                    View
                  </Button>
                  <AdminDeleteRequest ids={[id]} />
                </TableCell>
              </tr>
            )
          )}
        </tbody>
        <Pagination
          paginate={{
            currentPage: currentPage + 1,
            totalPages: lastPage + 1
          }}
          handlePageChange={handlePagination}
        />
      </table>
    </AdminPage>
  );
};

export default Requests;
