import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';

import { Request } from '@tso/tso-components';

import { title } from '../../Pages/App/Request';
import { requestPreview } from '../../fragments/index';
import { MapPreview, InfiniteScroll } from '../index';
import { paginate } from '../../actions/index';

const GET_USER_REQUESTS = gql`
  query userRequests($id: Int!) {
    userRequests(id: $id) {
      data {
        ...RequestPreview
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${requestPreview}
`;

const UserRequests = ({ id }) => {
  const { data, loading, error, fetchMore } = useQuery(GET_USER_REQUESTS, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.userRequests.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id, page: currentPage + 1 },
      updateQuery: paginate('userRequests')
    });
  };

  if (loading || error) {
    return null;
  }

  return (
    <InfiniteScroll onBottom={handleBottom}>
      {data.userRequests.data.map(
        (
          {
            id,
            year_start,
            year_end,
            date,
            user,
            latitude,
            longitude,
            description,
            image_count
          },
          index
        ) => (
          <Request
            key={id}
            id={id}
            date={
              date
                ? dayjs(date).format('D MMM YYYY')
                : `${year_start} - ${year_end}`
            }
            user={user}
            latitude={latitude}
            longitude={longitude}
            name={title(data.userRequests.data[index])}
            description={description}
            count={image_count}
            mb={3}>
            <MapPreview
              latitude={latitude}
              longitude={longitude}
              sx={{
                flex: 'none',
                width: ['100%', 200],
                height: [190, 130]
              }}
            />
          </Request>
        )
      )}
    </InfiniteScroll>
  );
};

UserRequests.propTypes = {
  id: PropTypes.number.isRequired
};

export default UserRequests;
export { GET_USER_REQUESTS };
