"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
 */
var calculatePagination = function calculatePagination(current, total) {
  var delta = 2;
  var range = [1];
  var rangeWithDots = [];
  var l;

  if (total <= 1) {
    return range;
  }

  for (var i = current - delta; i <= current + delta; i++) {
    if (i < total && i > 1) {
      range.push(i);
    }
  }

  range.push(total);

  for (var _i = 0, _range = range; _i < _range.length; _i++) {
    var _i2 = _range[_i];

    if (l) {
      if (_i2 - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (_i2 - l !== 1) {
        rangeWithDots.push('...');
      }
    }

    rangeWithDots.push(_i2);
    l = _i2;
  }

  return rangeWithDots;
};

var _default = calculatePagination;
exports["default"] = _default;