import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

import wsLink from './socket';
import httpLink from './http';

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export default link;
