import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Box, Flex } from '@theme-ui/components';
import gql from 'graphql-tag';

import {
  Button,
  GoogleMapProvider,
  Heading4,
  Image,
  ImageGrid,
  TabLink
} from '@tso/tso-components';

import { useScrollPosition, useSearch } from '../../hooks/index';
import { imageResult } from '../../fragments/index';
import { paginate } from '../../actions/index';
import {
  ResultMap,
  InfiniteScroll,
  NoResults,
  ImageActions,
  ResultsCategories,
  Slider,
  Meta
} from '../index';
import SortBy, { options } from '../SortBy';
import SortByDistance, { options as distanceOptions } from '../SortByDistance';
import { description } from '../../validation/index';

const GET_IMAGES = gql`
  query images(
    $page: Int
    $sort: Sort
    $latitude: Float
    $longitude: Float
    $year_start: String
    $year_end: String
    $date: Date
    $category_id: Int
    $distance: Distance
  ) {
    images(
      page: $page
      sort: $sort
      lat: $latitude
      long: $longitude
      year_start: $year_start
      year_end: $year_end
      date: $date
      category_id: $category_id
      distance: $distance
    ) {
      data {
        ...ImageResult
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${imageResult}
`;

const Results = () => {
  const [gridLoaded, setGridLoaded] = useState(false);
  const { params, input, updateSearch } = useSearch();
  const scroll = useScrollPosition();
  const { loading, error, data, fetchMore } = useQuery(GET_IMAGES, {
    variables: { ...params, page: 0 },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (!loading && gridLoaded && scroll) {
      window.scrollTo(0, scroll);
    }
  }, [loading, scroll, gridLoaded]);

  const [filters, setFilters] = useState(false);

  const toggleFilters = () => {
    setFilters(prevState => !prevState);
  };

  const handleCategoryChange = value => {
    updateSearch({ variables: { category_id: value } });
  };

  const handleYearChange = ([year_start, year_end]) => {
    updateSearch({
      variables: {
        year_start: year_start.toString(),
        year_end: year_end.toString()
      }
    });
  };

  const handleViewChange = view => {
    updateSearch({ variables: { view } });
  };

  const handleSort = ({ value }) => {
    updateSearch({ variables: { sort: value } });
  };

  const handleDistanceSort = ({ value }) => {
    updateSearch({ variables: { distance: value } });
  };

  const handlePagination = () => {
    const { currentPage, hasNextPage } = data.images.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { ...params, page: currentPage + 1 },
      updateQuery: paginate('images')
    });
  };

  if (loading) {
    return null;
  }

  if (error || (data.images && data.images.total === 0)) {
    return <NoResults input={input} />;
  }

  return (
    <>
      <Meta title="Results" />
      <Flex
        sx={{
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          bg: 'background',
          height: [45, null, 65],
          position: 'sticky',
          top: [89, 62],
          zIndex: 1,
          px: [2, 3],
          borderBottom: 1,
          borderColor: 'gray.4'
        }}>
        <Box sx={{ display: [null, null, 'none'] }}>
          <TabLink size="small" onClick={toggleFilters}>
            Filters
          </TabLink>
        </Box>
        <Box
          sx={{
            display: ['none', null, 'flex'],
            mx: [null, null, -2],
            py: 2
          }}>
          <Box
            sx={{
              mx: [null, null, 2],
              width: 460
            }}>
            <Slider
              values={[params.year_start, params.year_end]}
              handleValueChange={handleYearChange}
            />
          </Box>
          <Box sx={{ mx: [null, null, 2] }}>
            <ResultsCategories
              value={params.category_id}
              handleChange={handleCategoryChange}
              right
            />
          </Box>

          <Box sx={{ mx: [null, null, 2] }}>
            <SortBy
              value={options.find(({ value }) => value === params.sort)}
              handleChange={handleSort}
              right
            />
          </Box>

          <Box sx={{ mx: [null, null, 2] }}>
            <SortByDistance
              value={distanceOptions.find(
                ({ value }) => value === params.distance
              )}
              handleChange={handleDistanceSort}
            />
          </Box>
        </Box>

        <Box ml="auto">
          <TabLink
            size="small"
            onClick={() => handleViewChange('GRID')}
            active={params.view === 'GRID'}>
            <Box sx={{ display: [null, 'none'] }} as="span">
              Grid
            </Box>
            <Box sx={{ display: ['none', 'block'] }} as="span">
              Grid view
            </Box>
          </TabLink>
          <TabLink
            size="small"
            onClick={() => handleViewChange('MAP')}
            active={params.view === 'MAP'}>
            <Box sx={{ display: [null, 'none'] }} as="span">
              Map
            </Box>
            <Box sx={{ display: ['none', 'block'] }} as="span">
              Map view
            </Box>
          </TabLink>
        </Box>
      </Flex>

      {params.view === 'GRID' && (
        <InfiniteScroll onBottom={handlePagination}>
          <ImageGrid
            sx={{ pt: [2, 3] }}
            loadedCallback={() => setGridLoaded(true)}>
            {data.images.data.map(
              ({ id, is_unidentified, src, revision, ...rest }) => (
                <Image
                  Actions={ImageActions}
                  id={id}
                  unidentified={is_unidentified}
                  name={revision.name}
                  description={description(revision)}
                  image={{ url: src }}
                  key={id}
                  isPublic={true}
                  orientation={revision.orientation}
                  {...rest}
                />
              )
            )}
          </ImageGrid>
        </InfiniteScroll>
      )}

      {params.view === 'MAP' && (
        <GoogleMapProvider
          initalCenter={{
            lat: data.images.data[0].revision.latitude,
            lng: data.images.data[0].revision.longitude
          }}>
          <ResultMap
            results={data.images.data}
            handleBottom={handlePagination}
          />
        </GoogleMapProvider>
      )}

      {filters && (
        <Box
          sx={{
            display: [null, null, 'none'],
            bg: 'background',
            position: 'fixed',
            top: [89, 62],
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
            size: '100%'
          }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              p: 2,
              borderBottom: 1,
              borderColor: 'gray.4'
            }}>
            <Button onClick={toggleFilters} size="small">
              Apply
            </Button>

            <Button onClick={toggleFilters} appearance="link" size="small">
              Cancel
            </Button>
          </Flex>
          <Box sx={{ p: 2 }}>
            <Box mb={4}>
              <Heading4>Date</Heading4>
              <Slider
                values={[params.year_start, params.year_end]}
                handleValueChange={handleYearChange}
              />
            </Box>

            <Box mb={4}>
              <Heading4>Categories</Heading4>
              <ResultsCategories
                value={params.category_id}
                handleChange={handleCategoryChange}
                block
              />
            </Box>

            <Box mb={4}>
              <Heading4>Sort by</Heading4>
              <SortBy
                value={options.find(({ value }) => value === params.sort)}
                handleChange={handleSort}
                block
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Results;
export { GET_IMAGES };
