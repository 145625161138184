import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import AdminDelete from './AdminDelete';

const DELETE_REQUESTS = gql`
  mutation deleteRequests($ids: [Int!]!) {
    deleteRequests(ids: $ids)
  }
`;

const AdminDeleteRequest = ({ ids, ...rest }) => {
  const [deleteRequests] = useMutation(DELETE_REQUESTS, {
    refetchQueries: ['allRequests', 'userRequests', 'requests']
  });

  return (
    <AdminDelete ids={ids} query={deleteRequests} type="request" {...rest} />
  );
};

export default AdminDeleteRequest;
