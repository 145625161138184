import React from 'react';
import PropTypes from 'prop-types';

import { Profile as ProfileComponent } from '../../Components/index';

const Profile = ({ match }) => <ProfileComponent id={match.params.id} />;

Profile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default Profile;
