const length = (string = '', { min = 2, max = 10, type = 'Input' }) => {
  string = string.trim();

  let error = null;

  if (string.length < min) {
    error = `${type} is less than ${min} characters`;
  }

  if (string.length > max) {
    error = `${type} is greater than ${max} characters`;
  }

  return { error, valid: Boolean(!error) };
};

module.exports = { length };
