import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex } from '@theme-ui/components';
import gql from 'graphql-tag';

import {
  Text2Medium,
  ActionButton,
  Button,
  Checkbox,
  Feedback,
  Heading3,
  Input,
  Label,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea
} from '@tso/tso-components';

import { useAlert } from '../../hooks/index';
import DeleteCollection from './DeleteCollection';
import { userCollection } from '../../fragments/index';
import { length } from '../../validation/index';

const EDIT_COLLECTION = gql`
  mutation editCollection(
    $id: Int!
    $name: String!
    $description: String!
    $isPrivate: Boolean!
  ) {
    editCollection(
      id: $id
      name: $name
      description: $description
      isPrivate: $isPrivate
    ) {
      ...UserCollection
    }
  }
  ${userCollection}
`;

const EditCollection = ({
  id,
  name,
  description,
  is_private,
  buttonType = 'image'
}) => {
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [collectionData, setCollectionData] = useState({
    id,
    name,
    description,
    isPrivate: is_private
  });

  const { showSuccess } = useAlert();
  const [editCollection] = useMutation(EDIT_COLLECTION, {
    refetchQueries: ['collections', 'privateCollections', 'userCollections']
  });

  const toggleModal = () => setModal(modal => !modal);

  const handleNameChange = event =>
    setCollectionData({ ...collectionData, name: event.target.value });

  const handlePrivacyChange = () =>
    setCollectionData({
      ...collectionData,
      isPrivate: !collectionData.isPrivate
    });

  const handleDescriptionChange = event =>
    setCollectionData({ ...collectionData, description: event.target.value });

  const handleEditCollection = async () => {
    const { valid, error } = length(collectionData.name, {
      min: 2,
      max: 100,
      type: 'Name'
    });

    const { valid: validDescription, error: errorDescription } = length(
      collectionData.description,
      {
        min: 0,
        max: 255,
        type: 'Description'
      }
    );

    if (!valid) {
      return setErrorMessage(error);
    }

    if (!validDescription) {
      return setErrorMessage(errorDescription);
    }

    await editCollection({ variables: collectionData });

    toggleModal();
    showSuccess('Collection updated successfully');
  };

  const valid = Boolean(collectionData.name);

  return (
    <>
      {buttonType === 'image' && (
        <ActionButton
          icon="edit"
          label="editCollection"
          onClick={toggleModal}
          aria-label="Edit Collection">
          Edit collection
        </ActionButton>
      )}

      {buttonType === 'list' && (
        <ListItem label="Edit" onClick={toggleModal} aria-label="Edit">
          Edit
        </ListItem>
      )}

      <>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Edit collection</ModalHeader>
          <ModalBody>
            <Heading3>General information</Heading3>
            <Input
              onChange={handleNameChange}
              defaultValue={name}
              placeholder={'Name *'}
              aria-label={'nameInput'}
              mb={3}
            />

            <Textarea
              onChange={handleDescriptionChange}
              defaultValue={description}
              placeholder={'Description'}
              aria-label={'descriptionInput'}
              mb={3}
            />

            {errorMessage && (
              <Feedback status="warning" mt={2}>
                {errorMessage}
              </Feedback>
            )}
            <Heading3>Cover picture</Heading3>
            <Flex
              as={RouterLink}
              to={`/collections/${id}/header`}
              aria-label="Select a photo"
              sx={{
                appearance: 'none',
                height: 100,
                width: '100%',
                p: 3,
                color: 'text',
                bg: 'background',
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: 'gray.3',
                alignItems: 'center',
                cursor: 'pointer',
                outline: 0,
                '&:focus, &:hover, &:active': {
                  bg: 'gray.4'
                }
              }}>
              <Text2Medium>+ Select a photo</Text2Medium>
            </Flex>
            <Label variant="checkbox" my={3}>
              <Checkbox
                defaultChecked={collectionData.isPrivate}
                onChange={handlePrivacyChange}
                aria-label={'isPrivateToggle'}
              />
              <div>Keep this collection private</div>
            </Label>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleEditCollection} isDisabled={!valid}>
              Save
            </Button>
            <DeleteCollection id={id} />
          </ModalFooter>
        </Modal>
      </>
    </>
  );
};

EditCollection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  is_private: PropTypes.bool.isRequired,
  buttonType: PropTypes.oneOf(['image', 'list'])
};

export default EditCollection;
export { EDIT_COLLECTION };
