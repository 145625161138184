import React from 'react';
import PropTypes from 'prop-types';

import { Text, Link } from '@tso/tso-components';

const getAddress = ({
  building_number,
  street_address_1,
  street_address_2,
  city,
  postcode,
  region,
  country
}) => {
  const components = {
    street_address_1: building_number
      ? `${building_number} ${street_address_1}`
      : street_address_1,
    street_address_2,
    city,
    postcode,
    region,
    country
  };

  return Object.values(components).filter(Boolean);
};

const Address = ({ onClick = () => {}, ...revision }) => {
  const address = getAddress(revision);

  if (address.length > 0) {
    const [first, ...rest] = address;
    return (
      <Text variant="text0.light">
        <strong>{first}</strong>
        {rest.length > 0 && <>, {rest.join(', ')}</>}
      </Text>
    );
  }

  return (
    <Link as="button" onClick={onClick} variant="link0.medium" color="primary">
      Where was this photo taken?
    </Link>
  );
};

Address.propTypes = {
  street_address_1: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.string,
  onClick: PropTypes.func
};

export default Address;
export { Address, getAddress };
