import dayjs from 'dayjs';

export const isValidDate = date => {
  // if the day value is less than 10
  // we need to append a 0 infront of it
  // e.g. 1 => 01
  if (date[2] < 10 && date[2].length < 2) {
    date[2] = ('0' + date[2]).slice(-2);
  }
  return dayjs(`${date[0]}/${date[1]}/${date[2]}`, 'YYYY/MM/DD').isValid();
};
