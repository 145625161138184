import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom';

import {
  Button,
  Feedback,
  Heading1,
  Heading4,
  Text,
  Container
} from '@tso/tso-components';

import { CategoryPills, Meta } from '../../Components/index';
import { UserInterests } from '../../fragments/index';
import { useAlert } from '../../hooks/index';

const GET_INTERESTS = gql`
  {
    getInterests {
      ...UserInterests
    }
  }
  ${UserInterests}
`;

const UPDATE_INTERESTS = gql`
  mutation storeInterests($category_ids: [Int!]) {
    storeInterests(category_ids: $category_ids) {
      ...UserInterests
    }
  }
  ${UserInterests}
`;

const handleInterests = (cache, { data: { storeInterests } }) => {
  cache.writeQuery({
    query: GET_INTERESTS,
    data: {
      getInterests: {
        categories: storeInterests.categories,
        __typename: 'Interests'
      }
    }
  });
};

const Interests = () => {
  const [userInterests, setUserInterests] = useState({
    categories: [],
    waitForInterests: true
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { showSuccess } = useAlert();

  const { loading, error } = useQuery(GET_INTERESTS, {
    onCompleted(data) {
      setUserInterests({
        ...userInterests,
        categories: data.getInterests.categories.map(a => a.id),
        waitForInterests: false
      });
    }
  });

  const [updateInterests] = useMutation(UPDATE_INTERESTS, {
    update: handleInterests
  });

  if (loading || userInterests.waitForInterests || error) {
    return null;
  }

  const handleSubmit = async event => {
    event.preventDefault();

    if (userInterests.categories.length === 0) {
      return setErrorMessage('Please select at least one category');
    }

    await updateInterests({
      variables: {
        category_ids: userInterests.categories
      }
    });

    showSuccess('Successfully updated your interests');
  };

  const handleCategorySelect = id => {
    setUserInterests(({ categories, ...rest }) => {
      return {
        ...rest,
        categories: categories.includes(id)
          ? categories.filter(item => item !== id)
          : categories.concat(id)
      };
    });
  };

  return (
    <Box variant="layout.baz">
      <Meta title="Interests" />
      <Container>
        <Box variant="layout.form">
          <Heading1>Customise your interests</Heading1>
          <Text mb={4}>
            Add in a few more details so we can personalise your feed with
            things that matter to you.
          </Text>

          <Box mb={4}>
            <Heading4>What are you interested in?</Heading4>
            <CategoryPills
              categories={userInterests.categories}
              handleCategorySelect={handleCategorySelect}
            />
            {errorMessage && (
              <Feedback status="warning">{errorMessage}</Feedback>
            )}
          </Box>

          <Button type="submit" onClick={handleSubmit}>
            Save preferences
          </Button>
          <Button
            as={RouterLink}
            to="/profile"
            appearance={'link'}
            type="button">
            Cancel
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Interests;
export { GET_INTERESTS, UPDATE_INTERESTS };
