import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Box } from '@theme-ui/components';

import { Avatar, Link, Note, Link0Medium, Feedback } from '@tso/tso-components';

import { useAuth, useAlert } from '../../hooks/index';
import { setUser } from '../../actions/index';
import { isValidType, isValidProfileSize } from '../../validation/index';

const UPLOAD_PROFILE_PICTURE = gql`
  mutation uploadProfilePicture($image: Upload!) {
    uploadProfilePicture(image: $image) {
      profile_name
      profile_src
    }
  }
`;

const UploadProfilePicture = () => {
  const [error, setError] = useState();

  const { user } = useAuth();
  const { showSuccess } = useAlert();

  const [upload] = useMutation(UPLOAD_PROFILE_PICTURE, { update: setUser });

  const handleImageChange = async event => {
    const [image] = event.target.files;

    if (!isValidType(image)) {
      return setError(
        'That file type is not supported. Please upload a photo in JPEG or PNG formats.'
      );
    }

    if (!isValidProfileSize(image)) {
      return setError(
        'That image is too big, please upload an image under 500kb.'
      );
    }

    await upload({ variables: { image } });
    setError(null);
    showSuccess('Successfully updated and saved new image');
  };

  return (
    <>
      <Flex
        sx={{
          alignItems: 'center',
          mb: 3
        }}>
        <Avatar
          as={'div'}
          name={user.name}
          image={{ url: user.profile_src }}
          size={100}
        />
        <Box sx={{ flex: '1 1 auto', ml: 3 }}>
          <Link
            as="label"
            htmlFor="upload"
            color="primary"
            variant="link1.medium"
            sx={{ cursor: 'pointer' }}>
            + Upload profile picture
          </Link>
          <Note>
            Please upload a photo in JPEG or PNG formats and aim to keep the
            size below 500KB.{' '}
            <Link0Medium as={RouterLink} to="/help">
              More information
            </Link0Medium>
          </Note>
          <Box
            as="input"
            sx={{ display: 'none' }}
            onChange={handleImageChange}
            type="file"
            id="upload"
          />
        </Box>
      </Flex>

      {error && (
        <Feedback status="warning" mt={2}>
          {error}
        </Feedback>
      )}
    </>
  );
};

export default UploadProfilePicture;
export { UPLOAD_PROFILE_PICTURE };
