import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { userCollection } from '../../fragments/index';
import { useAlert } from '../../hooks/index';
import AuthListItem from '../AuthListItem';

const REMOVE_IMAGES_FROM_COLLECTION = gql`
  mutation removePhotosFromCollection(
    $collection_id: Int!
    $image_ids: [Int!]!
  ) {
    removePhotosFromCollection(
      collection_id: $collection_id
      image_ids: $image_ids
    ) {
      ...UserCollection
    }
  }
  ${userCollection}
`;

const RemoveImageFromCollection = ({ id, collection }) => {
  const { showSuccess } = useAlert();

  const [removePhoto] = useMutation(REMOVE_IMAGES_FROM_COLLECTION, {
    refetchQueries: ['collection']
  });

  const handleRemove = async () => {
    await removePhoto({
      variables: { collection_id: collection.id, image_ids: [id] }
    });
    showSuccess('Successfully remove photo from collection');
  };

  return (
    <AuthListItem
      label="Remove from collection"
      onClick={handleRemove}
      aria-label="Remove from collection">
      Remove from collection
    </AuthListItem>
  );
};

RemoveImageFromCollection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  collection: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default RemoveImageFromCollection;
