import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Pagination, Button } from '@tso/tso-components';

import { loggedInUser, imageDetail } from '../../fragments/index';
import { AdminDeleteComment, AdminPage } from '../../Components/index';
import { usePage } from '../../hooks/index';

const GET_IMAGE_COMMENTS = gql`
  query image($id: Int!, $page: Int) {
    image(id: $id) {
      ...ImageDetail
      comments(page: $page) {
        data {
          user {
            ...LoggedInUser
          }
          id
          comment
          created_at
        }
        total
        page {
          currentPage
          lastPage
          perPage
          hasNextPage
        }
      }
    }
  }
  ${loggedInUser}
  ${imageDetail}
`;

const Comments = ({ match }) => {
  const [page, setPage] = usePage();

  const { loading, error, data } = useQuery(GET_IMAGE_COMMENTS, {
    variables: { id: parseInt(match.params.id), page },
    fetchPolicy: 'network-only'
  });

  const handlePagination = page => setPage(parseInt(page) - 1);

  if (loading || error) {
    return null;
  }

  const { currentPage, lastPage } = data.image.comments.page;
  const { data: comments } = data.image.comments;

  return (
    <AdminPage title="Comments">
      <table style={{ width: '100%', minWidth: 700 }}>
        <thead>
          <tr>
            <th>Comment</th>
            <th>Created By</th>
            <th>Posted</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {comments.map(({ id, comment, user, created_at }, index) => (
            <tr key={index}>
              <td>{comment}</td>
              <td>
                <Link to={`/users/${user.id}`}>{user.name}</Link>
              </td>
              <td>{dayjs(created_at).fromNow()}</td>
              <td>
                <Button
                  size={'small'}
                  appearance={'link'}
                  as={Link}
                  to={`/images/${match.params.id}`}>
                  View
                </Button>
                <AdminDeleteComment ids={[id]} />
              </td>
            </tr>
          ))}
        </tbody>
        <Pagination
          paginate={{
            currentPage: currentPage + 1,
            totalPages: lastPage + 1
          }}
          handlePageChange={handlePagination}
        />
      </table>
    </AdminPage>
  );
};

export default Comments;
