import { getAddress } from '../Components/Address';

/**
 * Grab various part of the image, and try and build a nice description.
 * Can be any of the following.
 * - "address - year range"
 * - "address" - year
 * - "address"
 * - "year"
 * - "year range"
 *
 * @return String
 */
const description = ({ year, year_start, year_end, ...locationInfo }) => {
  let from = '';

  // Build the optional date or year range.
  if (year_end && year_end) {
    from = `${year_start} to ${year_end}`;
  } else if (year) {
    from = year;
  }

  // Get the first part of the address.
  const [address] = getAddress(locationInfo);

  if (address && from) {
    return `${address} - ${from}`;
  }

  if (address) {
    return address;
  }

  if (from) {
    return from;
  }

  return '';
};

export { description };
