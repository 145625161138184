import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import {
  Button,
  Container,
  Feedback,
  Field,
  Heading1,
  Heading4,
  Link2Medium,
  Text,
  Text1Light
} from '@tso/tso-components';

import { Meta } from '../../Components';
import { isNotValidEmail } from '../../validation/index';
import { useAlert } from '../../hooks/index';

const INVITE_USER = gql`
  mutation inviteUser($emails: [Email!]!) {
    inviteUser(emails: $emails)
  }
`;

const hasDuplicates = entries => new Set(entries).size !== entries.length;

const Invite = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [emails, setEmails] = useState(['']);

  const { showSuccess } = useAlert();
  const [inviteUser] = useMutation(INVITE_USER);

  const handleSubmit = async () => {
    setErrorMessage('');

    const invalidEmail = emails.some(isNotValidEmail);

    if (invalidEmail || emails.length === 0 || hasDuplicates(emails)) {
      return setErrorMessage(
        'One or more emails are not valid, please check and try again'
      );
    }

    await inviteUser({ variables: { emails } });
    showSuccess('User(s) invited successfully');
    setEmails(['']);
  };

  const handleEmailChange = index => event => {
    const newValue = event.target.value;

    // Swap out email with new value.
    setEmails(emails =>
      emails.map((email, key) => {
        if (key === index) {
          return newValue;
        }
        return email;
      })
    );
  };

  return (
    <Box variant="layout.baz">
      <Meta title="Invite Friends" />
      <Container>
        <Box variant="layout.form">
          <Heading1>Invite friends</Heading1>
          <Text mb={3}>
            Help to grow The Story Of community and make sure people see your
            photos. Invite people by sending email invites directly, or by
            sending them an invite link.
          </Text>

          <Box mb={5}>
            <Heading4 as="h2">By email</Heading4>

            <Text1Light mb={3}>
              Enter the email addresses of the people you would like to invite
            </Text1Light>

            {emails.map((_, index) => (
              <Field
                name={`email-${index}`}
                label="Email"
                hideLabel
                type="email"
                placeholder="Email"
                key={index}
                value={emails[index]}
                onChange={handleEmailChange(index)}
                mb={3}
              />
            ))}

            <Box mb={3}>
              <Link2Medium
                role="button"
                color="primary"
                onClick={() => setEmails(emails => emails.concat(['']))}>
                + add
              </Link2Medium>
            </Box>

            {errorMessage && <Feedback mt={2}>{errorMessage}</Feedback>}

            <Button type="submit" onClick={handleSubmit} block>
              Send invites
            </Button>
          </Box>

          {/* <div style={{ marginTop: '3rem' }}> */}
          {/*   <Heading3 style={{ marginTop: '2rem' }}>By link</Heading3> */}
          {/*   <Text> */}
          {/*     Just copy the link below and send it to the people you would like to */}
          {/*     invite. */}
          {/*   </Text> */}
          {/*   <div style={{ display: 'inline-flex', width: '100%' }}> */}
          {/*     <Input */}
          {/*       type="text" */}
          {/*       isDisabled={true} */}
          {/*       value={''} */}
          {/*       name="userLink" */}
          {/*       aria-label="userLink" */}
          {/*     /> */}
          {/*     <Link */}
          {/*       style={{ */}
          {/*         width: '25%', */}
          {/*         paddingTop: '15px' */}
          {/*       }} */}
          {/*       color="primary" */}
          {/*       variant="link1.medium"> */}
          {/*       Copy link */}
          {/*     </Link> */}
          {/*   </div> */}
          {/* </div> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Invite;
export { hasDuplicates };
