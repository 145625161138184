"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _color = require("@theme-ui/color");

var _alerts = _interopRequireDefault(require("./alerts"));

var _badges = _interopRequireDefault(require("./badges"));

var _buttons = _interopRequireDefault(require("./buttons"));

var _comments = _interopRequireDefault(require("./comments"));

var _containers = _interopRequireDefault(require("./containers"));

var _dropdowns = _interopRequireDefault(require("./dropdowns"));

var _forms = _interopRequireDefault(require("./forms"));

var _images = _interopRequireDefault(require("./images"));

var _layout = _interopRequireDefault(require("./layout"));

var _links = _interopRequireDefault(require("./links"));

var _messages = _interopRequireDefault(require("./messages"));

var _modals = _interopRequireDefault(require("./modals"));

var _pins = _interopRequireDefault(require("./pins"));

var _text = _interopRequireDefault(require("./text"));

var _tab = _interopRequireDefault(require("./tab"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  alerts: _alerts["default"],
  badges: _badges["default"],
  buttons: _buttons["default"],
  comments: _comments["default"],
  containers: _containers["default"],
  dropdowns: _dropdowns["default"],
  forms: _forms["default"],
  images: _images["default"],
  layout: _layout["default"],
  links: _links["default"],
  messages: _messages["default"],
  modals: _modals["default"],
  pins: _pins["default"],
  text: _text["default"],
  tab: _tab["default"],
  iconButton: {
    primary: {
      bg: 'primary',
      color: 'background'
    },
    secondary: {
      bg: 'secondary',
      color: 'background',
      '&:focus, &:hover, &:active': {
        bg: 'gray.1'
      }
    },
    tertiary: {
      bg: 'transparent',
      '&:focus, &:hover, &:active': {
        bg: 'gray.4'
      }
    },
    tertiaryActive: {
      bg: 'gray.4',
      '&:focus, &:hover, &:active': {
        bg: 'gray.4'
      }
    },
    muted: {
      color: 'gray.3',
      '&:focus, &:hover, &:active': {
        color: 'text'
      }
    },
    background: {
      bg: 'transparent',
      color: 'background',
      '&:focus, &:hover, &:active': {
        color: 'text'
      }
    },
    backgroundAlt: {
      bg: 'background',
      color: 'text'
    }
  },
  shareButton: {
    facebook: {
      bg: 'facebook',
      color: 'background'
    },
    twitter: {
      bg: 'twitter',
      color: 'background'
    },
    pinterest: {
      bg: 'pinterest',
      color: 'background'
    },
    whatsapp: {
      bg: 'whatsapp',
      color: 'background'
    },
    email: {
      bg: 'gray.2',
      color: 'background'
    }
  },
  header: {
    fixed: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0
    }
  },
  notifications: {
    "default": {
      '&:focus, &:hover, &:active': {
        bg: 'gray.4'
      }
    },
    "new": {
      bg: 'highlight'
    }
  },
  promo: {
    secondary: {
      bg: 'gray.4'
    },
    highlight: {
      bg: 'highlight'
    }
  },
  feedback: {
    warning: {
      bg: (0, _color.alpha)('warning', 0.5),
      color: 'text'
    },
    positive: {
      bg: (0, _color.alpha)('success', 0.3),
      color: 'text'
    },
    primary: {
      bg: 'primary',
      color: 'background'
    },
    highlight: {
      bg: 'highlight',
      color: 'text'
    }
  }
};
exports["default"] = _default;