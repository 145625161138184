import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Box, Flex } from '@theme-ui/components';
import { BaseStyles } from 'theme-ui';
import dayjs from 'dayjs';

import {
  Button,
  Container,
  Heading1,
  Link,
  Text0Regular,
  User
} from '@tso/tso-components';

import {
  ImagesByLocation,
  MapPreview,
  Share,
  EditRequest,
  BackArrow,
  DeleteRequestModal,
  Meta
} from '../../Components/index';
import { requestDetail } from '../../fragments/index';
import { getAddress } from '../../Components/Address';
import { useAuth } from '../../hooks/index';

const GET_REQUEST = gql`
  query($id: Int!) {
    request(id: $id) {
      ...RequestDetail
    }
  }
  ${requestDetail}
`;

const yearRange = ({ date, year_start, year_end }) => {
  if (year_start && year_end) {
    return { between: [year_start, year_end] };
  }

  return { on: dayjs(date).toISOString() };
};

const title = request => {
  const { year_start, date, year_end } = request;
  const yearCopy = date
    ? `on ${dayjs(date).format('D MMM YYYY')}`
    : `between ${year_start} and ${year_end}`;

  return `Help me add to the story of ${getAddress(request).join(
    ', '
  )} ${yearCopy}`;
};

const Request = ({ match }) => {
  const { data, loading, error } = useQuery(GET_REQUEST, {
    variables: { id: parseInt(match.params.id) },
    fetchPolicy: 'network-only'
  });

  const { user: loggedInUser } = useAuth();

  if (loading || error) {
    return null;
  }

  return (
    <>
      <Meta title="Request" />
      <Box pt={[2, 4]}>
        <Container variant="xxl">
          <Box
            sx={{
              display: [null, 'flex'],
              alignItems: [null, 'flex-start'],
              position: 'relative',
              pb: 3,
              borderBottom: 1,
              borderBottomColor: 'gray.4'
            }}>
            <BackArrow
              sx={{
                position: ['absolute', 'static'],
                top: 2,
                left: 2,
                zIndex: 1,
                flex: 'none',
                mr: [null, 3]
              }}
            />

            <Box
              sx={{
                bg: 'gray.4',
                mb: 2,
                order: [null, 1],
                width: [null, 250, 480],
                flex: [null, 'none'],
                height: [160, null, 280],
                overflow: 'hidden'
              }}>
              <MapPreview
                sx={{
                  size: '100%'
                }}
                latitude={data.request.latitude}
                longitude={data.request.longitude}
              />
            </Box>

            <Box
              sx={{
                order: [null, 0],
                flex: [null, '1 1 auto'],
                mr: [null, 3]
              }}>
              <Box>
                <Heading1>{title(data.request)}</Heading1>
                <User
                  id={data.request.user.id}
                  name={data.request.user.name}
                  image={{ url: data.request.user.profile_src }}
                  size={40}
                  mb={3}>
                  <Text0Regular color="gray.2">
                    {dayjs(data.request.created_at).format('D MMM YYYY')}
                  </Text0Regular>
                </User>

                <BaseStyles>
                  <p>{data.request.description}</p>
                </BaseStyles>
              </Box>
            </Box>
          </Box>

          <Flex
            sx={{
              alignItems: 'center',
              borderBottom: 1,
              borderColor: 'gray.4',
              py: 2,
              mb: 3
            }}>
            <Share
              shareData={{
                name: `The Story Of ${data.request.street_address_1}`,
                description: data.request.description,
                url: window.location.href
              }}
            />
            {loggedInUser.id === data.request.user.id && (
              <>
                <EditRequest id={parseInt(match.params.id)} />
                <DeleteRequestModal id={parseInt(match.params.id)} />
              </>
            )}
            <Box ml="auto">
              <RouterLink to={'/upload'}>
                <Button as={Link}>Add a photo</Button>
              </RouterLink>
            </Box>
          </Flex>
        </Container>

        <ImagesByLocation
          latitude={data.request.latitude}
          longitude={data.request.longitude}
          {...yearRange(data.request)}
        />
      </Box>
    </>
  );
};

Request.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired
};

export default Request;
export { GET_REQUEST, title };
