import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box, Flex } from '@theme-ui/components';
import { useHistory } from 'react-router-dom';

import { Button, Heading5 } from '@tso/tso-components';

import { useSearch } from '../../hooks/index';

const POPULAR_CATEGORIES = gql`
  {
    popularCategories {
      id
      name
    }
  }
`;

const SearchCategories = () => {
  const { searchCategory } = useSearch();

  const { loading, error, data } = useQuery(POPULAR_CATEGORIES, {
    fetchPolicy: 'network-only'
  });
  const history = useHistory();

  if (loading || error) {
    return null;
  }

  const selectCategory = id => async event => {
    event.preventDefault();
    await searchCategory({ variables: { category_id: id } });
    history.push('/results');
  };

  return (
    <Box sx={{ p: 2 }}>
      <Heading5 sx={{ mb: 2 }}>Popular categories</Heading5>
      <Flex sx={{ flexWrap: 'wrap', ml: -1 }}>
        {data.popularCategories.map(({ id, name }) => (
          <Button
            key={id}
            appearance={'outline'}
            size="small"
            onClick={selectCategory(id)}
            sx={{ ml: 1, mb: 1 }}>
            {name}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default SearchCategories;
export { POPULAR_CATEGORIES };
