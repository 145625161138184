import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SelectFromImages } from '../../Components';
import { useAlert, useAuth } from '../../hooks/index';
import { GET_USER_IMAGES } from '../../Components/Profile/UserImages';
import { paginate } from '../../actions/index';
import { ADD_TO_COLLECTION } from '../../Components/Image/AddToCollection';

const SelectMoreCollectionPhotos = ({ match }) => {
  const [selected, setSelected] = useState([]);

  const { user } = useAuth();
  const { showSuccess } = useAlert();

  const history = useHistory();
  const [addPhotos] = useMutation(ADD_TO_COLLECTION);
  const { loading, error, data, fetchMore } = useQuery(GET_USER_IMAGES, {
    variables: { id: user.id },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.userImages.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id: user.id, page: currentPage + 1 },
      updateQuery: paginate('userImages')
    });
  };

  const handleImageSelect = newId => () => {
    const existing = selected.find(id => id === newId);

    if (existing) {
      return setSelected(selected => selected.filter(id => id !== newId));
    }

    setSelected(selected => selected.concat([newId]));
  };

  const handleSubmit = async () => {
    await addPhotos({
      variables: {
        collection_id: parseInt(match.params.id),
        image_ids: selected
      }
    });
    showSuccess('Successfully added photos to your collection!');
    history.push(`/collections/${match.params.id}`);
  };

  if (loading || error) {
    return null;
  }

  return (
    <SelectFromImages
      images={data.userImages.data}
      selected={selected}
      handleSubmit={handleSubmit}
      handleBottom={handleBottom}
      handleImageSelect={handleImageSelect}
    />
  );
};

SelectMoreCollectionPhotos.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired
    }).isRequired
  }).isRequired
};

export default SelectMoreCollectionPhotos;
