const paginate = queryName => (prev, { fetchMoreResult }) => {
  return {
    ...prev,
    [queryName]: {
      ...fetchMoreResult[queryName],
      data: [...prev[queryName].data, ...fetchMoreResult[queryName].data]
    }
  };
};

export { paginate };
