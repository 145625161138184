import { GET_LOGGED_IN } from '../hooks/useAuth';

const setUser = (cache, { data }) => {
  const [newUser] = Object.values(data);
  const { user } = cache.readQuery({ query: GET_LOGGED_IN });

  cache.writeData({
    data: {
      user: { ...user, ...newUser }
    }
  });
};

export { setUser };
