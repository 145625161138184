import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Text
} from '@tso/tso-components';

import { useAlert } from '../../hooks/index';
import { userCollection } from '../../fragments/index';

const PUBLISH_COLLECTION = gql`
  mutation publishCollection($id: Int!) {
    publishCollection(id: $id) {
      ...UserCollection
    }
  }
  ${userCollection}
`;

const PublishCollection = ({ id }) => {
  const [modal, setModal] = useState(false);

  const [publishCollection] = useMutation(PUBLISH_COLLECTION, {
    refetchQueries: ['collections', 'privateCollections', 'userCollections']
  });

  const { showSuccess } = useAlert();

  const toggleModal = () => setModal(modal => !modal);

  const handlePublishCollection = async () => {
    await publishCollection({ variables: { id } });

    toggleModal();
    showSuccess('Collection published successfully');
  };

  return (
    <>
      <Button
        appearance="tertiary"
        size="small"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 3
        }}
        onClick={toggleModal}>
        Publish
      </Button>
      <div>
        <Modal isOpen={modal}>
          <ModalHeader>
            Are you sure you want to publish this collection?
          </ModalHeader>
          <ModalBody>
            <div style={{ marginBottom: '1rem' }}>
              <Text>
                By publishing this collection, it will become discoverable by
                everyone on The Story Of. Would you like to proceeed?
              </Text>
            </div>
          </ModalBody>
          <ModalFooter sx={{ textAlign: 'center' }}>
            <Button onClick={handlePublishCollection}>Publish</Button>
            <Button onClick={toggleModal} appearance="link">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

PublishCollection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  is_private: PropTypes.bool.isRequired
};

export default PublishCollection;
export { PUBLISH_COLLECTION };
