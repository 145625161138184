"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _color = require("@theme-ui/color");

var pins = {
  "default": {
    bg: (0, _color.alpha)('primary', 0.1),
    borderColor: 'primary',
    color: 'primary',
    '&:focus, &:hover, &:active': {
      bg: (0, _color.alpha)('text', 0.15),
      borderColor: 'text',
      color: 'text'
    }
  },
  active: {
    bg: 'text',
    borderColor: 'text',
    color: 'text'
  }
};
var _default = pins;
exports["default"] = _default;