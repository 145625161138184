const user = {
  __typename: 'User',
  id: null,
  name: null,
  email: null,
  website_url: null,
  profile_src: null,
  profile_name: null,
  is_admin: false
};

const search = {
  __typename: 'Search',
  input: '',
  global_input: '',
  latitude: null,
  longitude: null,
  year_start: '1900',
  year_end: new Date().getFullYear().toString(),
  category_id: null,
  sort: 'NEWEST_CREATED',
  view: 'GRID',
  distance: 'LOCAL'
};

const alert = {
  __typename: 'Alert',
  status: '',
  message: ''
};

const defaultCache = () => ({
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : user,
  history: [],
  scroll: 0,
  isLoggedIn: Boolean(
    localStorage.getItem('token') && localStorage.getItem('user')
  ),
  search,
  alert,
  sortBy: 'NEWEST_CREATED'
});

export { defaultCache, user, search, alert };
