import { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

const GET_ALERT = gql`
  {
    alert @client {
      status
      message
    }
  }
`;

const useAlert = () => {
  const { data = {}, client } = useQuery(GET_ALERT);
  const history = useHistory();

  const writeAlert = useCallback(
    ({ status, message }) =>
      client.writeData({
        data: {
          alert: {
            __typename: 'Alert',
            status,
            message
          }
        }
      }),
    [client]
  );

  const showAlert = useCallback(
    message => writeAlert({ status: '', message }),
    [writeAlert]
  );

  const showSuccess = useCallback(
    message => writeAlert({ status: 'positive', message }),
    [writeAlert]
  );

  const showWarning = useCallback(
    message => writeAlert({ status: 'warning', message }),
    [writeAlert]
  );

  const closeAlert = useCallback(() => {
    writeAlert({ status: '', message: '' });
  }, [writeAlert]);

  const showWarningAndRedirect = useCallback(
    ({ message, to }) => {
      writeAlert({ status: 'warning', message });
      history.push(to);
    },
    [writeAlert, history]
  );

  const { alert = {} } = data;

  return {
    ...alert,
    hasAlert: Boolean(alert.status && alert.message),
    showAlert,
    showSuccess,
    showWarning,
    showWarningAndRedirect,
    closeAlert
  };
};

export default useAlert;
export { GET_ALERT };
