import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import { length } from '../../../validation/index';

import { Textarea, Button, FormFeedback } from '@tso/tso-components';

const ADD_COMMENT = gql`
  mutation commentOnImage($id: Int, $comment: String) {
    commentOnImage(id: $id, comment: $comment) {
      user {
        name
      }
      likes {
        id
        user {
          id
        }
      }
      dislikes {
        id
        user {
          id
        }
      }
      id
      comment
    }
  }
`;

const AddComment = React.forwardRef(({ imageId, handleComplete }, ref) => {
  const [comment, setComment] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [addComment] = useMutation(ADD_COMMENT, {
    onCompleted: handleComplete
  });

  const handleCommentChange = event => setComment(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();

    const { valid, error } = length(comment, {
      min: 2,
      max: 500,
      type: 'Comment'
    });

    if (!valid) {
      return setErrorMessage(error);
    }

    addComment({
      variables: {
        id: imageId,
        comment: comment.trim()
      }
    });
    setComment('');
  };

  return (
    <>
      <Textarea
        ref={ref}
        placeholder="Share your story of this place or suggest an edit on the photo’s date and location"
        onChange={handleCommentChange}
        value={comment}
        variant={errorMessage ? 'invalid' : 'default'}
        aria-label={'comment_input'}
        noresize
      />
      {errorMessage && (
        <FormFeedback variant="invalid">{errorMessage}</FormFeedback>
      )}
      <Box mt={3} my={3}>
        <Button onClick={handleSubmit} appearance="primary">
          Publish
        </Button>
      </Box>
    </>
  );
});

AddComment.propTypes = {
  imageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleComplete: PropTypes.func.isRequired
};

export default AddComment;
export { ADD_COMMENT };
