"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.problems = exports.locations = exports.tags = exports.categories = exports.days = exports.months = exports.years = void 0;
var years = [{
  value: 2019,
  label: 2019
}, {
  value: 2018,
  label: 2018
}, {
  value: 2017,
  label: 2017
}, {
  value: 2016,
  label: 2016
}, {
  value: 1919,
  label: 1919
}];
exports.years = years;
var months = [{
  value: '01',
  label: 'January'
}, {
  value: '02',
  label: 'February'
}, {
  value: '03',
  label: 'March'
}, {
  value: '04',
  label: 'April'
}, {
  value: '05',
  label: 'May'
}, {
  value: '06',
  label: 'June'
}, {
  value: '07',
  label: 'July'
}, {
  value: '08',
  label: 'August'
}, {
  value: '09',
  label: 'September'
}, {
  value: '10',
  label: 'October'
}, {
  value: '11',
  label: 'November'
}, {
  value: '12',
  label: 'December'
}];
exports.months = months;
var days = [{
  value: '01',
  label: '01'
}, {
  value: '02',
  label: '02'
}, {
  value: '03',
  label: '03'
}, {
  value: '04',
  label: '04'
}, {
  value: '05',
  label: '04'
}, {
  value: '06',
  label: '06'
}, {
  value: '07',
  label: '07'
}, {
  value: '08',
  label: '08'
}];
exports.days = days;
var categories = [{
  value: 'Category 1',
  label: 'Category 1'
}, {
  value: 'Category 2',
  label: 'Category 2'
}, {
  value: 'Category 3',
  label: 'Category 3'
}, {
  value: 'Category 4',
  label: 'Category 4'
}, {
  value: 'Category 5',
  label: 'Category 5'
}];
exports.categories = categories;
var tags = [{
  value: 'Market',
  label: 'Market'
}, {
  value: 'Square',
  label: 'Square'
}, {
  value: 'Tree',
  label: 'Tree'
}];
exports.tags = tags;
var locations = [{
  value: 'Norwich Cathedral',
  label: 'Norwich Cathedral'
}];
exports.locations = locations;
var problems = [{
  value: 'Nudity',
  label: 'Nudity'
}, {
  value: 'Violence',
  label: 'Violence'
}, {
  value: 'False information',
  label: 'False information'
}, {
  value: 'Undesirable content',
  label: 'Undesirable content'
}, {
  value: 'Someting else',
  label: 'Someting else'
}];
exports.problems = problems;
var data = {
  categories: categories,
  days: days,
  locations: locations,
  months: months,
  problems: problems,
  tags: tags,
  years: years
};
var _default = data;
exports["default"] = _default;