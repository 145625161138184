import React from 'react';
import PropTypes from 'prop-types';

import { ImageGrid, Image } from '@tso/tso-components';

import { ImageActions } from '../index';
import { description } from '../../validation/index';

const RelatedImages = ({ images }) => (
  <ImageGrid name="Related photos" py={3}>
    {images.map(({ id, src, revision, ...rest }) => (
      <Image
        Actions={ImageActions}
        id={id}
        name={revision.name}
        description={description(revision)}
        orientation={revision.orientation}
        image={{ url: src }}
        key={id}
        isPublic={true}
        {...rest}
      />
    ))}
  </ImageGrid>
);

RelatedImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      revision: PropTypes.shape({ name: PropTypes.string.isRequired }),
      src: PropTypes.string.isRequired
    })
  ).isRequired
};

export default RelatedImages;
