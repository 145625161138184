import React from 'react';

import { Profile as ProfileComponent } from '../../Components/index';
import { useAuth } from '../../hooks/index';

const MyProfile = () => {
  const { user } = useAuth();

  return <ProfileComponent id={user.id} />;
};

export default MyProfile;
