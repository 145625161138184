import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { detailedLike } from '../fragments/index';
import { setComments } from '../actions/index';

const LIKE_COMMENT = gql`
  mutation likeComment($id: Int!) {
    likeComment(id: $id) {
      ...DetailedLike
    }
  }
  ${detailedLike}
`;

const DISLIKE_COMMENT = gql`
  mutation dislikeComment($id: Int!) {
    dislikeComment(id: $id) {
      ...DetailedLike
    }
  }
  ${detailedLike}
`;

const useLikes = imageId => {
  const [dislikeComment] = useMutation(DISLIKE_COMMENT, {
    update: setComments(imageId)
  });
  const [likeComment] = useMutation(LIKE_COMMENT, {
    update: setComments(imageId)
  });

  const like = id => likeComment({ variables: { id } });

  const dislike = id => dislikeComment({ variables: { id } });

  return { like, dislike };
};

export default useLikes;
export { LIKE_COMMENT, DISLIKE_COMMENT };
