import { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { loggedInUser } from '../fragments/index';
import { handleLogout } from '../apollo/auth';

const GET_LOGGED_IN = gql`
  {
    user @client {
      ...LoggedInUser
    }
    isLoggedIn @client
  }
  ${loggedInUser}
`;

const useAuth = () => {
  const client = useApolloClient();

  const { data = {} } = useQuery(GET_LOGGED_IN);

  const logout = useCallback(
    message => {
      handleLogout({
        client,
        message: message || 'You have been successfully logged out.'
      });
    },
    [client]
  );

  const { isLoggedIn = false, user = {} } = data;

  // Listen for updates to user, and persist in local storage.
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return {
    isAdmin: Boolean(user.is_admin),
    isNotAdmin: !user.is_admin,
    isLoggedIn,
    isNotLoggedIn: !isLoggedIn,
    isGuest: !isLoggedIn,
    isNotGuest: isLoggedIn,
    logout,
    user
  };
};

export default useAuth;
export { GET_LOGGED_IN };
