import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';

import SortBy, { options } from '../SortBy.js';
import GoogleSearch from '../GoogleSearch';
import { useLocation } from '../../hooks/index';

const RequestSearch = ({ handleRequestQuery, sort, ...rest }) => {
  const [location, setLocation] = useState();

  const [getLocation] = useLocation(handleRequestQuery);

  const handleLocationSelect = location => {
    setLocation(location);

    if (location) {
      return getLocation({ variables: { place_id: location.value } });
    }

    return handleRequestQuery({ latitude: null, longitude: null });
  };

  const handleSort = option => handleRequestQuery({ sort: option.value });

  return (
    <Box
      sx={{
        display: [null, 'flex'],
        alignItems: [null, 'flex-end'],
        mx: [null, -2]
      }}
      {...rest}>
      <Box sx={{ mb: [3, 0], mx: [null, 2], width: ['100%', 300] }}>
        <GoogleSearch value={location} onChange={handleLocationSelect} />
      </Box>
      <Box sx={{ mx: [null, 2], flex: 'none' }}>
        <SortBy
          value={options.find(({ value }) => value === sort)}
          handleChange={handleSort}
          right
        />
      </Box>
    </Box>
  );
};

export default RequestSearch;

RequestSearch.propTypes = {
  handleRequestQuery: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired
};
