import React from 'react';
import { Box } from '@theme-ui/components';
import PropTypes from 'prop-types';

import {
  List,
  Dropdown,
  DropdownToggle,
  IconButton,
  DropdownMenu
} from '@tso/tso-components';

import { useAuth } from '../../hooks/index';
import { EditCollection, DeleteCollection, Share } from '../index';

const CollectionActions = ({
  id,
  name,
  image,
  description,
  options,
  setOptions,
  header,
  user,
  is_private,
  setEntered
}) => {
  const { user: loggedInUser } = useAuth();

  const toggleOptions = () => setOptions(options => !options);

  const close = () => {
    setEntered(false);
    setOptions(false);
  };

  const isTheUsersCollection = loggedInUser.id === user.id;

  return (
    <Box sx={{ position: 'absolute', top: 2, right: 2, zIndex: 4 }}>
      <Dropdown isOpen={options} toggle={toggleOptions}>
        <DropdownToggle
          as={IconButton}
          icon="more"
          aria-label="Collection options"
          appearance="backgroundAlt"
          size="small"
        />
        <DropdownMenu right>
          <List>
            <Share
              onShare={close}
              buttonType="list"
              shareData={{
                name,
                description,
                url: `${window.location.host}/collections/${id}`,
                image: image ? image.url : ''
              }}
            />
            {isTheUsersCollection && (
              <>
                <EditCollection
                  buttonType="list"
                  id={id}
                  name={name}
                  description={description}
                  is_private={is_private}
                  header={header}
                />
                <DeleteCollection id={id} buttonType="list" />
              </>
            )}
          </List>
        </DropdownMenu>
      </Dropdown>
    </Box>
  );
};

CollectionActions.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  image: PropTypes.object,
  description: PropTypes.any,
  options: PropTypes.any,
  setOptions: PropTypes.any,
  setEntered: PropTypes.any,
  user: PropTypes.any,
  header: PropTypes.object,
  is_private: PropTypes.bool
};

export default CollectionActions;
