import { GET_IMAGE } from '../Pages/App/Image';

const getImage = (cache, id) => {
  const { image } = cache.readQuery({
    query: GET_IMAGE,
    variables: { id: parseInt(id) }
  });

  return image;
};

/**
 * Handle liking and disliking a comment. Can take multiple routes:
 * - User is liking / disliking for the first time.
 * - User is liking a comment with an existing dislike, swap them.
 * - User is disliking a comment with an existing like, swap them.
 * - User has double clicked a like / dislike. Remove it.
 */
const likeComment = (comment, like) => {
  if (like.positive) {
    const existingLike = comment.likes.find(
      ({ user }) => user.id === like.user.id
    );

    return {
      ...comment,
      likes: existingLike
        ? comment.likes.filter(({ user }) => user.id !== like.user.id)
        : comment.likes.concat(like),
      dislikes: comment.dislikes.filter(({ user }) => user.id !== like.user.id)
    };
  }

  const existingDislike = comment.dislikes.find(
    ({ user }) => user.id === like.user.id
  );

  return {
    ...comment,
    likes: comment.likes.filter(({ user }) => user.id !== like.user.id),
    dislikes: existingDislike
      ? comment.dislikes.filter(({ user }) => user.id !== like.user.id)
      : comment.dislikes.concat(like)
  };
};

const setComments = id => (cache, { data }) => {
  const image = getImage(cache, id);
  const like = data[Object.keys(data)[0]];

  const { comments: paginatedComments, ...imageFields } = image;
  const { data: comments, ...commentFields } = paginatedComments;

  cache.writeQuery({
    query: GET_IMAGE,
    variables: { id: parseInt(id) },
    data: {
      image: {
        ...imageFields,
        comments: {
          data: comments.map(comment => {
            if (comment.id === like.likeable_id) {
              return likeComment(comment, like);
            }
            return comment;
          }),
          ...commentFields
        }
      }
    }
  });
};

export { setComments, getImage };
