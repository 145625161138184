import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { options } from '../Components/SortBy';

const GET_SORT = gql`
  {
    sortBy @client
  }
`;

const ADD_SORT = gql`
  mutation addSort($sortBy: String) {
    addSort(sortBy: $sortBy) @client
  }
`;

const useSortSelection = () => {
  const { data: sortData } = useQuery(GET_SORT);
  const [addSortMutation] = useMutation(ADD_SORT);

  const addSort = async sortBy => {
    await addSortMutation({
      variables: { sortBy: sortBy.value }
    });
  };

  return {
    sortBy: sortData?.sortBy
      ? options.filter(option => option.value == sortData.sortBy)[0]
      : { ...options[0] },
    addSort
  };
};

export default useSortSelection;
