import { useCallback } from 'react';
import { useHistory as useBrowserHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

const GET_HISTORY = gql`
  {
    history @client
  }
`;

const ADD_TO_HISTORY = gql`
  mutation addToHistory($url: String) {
    addToHistory(url: $url) @client
  }
`;

const useHistory = () => {
  const { push: redirect } = useBrowserHistory();

  const { data = {} } = useQuery(GET_HISTORY);
  const [addToHistoryMutation] = useMutation(ADD_TO_HISTORY);

  const { history = {} } = data;

  const addToHistory = useCallback(
    async pathname => addToHistoryMutation({ variables: { url: pathname } }),
    [addToHistoryMutation]
  );

  const authRedirect = useCallback(async () => {
    // If the login page is the first page they've been on, take
    // them home.
    if (history.length === 0) {
      return redirect('/');
    }

    // Find the last route they went on that wasn't an auth page.
    const redirectRoute = history
      .reverse()
      .find(url => !['/login', '/signup'].includes(url));

    if (!redirectRoute) {
      return redirect('/');
    }

    redirect(redirectRoute);
  }, [history, redirect]);

  return { history, authRedirect, addToHistory };
};

export default useHistory;
export { GET_HISTORY, ADD_TO_HISTORY };
