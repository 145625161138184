import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useAlert } from '../../../hooks/index';
import Report from '../../Report';

const REPORT_COMMENT = gql`
  mutation reportComment($id: Int!, $problem_id: Int!, $message: String!) {
    reportComment(id: $id, problem_id: $problem_id, message: $message) {
      id
      user {
        id
      }
    }
  }
`;

const ReportComment = ({ id, ...props }) => {
  const [reportComment] = useMutation(REPORT_COMMENT);

  const { showSuccess } = useAlert();

  const handleReport = async (message, problemId) => {
    await reportComment({
      variables: { id: parseInt(id), problem_id: parseInt(problemId), message }
    });
    showSuccess('Report submitted');
  };

  return (
    <Report
      {...props}
      reportType={'Comment'}
      header="Report a comment"
      handleReport={handleReport}
      submitText="Report comment"
    />
  );
};

ReportComment.propTypes = {
  id: PropTypes.number.isRequired
};

export default ReportComment;
export { REPORT_COMMENT };
