import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import { Heading5, Text1Regular, Text0Light } from '@tso/tso-components';

const POPULAR_LOCATIONS = gql`
  {
    popularLocations {
      name
      hits
    }
  }
`;

const SearchPopularLocations = ({ handlePopularLocation }) => {
  const { loading, data, error } = useQuery(POPULAR_LOCATIONS, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Heading5 sx={{ mb: 2 }}>Popular</Heading5>
      <Box sx={{ mx: -2 }}>
        {data.popularLocations.map(({ name }, key) => (
          <Box
            as="button"
            key={key}
            sx={{
              appearance: 'none',
              display: 'block',
              width: '100%',
              textAlign: 'left',
              bg: 'transparent',
              border: 0,
              borderRadius: 0,
              cursor: 'pointer',
              outline: 0,
              p: 2,
              '&:focus, &:hover, &:active': {
                bg: 'gray.4'
              }
            }}
            onClick={() => handlePopularLocation(name)}>
            <Text1Regular as="div">{name}</Text1Regular>
            <Text0Light as="div" color="gray.2">
              Norfolk, United Kingdom
            </Text0Light>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

SearchPopularLocations.propTypes = {
  handlePopularLocation: PropTypes.func.isRequired
};

export default SearchPopularLocations;
export { POPULAR_LOCATIONS };
