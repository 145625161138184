import React from 'react';
import { Box } from '@theme-ui/components';
import PropTypes from 'prop-types';

import {
  List,
  Dropdown,
  DropdownToggle,
  IconButton,
  DropdownMenu
} from '@tso/tso-components';

import { useAuth } from '../../hooks/index';
import {
  EditImageModal,
  DeleteImage,
  AddToCollection,
  ReportImage,
  Share,
  RemoveImageFromCollection,
  AuthListItem
} from '../index';

const ImageActions = ({
  id,
  name,
  description,
  options,
  setOptions,
  user,
  setEntered,
  collection,
  image
}) => {
  const { user: loggedInUser } = useAuth();

  const toggleOptions = () => setOptions(options => !options);

  const close = () => {
    setEntered(false);
    setOptions(false);
  };

  const isTheUsersImage = user && loggedInUser.id === user.id;

  return (
    <Box sx={{ position: 'absolute', top: 2, right: 2, zIndex: 3 }}>
      <Dropdown isOpen={options} toggle={toggleOptions}>
        <DropdownToggle
          as={IconButton}
          icon="more"
          aria-label="Image options"
          appearance="backgroundAlt"
          size="small"
        />
        <DropdownMenu right>
          <List>
            {collection && collection.user.id === loggedInUser.id && (
              <RemoveImageFromCollection id={id} collection={collection} />
            )}
            <AddToCollection Submit={AuthListItem} imageId={parseInt(id)} />
            <Share
              onShare={close}
              buttonType="list"
              shareData={{
                name,
                description,
                url: `${window.location.host}/images/${id}`,
                image: image.url
              }}
            />
            {!isTheUsersImage && <ReportImage Submit={AuthListItem} id={id} />}
            {isTheUsersImage && (
              <>
                <EditImageModal id={id} />
                <DeleteImage id={id} />
              </>
            )}
          </List>
        </DropdownMenu>
      </Dropdown>
    </Box>
  );
};

ImageActions.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  description: PropTypes.any,
  options: PropTypes.any,
  setOptions: PropTypes.any,
  setEntered: PropTypes.any,
  isPublic: PropTypes.any,
  user: PropTypes.any,
  collection: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default ImageActions;
