import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

const GET_LOCATION_DETAILS = gql`
  query getLocationDetails($place_id: String!) {
    getLocationDetails(place_id: $place_id) {
      street_address_1
      street_address_2
      city
      region
      postcode
      country
      latitude
      longitude
    }
  }
`;

const useLocation = callback => {
  const query = useLazyQuery(GET_LOCATION_DETAILS, {
    onCompleted: ({ getLocationDetails: result }) => {
      callback({
        ...result,
        latitude: parseFloat(result.latitude),
        longitude: parseFloat(result.longitude)
      });
    }
  });

  return query;
};

export default useLocation;
export { GET_LOCATION_DETAILS };
