import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/react-hooks';
import {
  Heading1,
  Text,
  Button,
  Container,
  Request,
  Heading2
} from '@tso/tso-components';

import { Share, MapPreview, Meta } from '../../Components/index';
import { PopularImages } from '../../Components/Image/TodaysPick';

import { GET_REQUESTS } from './Requests';
import { title } from './Request';

const RequestCreateSuccess = props => {
  const { data, error } = useQuery(GET_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: { page: 0, sort: 'NEWEST_CREATED' }
  });

  const showRequests = Boolean(!error && data && data.requests);

  return (
    <Box variant="layout.baz">
      <Meta title="Request successful" />
      <Container>
        <Box variant="layout.form" style={{ marginBottom: '2rem' }}>
          <Heading1>Your request has been posted to our community</Heading1>
          <Text mb={4}>
            Share your request on social media to help even more people help you
            tell your story.
          </Text>
          <Share
            defaultOpen={true}
            buttonType={'request'}
            shareData={{
              name: `I've just created a request about {location}`,
              description: 'Offer to help me with my request',
              url: `https://thestoryof.uk/requests/${props.match.params.id}`
            }}
          />
          <Link to={'/requests/create'}>
            <Button appearance={'link'}>Make another request</Button>
          </Link>
        </Box>
        {showRequests && (
          <>
            <Heading2>Recently created requests</Heading2>

            {data.requests.data.map(
              ({
                id,
                latitude,
                longitude,
                image_count,
                created_at,
                user,
                description,
                ...rest
              }) => (
                <Request
                  key={id}
                  id={id}
                  date={dayjs(created_at).format('D MMM YYYY')}
                  user={user}
                  latitude={latitude}
                  longitude={longitude}
                  name={title(rest)}
                  count={image_count}
                  description={description}
                  mb={3}>
                  <MapPreview
                    latitude={latitude}
                    longitude={longitude}
                    sx={{
                      flex: 'none',
                      width: ['100%', 200],
                      height: [190, 130]
                    }}
                  />
                </Request>
              )
            )}
          </>
        )}

        <PopularImages
          heading={'Explore some popular places'}
          subHeading={''}
        />
      </Container>
    </Box>
  );
};

RequestCreateSuccess.propTypes = {
  match: PropTypes.object,
  id: PropTypes.number
};

export default RequestCreateSuccess;
