import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { GoogleContext } from '../Contexts/index';

const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

const GoogleProvider = ({ children }) => {
  const [google, setGoogle] = useState();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.onload = () => setGoogle(window.google);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!google) {
    return null;
  }

  return (
    <GoogleContext.Provider value={{ ...google.maps }}>
      {children}
    </GoogleContext.Provider>
  );
};

GoogleProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default GoogleProvider;
export { src };
