import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@tso/tso-components';

const BackArrow = props => {
  const history = useHistory();

  return (
    <IconButton
      as={'button'}
      onClick={history.goBack}
      label="Back"
      icon="arrowleft"
      size="large"
      appearance="secondary"
      {...props}
    />
  );
};

export default BackArrow;
