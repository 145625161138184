import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { Box } from '@theme-ui/components';

import {
  Collections,
  Container,
  CreateButton,
  Link,
  Text
} from '@tso/tso-components';

import { userCollection } from '../../fragments/index';
import { paginate } from '../../actions/index';
import { InfiniteScroll, CollectionActions } from '../index';
import { useAuth } from '../../hooks/index';

const GET_PUBLIC_COLLECTIONS = gql`
  query userCollections($id: Int!, $page: Int) {
    userCollections(id: $id, page: $page) {
      data {
        ...UserCollection
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${userCollection}
`;

const UserCollections = ({ id }) => {
  const { user: loggedInUser } = useAuth();

  useEffect(() => localStorage.removeItem('selectedImage'), []);

  const { data, loading, error, fetchMore } = useQuery(GET_PUBLIC_COLLECTIONS, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.userCollections.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id, page: currentPage + 1 },
      updateQuery: paginate('userCollections')
    });
  };

  if (loading || error) {
    return null;
  }

  const onLoggedInUserProfile = loggedInUser.id === id;

  if (data.userCollections.total === 0 && onLoggedInUserProfile) {
    return (
      <Container variant="xxl">
        <CreateButton
          text="Create a collection"
          to="/collections/create"
          sx={{ mx: 'auto', mb: 3 }}
        />
        <Text center>
          You haven’t created a collection yet.{' '}
          <Link as={ReactRouterDomLink} to="/collections/create">
            Create a collection
          </Link>
        </Text>
      </Container>
    );
  }

  return (
    <InfiniteScroll onBottom={handleBottom}>
      <Collections
        collections={data.userCollections.data.map(
          ({ id, name, description, user, header }) => ({
            Actions: CollectionActions,
            id,
            name,
            description,
            ...(header && {
              image: { url: header.src },
              orientation: header.revision.orientation
            }),
            user: {
              id: user.id,
              name: user.name,
              image: {
                url: user.profile_src
              }
            },
            is_private: false
          })
        )}>
        <Box data-id="collection">
          <CreateButton
            text="Create a collection"
            to="/collections/create"
            sx={{ size: '100%' }}
          />
        </Box>
      </Collections>
    </InfiniteScroll>
  );
};

UserCollections.propTypes = {
  id: PropTypes.number.isRequired
};

export default UserCollections;
export { GET_PUBLIC_COLLECTIONS };
