import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/react-hooks';

import { Pagination, Button } from '@tso/tso-components';

import { SortToggle, Tableheading as Heading, TableCell } from '../index';
import { usePage } from '../../hooks/index';
import { AdminPage, AdminDeleteReport } from '../../Components/index';

const GET_ALL_REPORTS = gql`
  query reports($page: Int, $sort: ReportSort) {
    reports(page: $page, sort: $sort) {
      data {
        id
        report_message
        type_id
        type
        target_id
        created_at
        user {
          id
          name
        }
        problem {
          name
        }
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
`;

const links = {
  Image: 'images',
  Comment: 'images',
  User: 'users'
};

const Reports = () => {
  const [page, setPage] = usePage();
  const [sort, setSort] = useState();

  const { loading, error, data } = useQuery(GET_ALL_REPORTS, {
    fetchPolicy: 'network-only',
    variables: { page, sort }
  });

  console.log(data);

  const handlePagination = page => setPage(parseInt(page) - 1);

  if (loading || error) {
    return null;
  }

  const { currentPage, lastPage } = data.reports.page;

  return (
    <AdminPage title="Reports">
      <table style={{ width: '100%', minWidth: 700 }}>
        <thead>
          <tr>
            <Heading>Message</Heading>
            <Heading>
              <span>Submitted</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'OLDEST_CREATED',
                  down: 'NEWEST_CREATED'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>
              Submitted By
              <SortToggle
                sort={{
                  current: sort,
                  up: 'USER_ASC',
                  down: 'USER_DESC'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>
              Type
              <SortToggle
                sort={{
                  current: sort,
                  up: 'TYPE_ASC',
                  down: 'TYPE_DESC'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>Actions</Heading>
          </tr>
        </thead>
        <tbody>
          {data.reports.data.map(
            (
              {
                id,
                problem,
                type,
                report_message,
                user,
                target_id,
                created_at
              },
              index
            ) => (
              <tr key={index}>
                <TableCell>{report_message || 'N/A'}</TableCell>
                <TableCell>{dayjs(created_at).fromNow()}</TableCell>
                <TableCell>
                  <Link to={`/users/${user.id}`}>{user.name}</Link>
                </TableCell>
                <TableCell>{problem ? problem.name : 'N/A'}</TableCell>
                <TableCell>
                  <Button
                    size={'small'}
                    appearance={'link'}
                    as={Link}
                    to={`/${links[type]}/${target_id}`}>
                    View {type}
                  </Button>
                  <AdminDeleteReport ids={[id]} />
                </TableCell>
              </tr>
            )
          )}
        </tbody>
        <Pagination
          paginate={{
            currentPage: currentPage + 1,
            totalPages: lastPage + 1
          }}
          handlePageChange={handlePagination}
        />
      </table>
    </AdminPage>
  );
};

export default Reports;
