import { useState } from 'react';

const useMultiSelect = (items = []) => {
  const [selected, setSelected] = useState([]);

  const allSelected = items
    .map(({ id }) => id)
    .every(id => selected.includes(id));

  const handleSelect = newId => {
    const alreadySelected = selected.includes(newId);

    setSelected(ids => {
      return alreadySelected
        ? [...new Set(ids.filter(id => id !== newId))]
        : [...new Set(ids.concat([newId]))];
    });
  };

  const handleAllSelected = () => {
    if (allSelected) {
      return setSelected(selected => [
        ...new Set(
          selected.filter(id => !items.map(({ id }) => id).includes(id))
        )
      ]);
    }

    setSelected(selected => [
      ...new Set(selected.concat(items.map(({ id }) => id)))
    ]);
  };

  return {
    selected,
    setSelected,
    handleSelect,
    handleAllSelected,
    allSelected
  };
};

export default useMultiSelect;
