"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var fontWeights = {
  ultraLight: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600
};
fontWeights.body = fontWeights.light;
fontWeights.heading = fontWeights.bold;
var _default = fontWeights;
exports["default"] = _default;