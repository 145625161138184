import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SelectFromImages } from '../../Components';
import { useAlert, useAuth } from '../../hooks/index';
import { GET_USER_IMAGES } from '../../Components/Profile/UserImages';
import { paginate } from '../../actions/index';

const CREATE_COLLECTION = gql`
  mutation createCollection(
    $name: String!
    $description: String!
    $isPrivate: Boolean!
    $header: Int!
    $imagesToAdd: [Int!]!
  ) {
    createCollection(
      name: $name
      description: $description
      isPrivate: $isPrivate
      header: $header
      imagesToAdd: $imagesToAdd
    ) {
      id
    }
  }
`;

const SelectCollectionPhotos = ({ location: { state: formData } }) => {
  const selectedImageId = parseInt(localStorage.getItem('selectedImage'));
  const [collection, setCollection] = useState({
    ...formData,
    imagesToAdd: selectedImageId ? [selectedImageId] : []
  });

  const { user } = useAuth();
  const { showSuccess } = useAlert();

  const history = useHistory();
  const [createCollection] = useMutation(CREATE_COLLECTION);
  const { loading, error, data, fetchMore } = useQuery(GET_USER_IMAGES, {
    variables: { id: user.id },
    fetchPolicy: 'network-only'
  });

  const handleBottom = () => {
    const { currentPage, hasNextPage } = data.userImages.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id: user.id, page: currentPage + 1 },
      updateQuery: paginate('userImages')
    });
  };

  const handleImageSelect = selectedId => () => {
    const existing = collection.imagesToAdd.find(id => id === selectedId);

    return setCollection(({ imagesToAdd, ...rest }) => ({
      ...rest,
      imagesToAdd: existing
        ? imagesToAdd.filter(id => id !== selectedId)
        : imagesToAdd.concat([selectedId])
    }));
  };

  const handleSubmit = async () => {
    const { data } = await createCollection({
      variables: { ...collection, header: collection.imagesToAdd[0] }
    });
    showSuccess('Successfully created your collection!');
    history.push(`/collections/${data.createCollection.id}`);
  };

  if (loading || error) {
    return null;
  }

  return (
    <SelectFromImages
      images={data.userImages.data}
      selected={collection.imagesToAdd}
      handleSubmit={handleSubmit}
      handleBottom={handleBottom}
      handleImageSelect={handleImageSelect}
    />
  );
};

SelectCollectionPhotos.propTypes = {
  location: PropTypes.object
};

export default SelectCollectionPhotos;
export { CREATE_COLLECTION };
