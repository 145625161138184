import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../../hooks/index';

const AdminRoute = props => {
  const { isNotLoggedIn, isNotAdmin } = useAuth();

  if (isNotLoggedIn) {
    return <Redirect to="/login" />;
  }

  if (isNotAdmin) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};

export default AdminRoute;
