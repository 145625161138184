import React, { useState } from 'react';
import { Box } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import {
  Button,
  Container,
  Feedback,
  Field,
  Heading1,
  Heading5,
  Input,
  Text0Medium
} from '@tso/tso-components';

import { isValidEmail, isValidFullName, length } from '../../validation/index';
import { useAuth, useAlert } from '../../hooks/index';
import { setUser } from '../../actions/index';
import {
  UploadProfilePicture,
  DeleteAccount,
  Meta
} from '../../Components/index';
import { loggedInUser } from '../../fragments/index';

const UPDATE_USER = gql`
  mutation editUser(
    $first_name: String!
    $last_name: String!
    $email: Email!
    $password: String
    $website_url: Url
  ) {
    editUser(
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
      website_url: $website_url
    ) {
      ...LoggedInUser
    }
  }
  ${loggedInUser}
`;

const Section = props => (
  <Box
    __css={{
      pb: 5,
      mb: 5,
      borderBottom: 1,
      borderColor: 'text'
    }}
    {...props}
  />
);

const Settings = () => {
  const { user } = useAuth();
  const { showSuccess } = useAlert();

  const [userData, setUserData] = useState({
    name: user.name,
    email: user.email,
    website_url: user.website_url,
    password: '',
    showPassword: false
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const [updateUser] = useMutation(UPDATE_USER, { update: setUser });

  const showPassword = event => {
    event.preventDefault();
    setUserData({ ...userData, showPassword: !userData.showPassword });
  };

  const handleEmailChange = event => {
    setUserData({ ...userData, email: event.target.value });
  };

  const handleNameChange = event => {
    setUserData({
      ...userData,
      name: event.target.value
    });
  };

  const handlePasswordChange = event => {
    setUserData({ ...userData, password: event.target.value });
  };

  const handleWebsiteChange = event => {
    setUserData({ ...userData, website_url: event.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const [firstName, lastName] = userData.name.split(' ');

    const { valid, error } = length(firstName + ' ' + lastName, {
      min: 5,
      max: 25,
      type: 'Name'
    });

    if (!valid) {
      return setErrorMessage(error);
    }

    if (!isValidFullName(userData.name) || !firstName || !lastName) {
      return setErrorMessage('Please enter your full name e.g. Joe Bloggs');
    }

    if (!isValidEmail(userData.email)) {
      return setErrorMessage("The email you've provided is not valid");
    }

    if (userData.password && userData.password.length < 8) {
      return setErrorMessage(
        "The password you've provided is not valid, ensure it's at least 8 characters"
      );
    }

    await updateUser({
      variables: {
        first_name: firstName,
        last_name: lastName,
        email: userData.email,
        password: userData.password,
        website_url: userData.website_url || null
      }
    });

    showSuccess('Profile updated successfully');
    setErrorMessage(null);
  };

  return (
    <Box variant="layout.baz">
      <Meta title="Settings" />
      <Container>
        <Box variant="layout.form">
          <Section id="profile-information">
            <Heading1>Account Settings</Heading1>

            <Heading5 as="h2" caps color="gray.2" mb={3}>
              Profile Information
            </Heading5>

            <UploadProfilePicture />

            <Field
              label="Name"
              hideLabel
              name="name"
              value={userData.name}
              onChange={handleNameChange}
              mb={3}
            />

            <Field
              label="Email"
              hideLabel
              name="email"
              type="email"
              value={userData.email}
              onChange={handleEmailChange}
              mb={3}
            />

            <Box sx={{ position: 'relative', mb: 3 }}>
              <Input
                value={userData.password}
                onChange={handlePasswordChange}
                type={userData.showPassword ? 'text' : 'password'}
                name="password"
                placeholder={'Enter a new password'}
                aria-label="password"
              />
              <Text0Medium
                as="button"
                type="button"
                tabIndex={0}
                onClick={showPassword}
                sx={{
                  appearance: 'none',
                  p: 0,
                  border: 0,
                  outline: 0,
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}>
                {userData.showPassword ? 'Hide password' : 'Show password'}
              </Text0Medium>
            </Box>

            <Field
              label="Website link"
              hideLabel
              name="websiteLink"
              value={userData.website_url || ''}
              onChange={handleWebsiteChange}
              placeholder="Add link to website"
              mb={3}
            />

            {Boolean(errorMessage) && (
              <Feedback status="warning" aria-label={'errorMessage'} mb={2}>
                {errorMessage}
              </Feedback>
            )}

            <Button type="submit" onClick={handleSubmit}>
              Save changes
            </Button>

            <Button as={RouterLink} to="/profile" appearance="link">
              Cancel
            </Button>
          </Section>
          <Section id="delete">
            <DeleteAccount />
          </Section>
        </Box>
      </Container>
    </Box>
  );
};

export default Settings;
export { UPDATE_USER };
