import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Pagination, Img, Checkbox, Label, Button } from '@tso/tso-components';

import { SortToggle, Tableheading as Heading, TableCell } from '../index';
import { AdminDeleteCollection, AdminPage } from '../../Components/index';
import { GET_COLLECTIONS } from '../../Components/Collection/AllCollections';
import { useMultiSelect, usePage } from '../../hooks/index';

const Collection = () => {
  const [page, setPage] = usePage();
  const [sort, setSort] = useState();

  const { loading, error, data } = useQuery(GET_COLLECTIONS, {
    fetchPolicy: 'network-only',
    variables: { page, sort }
  });

  const {
    selected,
    handleSelect,
    handleAllSelected,
    allSelected
  } = useMultiSelect(data?.collections?.data);

  const handlePagination = page => setPage(parseInt(page) - 1);

  if (loading || error) {
    return null;
  }

  const { currentPage, lastPage } = data.collections.page;

  return (
    <AdminPage title="Collections">
      <table style={{ width: '100%', minWidth: 700 }}>
        <thead>
          <tr>
            <Heading>
              <div style={{ display: 'inline-flex', maxWidth: '3rem' }}>
                <div>
                  <Label variant="checkbox">
                    <Checkbox
                      name="select-all-collections"
                      checked={allSelected}
                      onChange={handleAllSelected}
                    />
                  </Label>
                </div>
                {selected.length > 0 && (
                  <AdminDeleteCollection ids={selected} />
                )}
              </div>
            </Heading>
            <Heading>Preview</Heading>
            <Heading>Title</Heading>
            <Heading>Description</Heading>
            <Heading>
              <span>Created At</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'OLDEST_CREATED',
                  down: 'NEWEST_CREATED'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>
              <span>User</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'USER_ASC',
                  down: 'USER_DESC'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>Actions</Heading>
          </tr>
        </thead>
        <tbody>
          {console.log(data)}
          {data.collections.data.map(
            ({ id, name, description, header, user, created_at }, index) => (
              <tr key={index}>
                <TableCell>
                  <Label variant="checkbox">
                    <Checkbox
                      checked={selected.includes(id)}
                      onChange={() => handleSelect(id)}
                      aria-label="selectCollection"
                    />
                  </Label>
                </TableCell>
                <TableCell>
                  {header && (
                    <Img
                      src={header.src}
                      alt={name}
                      fit="contain"
                      style={{ width: '10rem', cursor: 'pointer' }}
                      orientation={header.revision.orientation}
                    />
                  )}
                  {!header && 'No header image'}
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{dayjs(created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  <Link to={`/users/${user.id}`}>{user.name}</Link>
                </TableCell>
                <TableCell>
                  <Button
                    size={'small'}
                    appearance={'link'}
                    as={Link}
                    to={`/collections/${id}`}>
                    View
                  </Button>
                  <AdminDeleteCollection ids={[id]} />
                </TableCell>
              </tr>
            )
          )}
        </tbody>
        <Pagination
          paginate={{
            currentPage: currentPage + 1,
            totalPages: lastPage + 1
          }}
          handlePageChange={handlePagination}
        />
      </table>
    </AdminPage>
  );
};

export default Collection;
