import React, { useContext, useMemo, useState } from 'react';
import { Box } from '@theme-ui/components';
import PropTypes from 'prop-types';
import Supercluster from 'supercluster';

import {
  GoogleMapContext,
  GoogleMap,
  Location,
  ImageSearch
} from '@tso/tso-components';

import { InfiniteScroll } from '../index';
import { boundary } from '../../data/index';

// https://www.npmjs.com/package/supercluster#options
const CLUSTER_CONFIG = { radius: 100, minZoom: 0, maxZoom: 18 };

const ResultMap = ({
  results = [],
  showSidebar = true,
  handleBottom = () => {}
}) => {
  const [activeResult, setActiveResult] = useState();

  const { zoom } = useContext(GoogleMapContext);
  const clusters = useMemo(
    () =>
      new Supercluster(CLUSTER_CONFIG)
        .load(
          results.map(({ revision, ...rest }) => ({
            type: 'Feature',
            properties: {
              ...rest,
              ...revision,
              lat_y: revision.latitude,
              long_x: revision.longitude
            },
            geometry: {
              type: 'Point',
              coordinates: [revision.longitude, revision.latitude]
            }
          }))
        )
        .getClusters(
          [boundary[0], boundary[1], boundary[3], boundary[2]],
          zoom
        ),
    [results, zoom]
  );

  const handleActiveResult = id => {
    if (activeResult === id) {
      return setActiveResult(null);
    }

    setActiveResult(id);
  };

  return (
    <Box
      sx={{
        display: [null, null, 'flex'],
        flexDirection: [null, null, 'row-reverse'],
        height: [null, null, 'calc(100vh - 128px)']
      }}>
      <Box
        sx={{
          position: 'relative',
          height: [300, null, '100%'],
          flex: [null, null, '1 1 auto']
        }}>
        <GoogleMap
          sx={{ size: '100%' }}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          {clusters.map((cluster, key) => (
            <Location
              key={key}
              {...cluster}
              lat={cluster.geometry.coordinates[1]}
              lng={cluster.geometry.coordinates[0]}
              activeResult={activeResult}
              setActiveResult={handleActiveResult}
            />
          ))}
        </GoogleMap>
      </Box>
      {showSidebar && (
        <InfiniteScroll useWindow={false} false onBottom={handleBottom}>
          <Box
            sx={{
              maxWidth: [null, null, 420],
              flex: [null, null, 'none'],
              height: [null, null, '100%'],
              position: 'relative'
            }}>
            {results.map(({ id, src, revision }) => (
              <ImageSearch
                isActive={id === activeResult}
                key={id}
                id={id}
                image={{ url: src }}
                {...revision}
              />
            ))}
          </Box>
        </InfiniteScroll>
      )}
    </Box>
  );
};

ResultMap.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      revision: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
      })
    })
  ).isRequired,
  showSidebar: PropTypes.bool,
  handleBottom: PropTypes.func
};

export default ResultMap;
export { CLUSTER_CONFIG };
