import gql from 'graphql-tag';

const userNotification = gql`
  fragment UserNotification on Notification {
    id
    message
    link
    seen
    image_src
    trigger {
      id
      profile_src
    }
  }
`;

export { userNotification };
