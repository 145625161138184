import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link as RouterLink } from 'react-router-dom';
import gql from 'graphql-tag';
import dayjs from 'dayjs';

import {
  CollectionAdd,
  Heading3,
  Link,
  Modal,
  ModalBody,
  ModalHeader,
  OverScroll,
  Text2Regular
} from '@tso/tso-components';

import { GET_PUBLIC_COLLECTIONS } from '../Profile/UserCollections';
import { userCollection } from '../../fragments/index';
import { useAlert, useAuth } from '../../hooks/index';
import { paginate } from '../../actions/index';
import InfiniteScroll from '../InfiniteScroll';
import AuthActionButton from '../AuthActionButton';

const ADD_TO_COLLECTION = gql`
  mutation addPhotosToCollection($collection_id: Int!, $image_ids: [Int!]!) {
    addPhotosToCollection(
      collection_id: $collection_id
      image_ids: $image_ids
    ) {
      ...UserCollection
    }
  }
  ${userCollection}
`;

const Collections = ({ handleClick }) => {
  const { user } = useAuth();
  const { loading, error, data, fetchMore } = useQuery(GET_PUBLIC_COLLECTIONS, {
    variables: { id: user.id },
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  const handlePagination = () => {
    const { currentPage, hasNextPage } = data.userCollections.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id: user.id, page: currentPage + 1 },
      updateQuery: paginate('userCollections')
    });
  };

  if (data.userCollections.total === 0) {
    return <p>You have no collections, why not create one?</p>;
  }

  return (
    <InfiniteScroll
      style={{ height: '300px' }}
      useWindow={false}
      onBottom={handlePagination}>
      {data.userCollections.data.map(({ id, created_at, name, header }) => (
        <CollectionAdd
          id={id}
          key={id}
          {...(header && { image: { url: header.src } })}
          name={name}
          description={`Created on: ${dayjs(created_at).format('D/MM/YYYY')}`}
          onClick={handleClick}
        />
      ))}
    </InfiniteScroll>
  );
};

Collections.propTypes = {
  handleClick: PropTypes.func.isRequired
};

const AddToCollection = ({ imageId, Submit = AuthActionButton }) => {
  const [open, setOpen] = useState(false);

  const [addPhotos] = useMutation(ADD_TO_COLLECTION);
  const { showSuccess, showWarning } = useAlert();

  useEffect(() => localStorage.setItem('selectedImage', imageId), [imageId]);

  const toggleModal = () => setOpen(open => !open);

  const handleClick = async id => {
    try {
      await addPhotos({
        variables: { collection_id: id, image_ids: [imageId] }
      });
      showSuccess(`Successfully added photo to collection`);
    } catch (error) {
      showWarning(
        'Sorry something went wrong. Did you try and add an image to a collection twice?'
      );
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Modal isOpen={open} toggle={toggleModal} size="small">
        <ModalHeader size="small" toggle={toggleModal}>
          <Heading3>Add to an existing collection</Heading3>
          <Text2Regular>
            Or{' '}
            <Link as={RouterLink} to="/collections/create">
              create new
            </Link>
          </Text2Regular>
        </ModalHeader>
        <ModalBody sx={{ py: 0 }}>
          <OverScroll sx={{ mx: -3, mb: -3 }} height={380}>
            <Collections handleClick={handleClick} />
          </OverScroll>
        </ModalBody>
      </Modal>
      <Submit icon="add" onClick={toggleModal} label="Add to collection">
        Add to collection
      </Submit>
    </>
  );
};

AddToCollection.propTypes = {
  imageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  Submit: PropTypes.any
};

export default AddToCollection;
export { ADD_TO_COLLECTION };
