import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQuery } from '@apollo/react-hooks';
import { Pagination, Img, Checkbox, Label, Button } from '@tso/tso-components';

import { SortToggle, Tableheading as Heading, TableCell } from '../index';
import { useMultiSelect, useImageParams } from '../../hooks/index';
import { AdminDeleteImage, AdminPage } from '../../Components/index';
import { imageDetail } from '../../fragments/index';

const GET_ALL_IMAGES = gql`
  query allImages($page: Int, $sort: Sort, $start: Date, $end: Date) {
    allImages(page: $page, sort: $sort, start: $start, end: $end) {
      data {
        ...ImageDetail
        created_at
        user {
          id
          name
        }
      }
      total
      page {
        currentPage
        lastPage
        perPage
        hasNextPage
      }
    }
  }
  ${imageDetail}
`;

const preventKeyDown = event => event.preventDefault();

const Images = () => {
  const [sort, setSort] = useState();
  const { page, setPage, start, setStart, end, setEnd } = useImageParams();

  const { loading, error, data } = useQuery(GET_ALL_IMAGES, {
    fetchPolicy: 'network-only',
    variables: {
      page,
      sort,
      start: dayjs(start).format('YYYY-MM-DD HH:mm:ss.SSS'),
      end: dayjs(end).format('YYYY-MM-DD HH:mm:ss.SSS')
    }
  });

  const {
    selected,
    handleSelect,
    handleAllSelected,
    allSelected
  } = useMultiSelect(data?.allImages?.data);

  const handlePagination = page => setPage(parseInt(page) - 1);

  if (loading || error) {
    return null;
  }

  const { currentPage, lastPage } = data.allImages.page;

  return (
    <AdminPage title="Images">
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <label style={{ marginRight: 10 }}>from</label>
        <input
          type="date"
          value={start}
          onChange={e => setStart(e.target.value)}
          onKeyDown={preventKeyDown}
        />
        <label style={{ marginLeft: 20, marginRight: 10 }}>to</label>
        <input
          type="date"
          value={end}
          onChange={e => setEnd(e.target.value)}
          onKeyDown={preventKeyDown}
        />
      </div>
      <table style={{ width: '100%', minWidth: 700 }}>
        <thead>
          <tr>
            <Heading style={{ width: '7rem' }}>
              <div style={{ display: 'inline-flex' }}>
                <div>
                  <Label variant="checkbox">
                    <Checkbox
                      name="select-all-images"
                      checked={allSelected}
                      onChange={handleAllSelected}
                    />
                  </Label>
                </div>
                {selected.length > 0 && <AdminDeleteImage ids={selected} />}
              </div>
            </Heading>
            <Heading>Preview</Heading>
            <Heading>Title</Heading>
            <Heading>Description</Heading>
            <Heading>
              <span>Created At</span>
              <SortToggle
                sort={{
                  current: sort,
                  up: 'OLDEST_CREATED',
                  down: 'NEWEST_CREATED'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>
              User
              <SortToggle
                sort={{
                  current: sort,
                  up: 'USER_ASC',
                  down: 'USER_DESC'
                }}
                handleSort={setSort}
              />
            </Heading>
            <Heading>Actions</Heading>
          </tr>
        </thead>
        <tbody>
          {data.allImages.data.map(
            ({ id, src, revision, user, created_at }, index) => (
              <tr key={index}>
                <TableCell>
                  <Label variant="checkbox">
                    <Checkbox
                      checked={selected.includes(id)}
                      onChange={() => handleSelect(id)}
                      aria-label="selectImage"
                    />
                  </Label>
                </TableCell>
                <TableCell style={{ overflowY: 'hidden' }}>
                  <div style={{ margin: '0 0.5rem', minWidth: '10rem' }}>
                    <Img
                      src={src}
                      alt={revision.name}
                      fit="contain"
                      style={{ width: '10rem' }}
                      orientation={revision.orientation}
                    />
                  </div>
                </TableCell>
                <TableCell>{revision.name}</TableCell>
                <TableCell>{revision.description}</TableCell>
                <TableCell>{dayjs(created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  <Link to={`/users/${user.id}`}>{user.name}</Link>
                </TableCell>
                <TableCell>
                  <Button
                    size={'small'}
                    appearance={'link'}
                    as={Link}
                    to={`/images/${id}`}>
                    View
                  </Button>
                  <Button
                    size={'small'}
                    appearance={'link'}
                    as={Link}
                    to={`/admin/images/${id}/comments`}>
                    Comments
                  </Button>
                  <AdminDeleteImage ids={[id]} />
                </TableCell>
              </tr>
            )
          )}
        </tbody>
        <Pagination
          paginate={{
            currentPage: currentPage + 1,
            totalPages: lastPage + 1
          }}
          handlePageChange={handlePagination}
        />
      </table>
    </AdminPage>
  );
};

export default Images;
