import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { Box } from '@theme-ui/components';

import {
  Button,
  Checkbox,
  Feedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea
} from '@tso/tso-components';

import { length } from '../../validation/index';

const CreateCollectionModal = ({ initalOpen = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    isPrivate: true
  });
  const [open, setOpen] = useState(initalOpen);
  const [errorMessage, setErrorMessage] = useState('');
  const [next, setNext] = useState(false);

  const history = useHistory();

  const { isPrivate, name } = formData;

  const handleTitleChange = event => {
    setFormData({ ...formData, name: event.target.value });
    setErrorMessage('');
  };

  const handleDescriptionChange = event =>
    setFormData({ ...formData, description: event.target.value });
  const handlePrivacyChange = () =>
    setFormData({ ...formData, isPrivate: !formData.isPrivate });

  const handleSubmit = () => {
    const { valid, error } = length(name, {
      min: 2,
      max: 100,
      type: 'Name'
    });

    if (!valid) {
      return setErrorMessage(error);
    }

    name.trim() && setNext(true);
  };

  const toggleOpen = () => {
    if (initalOpen) {
      return history.goBack();
    }

    setOpen(open => !open);
  };

  const handleCancel = () => {
    if (initalOpen) {
      return history.goBack();
    }

    setOpen(false);
  };

  return (
    <Box>
      {next && (
        <Redirect
          push
          to={{
            pathname: '/collections/photos',
            state: formData
          }}
        />
      )}
      <Modal isOpen={open} toggle={toggleOpen}>
        <ModalHeader toggle={toggleOpen}>Create a collection</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              name="name"
              aria-label="name"
              placeholder="Title *"
              onChange={handleTitleChange}
            />
            {errorMessage && (
              <Feedback status="warning" mt={2}>
                {errorMessage}
              </Feedback>
            )}
          </FormGroup>

          <FormGroup>
            <Textarea
              name="description"
              aria-label="description"
              placeholder="Description"
              onChange={handleDescriptionChange}
            />
          </FormGroup>

          <Box>
            <Label variant="checkbox">
              <Checkbox
                defaultChecked={isPrivate}
                onChange={handlePrivacyChange}
                aria-label="policyCheckbox"
              />
              Keep this collection private
            </Label>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSubmit()} block>
            Add photos
          </Button>

          <Button appearance="link" onClick={handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {!initalOpen && <Button onClick={toggleOpen}>Create a collection</Button>}
    </Box>
  );
};

CreateCollectionModal.propTypes = {
  initalOpen: PropTypes.bool
};

export default CreateCollectionModal;
