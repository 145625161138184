import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';

import { GET_COLLECTION } from './Collection';
import { SelectFromImages } from '../../Components/index';
import { userCollection } from '../../fragments/index';
import { useAlert } from '../../hooks/index';

const CHANGE_COLLECTION_HEADER = gql`
  mutation changeCollectionHeader($id: Int!, $header_id: Int!) {
    changeCollectionHeader(id: $id, header_id: $header_id) {
      ...UserCollection
    }
  }
  ${userCollection}
`;

const CollectionHeader = ({ match }) => {
  const [selected, setSelected] = useState([]);

  const { showSuccess } = useAlert();

  const history = useHistory();
  const { loading, error, data } = useQuery(GET_COLLECTION, {
    variables: { id: parseInt(match.params.id) },
    fetchPolicy: 'network-only'
  });
  const [updateHeader] = useMutation(CHANGE_COLLECTION_HEADER, {
    refetchQueries: ['collections', 'privateCollections', 'userCollections']
  });

  const handleImageSelect = id => () => {
    if (id === selected[0]) {
      return setSelected([]);
    }

    setSelected([id]);
  };

  const handleBottom = () => {};

  const handleSubmit = async () => {
    await updateHeader({
      variables: { id: parseInt(match.params.id), header_id: selected[0] }
    });
    showSuccess('Successfully changed header photo');
    history.push(`/collections/${match.params.id}`);
  };

  if (loading || error) {
    return null;
  }

  return (
    <SelectFromImages
      images={data.collection.images.data}
      selected={selected}
      handleSubmit={handleSubmit}
      handleBottom={handleBottom}
      handleImageSelect={handleImageSelect}
    />
  );
};

CollectionHeader.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired
    }).isRequired
  }).isRequired
};

export default CollectionHeader;
