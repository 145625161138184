import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Box, Flex } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Avatar,
  Container,
  GoogleMapProvider,
  Heading1,
  Image,
  ImageGrid,
  Img,
  Link,
  TabLink,
  Text,
  Text1Regular,
  Text3Medium,
  User,
  ActionButton
} from '@tso/tso-components';

import { imageResult, detailedCollection } from '../../fragments/index';
import {
  OtherCollections,
  ResultMap,
  EditCollection,
  BackArrow,
  FollowButton,
  UnfollowButton,
  Share,
  ImageActions,
  InfiniteScroll
} from '../../Components/index';
import { useAuth } from '../../hooks/index';
import { description } from '../../validation/index';

const GET_COLLECTION = gql`
  query collection($id: Int!, $page: Int) {
    collection(id: $id) {
      ...DetailedCollection
      images(page: $page) {
        data {
          ...ImageResult
        }
        total
        page {
          currentPage
          lastPage
          perPage
          hasNextPage
        }
      }
    }
  }
  ${imageResult}
  ${detailedCollection}
`;

const Collection = props => {
  const [view, setView] = useState('GRID');
  const { user: loggedInUser, isLoggedIn } = useAuth();
  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTION, {
    variables: { id: parseInt(props.match.params.id), page: 0 },
    fetchPolicy: 'network-only'
  });

  const handleViewChange = view => setView(view);

  if (loading || error) {
    return null;
  }

  const {
    collection: {
      id,
      name,
      description: collectionDesc,
      user,
      header,
      images,
      is_private,
      following
    }
  } = data;

  const canFollow = isLoggedIn && loggedInUser.id !== user.id;

  const handlePagination = () => {
    const { currentPage, hasNextPage } = images.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id: parseInt(props.match.params.id), page: currentPage + 1 },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          collection: {
            ...prev.collection,
            images: {
              ...prev.collection.images,
              ...fetchMoreResult.collection.images,
              data: [
                ...prev.collection.images.data,
                ...fetchMoreResult.collection.images.data
              ]
            }
          }
        };
      }
    });
  };

  return (
    <>
      <Box pt={[2, 4]}>
        <Container variant="xxl">
          <Box
            sx={{
              display: [null, 'flex'],
              alignItems: [null, 'flex-start'],
              position: 'relative',
              pb: 3,
              borderBottom: 1,
              borderBottomColor: 'gray.4'
            }}>
            <BackArrow
              sx={{
                position: ['absolute', 'static'],
                top: 2,
                left: 2,
                zIndex: 1,
                flex: 'none',
                mr: [null, 3]
              }}
            />

            <Box
              sx={{
                bg: 'gray.4',
                mb: 2,
                order: [null, 1],
                width: [null, 250, 480],
                flex: [null, 'none'],
                overflow: 'hidden'
              }}>
              {header && (
                <Img
                  src={header.high_quality_src}
                  alt={name}
                  fit="contain"
                  orientation={header.revision.orientation}
                />
              )}
            </Box>

            <Box
              sx={{
                order: [null, 0],
                flex: [null, '1 1 auto'],
                mr: [null, 3]
              }}>
              <Box>
                <Heading1>{name}</Heading1>
                <User
                  id={user.id}
                  name={user.name}
                  image={{ url: user.profile_src }}
                  size={40}
                  mb={3}
                />
                <Text mb={2}>{collectionDesc}</Text>

                <Text1Regular mb={3} color="gray.2">
                  {images.total} photos
                </Text1Regular>

                {canFollow && following && <UnfollowButton id={id} />}
                {canFollow && !following && <FollowButton id={id} />}
              </Box>
            </Box>
          </Box>

          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: 'gray.4'
            }}>
            <Box>
              <Share
                shareData={{
                  name,
                  collectionDesc,
                  image: header && header.high_quality_src,
                  url: window.location.href
                }}
              />
              {loggedInUser.id === user.id && (
                <>
                  <EditCollection
                    id={parseInt(props.match.params.id)}
                    name={name}
                    description={collectionDesc}
                    is_private={is_private}
                    header={header}
                  />
                  <ActionButton
                    style={{ textDecoration: 'none' }}
                    as={RouterLink}
                    to={`/collections/${id}/photos`}
                    icon="add"
                    label="addImages"
                    aria-label="Add Images">
                    Add images
                  </ActionButton>
                </>
              )}
            </Box>

            {images.data.length > 0 && (
              <Box>
                <TabLink
                  onClick={() => handleViewChange('GRID')}
                  active={view === 'GRID'}>
                  Grid View
                </TabLink>
                <TabLink
                  onClick={() => handleViewChange('MAP')}
                  active={view === 'MAP'}>
                  Map View
                </TabLink>
              </Box>
            )}
          </Flex>
        </Container>
      </Box>

      {images.data.length > 0 && (
        <>
          {view === 'GRID' && (
            <InfiniteScroll onBottom={handlePagination}>
              <ImageGrid py={3}>
                {images.data.map(({ id, src, revision, ...rest }) => (
                  <Image
                    Actions={props => (
                      <ImageActions collection={data.collection} {...props} />
                    )}
                    key={id}
                    id={id}
                    name={revision.name}
                    description={description(revision)}
                    orientation={revision.orientation}
                    image={{ url: src }}
                    sx={{ mb: 2 }}
                    {...rest}
                  />
                ))}
              </ImageGrid>
            </InfiniteScroll>
          )}
          {view === 'MAP' && (
            <GoogleMapProvider
              initalCenter={{
                lat: images.data[0].revision.latitude,
                lng: images.data[0].revision.longitude
              }}
              intialZoom={12}>
              <ResultMap
                showSidebar
                handleBottom={handlePagination}
                results={images.data.filter(
                  ({ revision }) => revision.latitude && revision.longitude
                )}
              />
            </GoogleMapProvider>
          )}
        </>
      )}
      <Box py={3}>
        <Container variant="xxl">
          <Box sx={{ mx: 'auto', textAlign: 'center' }}>
            <Avatar
              id={user.id}
              name={user.name}
              image={{ url: user.profile_src }}
              size={80}
              mb={2}
              mx="auto"
            />
            <Text3Medium>
              <Link as={RouterLink} to={`/users/${user.id}`} variant="plain">
                {user.name}
              </Link>
            </Text3Medium>
          </Box>
        </Container>
      </Box>
      <OtherCollections />
    </>
  );
};

Collection.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  })
};

export default Collection;
export { GET_COLLECTION };
