"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var fonts = {
  body: '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  heading: 'inherit',
  monospace: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
};
var _default = fonts;
exports["default"] = _default;