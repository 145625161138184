"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var baseModal = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  pointerEvents: 'auto',
  backgroundClip: 'padding-box',
  outline: 0,
  my: [null, 3],
  mx: 'auto',
  minHeight: ['100vh', 'auto'],
  bg: 'background',
  color: 'text',
  zIndex: 1,
  boxShadow: [null, 'default']
};
var modals = {
  small: _objectSpread({}, baseModal, {
    px: 3,
    pb: 3,
    maxWidth: [null, 'modal.small']
  }),
  medium: _objectSpread({}, baseModal, {
    maxWidth: [null, 'modal.medium'],
    px: [3, 5],
    pb: [3, 4]
  }),
  header: {
    small: {
      mx: -3,
      pt: 3,
      pr: 64,
      pb: 3,
      pl: 3,
      borderBottom: 1,
      borderBottomColor: 'gray.4'
    },
    medium: {
      pt: [44, 4],
      py: 3,
      textAlign: 'center'
    }
  },
  footer: {
    horizontal: {
      justifyContent: 'space-between'
    },
    vertical: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '1040',
    width: '100vw',
    height: '100vh',
    bg: 'text',
    opacity: 0.75
  }
};
var _default = modals;
exports["default"] = _default;