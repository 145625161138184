import { useEffect } from 'react';

import { useHistory } from '../hooks/index';

const AuthRedirect = () => {
  const { authRedirect } = useHistory();

  useEffect(() => {
    authRedirect();
  }, [authRedirect]);

  return null;
};

export default AuthRedirect;
