import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Button,
  Heading,
  Container,
  Dialog,
  Link1Regular,
  Text,
  Tip,
  Field,
  Textarea,
  Heading1,
  ImageRotate,
  Heading2,
  Heading4
} from '@tso/tso-components';

import { WhitelistedCategories, GoogleSearch, DatePicker } from '../index';
import { getAddress } from '../Address';
import { imageEdit } from '../../fragments/index';
import { useDate } from '../../hooks/index';

const EDIT_IMAGE = gql`
  mutation editImage(
    $id: Int!
    $category_id: Int
    $place_id: String
    $name: String!
    $credit: String
    $description: String
    $day: String
    $month: String
    $year: String
    $year_start: String
    $year_end: String
    $orientation: Int
  ) {
    editImage(
      id: $id
      category_id: $category_id
      place_id: $place_id
      name: $name
      credit: $credit
      description: $description
      day: $day
      month: $month
      year: $year
      year_start: $year_start
      year_end: $year_end
      orientation: $orientation
    ) {
      ...ImageEdit
    }
  }
  ${imageEdit}
`;

const EditImage = ({ revision, onEdit, ...image }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [orientation, setOrientation] = useState(revision.orientation || 0);
  const [category, setCategory] = useState(null);
  const [location, setLocation] = useState(getAddress(revision).join(', '));
  const [showTip, setShowTip] = useState(true);
  const [showDialog, setShowDialog] = useState(
    !localStorage.getItem('hide_upload_help')
  );

  const locationRef = useRef();

  const { date, builtDate, setDate, givenFullDate, givenYearRange } = useDate(
    revision
  );

  const [editImage] = useMutation(EDIT_IMAGE, {
    onCompleted: onEdit,
    refetchQueries: ['userImages', 'images']
  });

  const handleSubmit = event => {
    event.preventDefault();

    editImage({
      variables: {
        id: image.id,
        name,
        description,
        orientation,
        category_id: category || null,
        place_id: location ? location.value : null,
        ...builtDate
      }
    });
  };

  const clearLocation = () => {
    setLocation('');
    locationRef.current.focus();
    setShowTip(false);
  };

  const handleNameChange = event => setName(event.target.value);

  const handleDescriptionChange = event => setDescription(event.target.value);

  const handleCategoryChange = ({ value }) => setCategory(value);

  const handleLocationChange = value => setLocation(value);

  const handleOrientationChange = value => setOrientation(value);

  const hasLocation = Boolean(revision.latitude && revision.longitude);

  const validDate = Boolean(givenFullDate || givenYearRange);

  const valid = Boolean(
    name.length > 5 && description.length > 5 && (validDate || location?.label)
  );

  return (
    <Box variant="layout.baz">
      <Container>
        <Box variant="layout.form">
          <Heading1>Tell us everything you know about this photo.</Heading1>

          <Text mb={3}>
            Add as much information to your photo as possible so other users
            know where and when it was taken.
          </Text>

          <Box mb={4}>
            <ImageRotate
              {...image}
              orientation={orientation}
              setOrientation={handleOrientationChange}
            />
          </Box>

          <Box mb={5}>
            <Field
              name="title"
              placeholder="Give your photo a title *"
              label="Title"
              hideLabel
              value={name}
              onChange={handleNameChange}
              mb={3}
            />

            <Field
              name="description"
              placeholder="Add a description *"
              label="Description"
              as={Textarea}
              value={description}
              onChange={handleDescriptionChange}
              hideLabel
              mb={3}
            />

            <WhitelistedCategories
              defaultText="No Category"
              value={category}
              handleChange={handleCategoryChange}
            />
          </Box>

          <Box mb={4}>
            <Heading2>Add more details to your photo:</Heading2>
            <Heading4>Please add either a date or a location</Heading4>

            {showDialog && hasLocation && (
              <Dialog
                name="It looks like we may have identified when and/or where this photo was taken."
                close={() => setShowDialog(false)}>
                <Text mb={3} color="gray.1">
                  Make sure you’re happy with our suggestion. If you don&apos;t
                  agree, you can correct anything we&apos;ve added.
                </Text>
                <Link1Regular
                  as="button"
                  onClick={() => {
                    localStorage.setItem('hide_upload_help', true);
                    setShowDialog(false);
                  }}>
                  Don&apos;t show again
                </Link1Regular>
              </Dialog>
            )}
          </Box>
          <Box mb={4}>
            <Heading as="h4" variant="h4">
              Where was this photo taken?
            </Heading>
            <GoogleSearch
              ref={locationRef}
              defaultInputValue={location}
              onChange={handleLocationChange}
            />
            {hasLocation && showTip && (
              <Tip
                name="We have identified a location. Does this seem right?"
                yes={() => setShowTip(false)}
                no={() => clearLocation()}
                sx={{ mt: 2 }}
              />
            )}
          </Box>

          <Box mb={4}>
            <Heading as="h4" variant="h4">
              When was this photo taken?
            </Heading>
            <DatePicker date={date} setDate={setDate} />
          </Box>

          <Button isDisabled={!valid} type="submit" onClick={handleSubmit}>
            Update image
          </Button>
          <Button as={RouterLink} to={`/images/${image.id}`} appearance="link">
            Skip
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

EditImage.propTypes = {
  revision: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    year: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string,
    year_start: PropTypes.string,
    year_end: PropTypes.string,
    street_address_1: PropTypes.string,
    orientation: PropTypes.number
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onEdit: PropTypes.func.isRequired
};

export default EditImage;
export { EDIT_IMAGE };
