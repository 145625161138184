import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

const SCROLL_OFFSET = 50;

const DivInfiniteScroll = ({ onBottom, children, style = {} }) => {
  const ref = useRef();
  const [fetchingResults, setFetchingResults] = useState(false);

  const handleScroll = useCallback(async () => {
    if (fetchingResults) {
      return;
    }

    const bottom = ref.current.scrollHeight - ref.current.scrollTop;
    const height = ref.current.clientHeight + SCROLL_OFFSET;

    // If we are at the bottom of the panel, fetch more results.
    if (bottom <= height) {
      setFetchingResults(true);
      await onBottom();
      setFetchingResults(false);
    }
  }, [fetchingResults, onBottom]);

  useEffect(() => {
    const div = ref.current;

    div.addEventListener('scroll', handleScroll);
    return () => {
      div.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div style={{ overflowY: 'auto', ...style }} ref={ref}>
      {children}
    </div>
  );
};

DivInfiniteScroll.propTypes = {
  children: PropTypes.any.isRequired,
  onBottom: PropTypes.func.isRequired,
  style: PropTypes.object
};

const WindowInfiniteScroll = ({ onBottom, children }) => {
  const ref = useRef();
  const [fetchingResults, setFetchingResults] = useState(false);

  const handleScroll = useCallback(async () => {
    if (fetchingResults) {
      return;
    }

    // If we are at the bottom of the panel, fetch more results.
    if (
      ref.current.getBoundingClientRect().bottom <=
      window.innerHeight + SCROLL_OFFSET
    ) {
      setFetchingResults(true);
      await onBottom();
      setFetchingResults(false);
    }
  }, [fetchingResults, onBottom]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return <div ref={ref}>{children}</div>;
};

WindowInfiniteScroll.propTypes = {
  children: PropTypes.any.isRequired,
  onBottom: PropTypes.func.isRequired
};

const InfiniteScroll = ({ useWindow = true, ...rest }) => {
  // When the user scrolls to the bottom of the window, fire handler.
  if (useWindow) {
    return <WindowInfiniteScroll {...rest} />;
  }

  // When the user scrolls to the bottom of the children, fire handler.
  return <DivInfiniteScroll {...rest} />;
};

InfiniteScroll.propTypes = {
  useWindow: PropTypes.bool
};

export default InfiniteScroll;
export { SCROLL_OFFSET };
