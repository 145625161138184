import React, { useState, useRef } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import { Box, Flex } from '@theme-ui/components';

import {
  ActionButton,
  CommentLogin,
  Comments,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Heading2,
  Img,
  List,
  Text,
  Text0Bold,
  Text0Light,
  Text1Bold,
  Text2Regular,
  Text3Medium,
  User
} from '@tso/tso-components';

import { useAuth, useLikes, useAlert } from '../../hooks/index';
import {
  RelatedImages,
  AddComment,
  ReportImage,
  DeleteImage,
  AddToCollection,
  EditImageModal,
  Share,
  Address,
  BackArrow,
  CommentActions,
  FeaturedIn,
  ImageDate,
  AuthListItem,
  Meta,
  ViewOnMap
} from '../../Components/index';
import {
  detailedLike,
  imageDetail,
  imagePreview,
  loggedInUser,
  userCollection
} from '../../fragments/index';

const GET_IMAGE = gql`
  query image($id: Int!, $page: Int) {
    image(id: $id) {
      ...ImageDetail
      categories {
        name
      }
      comments(page: $page) {
        data {
          user {
            ...LoggedInUser
          }
          likes {
            ...DetailedLike
          }
          dislikes {
            ...DetailedLike
          }
          id
          comment
          created_at
        }
        total
        page {
          currentPage
          lastPage
          perPage
          hasNextPage
        }
      }
      relatedImages {
        data {
          ...ImagePreview
        }
        total
        page {
          currentPage
          lastPage
          perPage
          hasNextPage
        }
      }
      collections {
        data {
          ...UserCollection
        }
        total
      }
    }
  }
  ${detailedLike}
  ${imageDetail}
  ${imagePreview}
  ${loggedInUser}
  ${userCollection}
`;

const Image = props => {
  const [openDropdown, setOpenDropdown] = useState();

  const commentRef = useRef();

  const { isLoggedIn, isGuest, user: loggedInUser } = useAuth();
  const { showWarning } = useAlert();
  const { like, dislike } = useLikes(props.match.params.id);

  const { loading, error, data, fetchMore, refetch } = useQuery(GET_IMAGE, {
    variables: { id: parseInt(props.match.params.id) },
    fetchPolicy: 'network-only'
  });

  const history = useHistory();

  const toggleDropdown = () => setOpenDropdown(open => !open);

  const handleCommentsLoad = event => {
    event.preventDefault();

    const { currentPage, hasNextPage } = data.image.comments.page;

    if (!hasNextPage) {
      return;
    }

    return fetchMore({
      variables: { id: parseInt(props.match.params.id), page: currentPage + 1 },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          image: {
            ...prev.image,
            comments: {
              ...prev.image.comments,
              ...fetchMoreResult.image.comments,
              data: [
                ...prev.image.comments.data,
                ...fetchMoreResult.image.comments.data
              ]
            }
          }
        };
      }
    });
  };

  const handleNewComment = () =>
    refetch({
      variables: { id: parseInt(props.match.params.id), page: 0 }
    });

  const handleLike = ({ type, id }) => () => {
    if (!isLoggedIn) {
      showWarning('Please log in to like comments');
      return history.push('/login');
    }

    if (type === 'positive') {
      return like(id);
    }

    return dislike(id);
  };

  const snapToComment = () => {
    if (commentRef.current) {
      commentRef.current.scrollIntoView({ block: 'center' });
      commentRef.current.focus();
    }
  };

  if (loading || error) {
    return null;
  }

  const {
    id,
    high_quality_src: src,
    revision,
    revision: { latitude, longitude },
    user,
    categories,
    comments,
    is_unidentified
  } = data.image;

  const isTheUsersImage = user && loggedInUser.id === user.id;

  return (
    <>
      <Meta title={revision.name} />
      <Box as="article">
        <Box
          sx={{
            display: [null, null, null, 'flex'],
            position: 'relative',
            pb: [null, null, null, 60]
          }}>
          <Box
            sx={{
              position: 'relative',
              bg: 'text',
              minHeight: [null, null, null, 880],
              height: [220, 440, 'calc(100vh - 55px - 60px)'],
              flex: [null, null, null, '1 1 auto'],
              overflow: 'hidden'
            }}>
            <BackArrow
              sx={{
                zIndex: '5',
                position: 'absolute',
                top: [2, null, 3],
                left: [2, null, 3]
              }}
            />
            <Img
              src={src}
              alt={revision.name}
              fit="contain"
              orientation={revision.orientation}
            />
          </Box>

          <Flex
            sx={{
              justifyContent: ['space-between', 'center'],
              borderBottom: 1,
              borderTop: 1,
              height: 60,
              borderColor: 'gray.4',
              py: 2,
              px: 3,
              position: [null, null, null, 'absolute'],
              bottom: [null, null, null, 0],
              left: [null, null, null, 0],
              right: [null, null, null, 0]
            }}>
            <Share
              shareData={{
                name: revision.name,
                description: revision.description,
                url: window.location.href,
                image: src
              }}
            />
            <AddToCollection imageId={parseInt(id)} />
            <ViewOnMap location={{ latitude, longitude }} />
            {user && (
              <Dropdown isOpen={openDropdown} toggle={toggleDropdown}>
                <DropdownToggle
                  as={ActionButton}
                  mobile
                  icon="more"
                  label="More image options"
                  isActive={openDropdown}>
                  More
                </DropdownToggle>
                <DropdownMenu right direction="up">
                  <List>
                    {isTheUsersImage && (
                      <>
                        <DeleteImage id={parseInt(id)} />
                        <EditImageModal id={parseInt(id)} />
                      </>
                    )}
                    {!isTheUsersImage && (
                      <ReportImage Submit={AuthListItem} id={parseInt(id)} />
                    )}
                  </List>
                </DropdownMenu>
              </Dropdown>
            )}
          </Flex>

          <Box
            sx={{
              width: [null, null, null, 420],
              p: [3, null, null, 4],
              flex: 'none'
            }}>
            {revision.name && (
              <header>
                <Heading2 as="h1">{revision.name}</Heading2>
              </header>
            )}

            <Text2Regular color="gray.1" mb={2}>
              {revision.year}
            </Text2Regular>

            <Text mb={3}>{revision.description}</Text>

            {user && (
              <User
                id={user.id}
                name={user.name}
                image={{ url: user.profile_src }}
                mb={3}
              />
            )}

            <Box sx={{ borderTop: 1, borderTopColor: 'gray.4', pt: 3, mb: 5 }}>
              <Box mb={3}>
                <Text0Bold as="h3" mb={1}>
                  Location
                </Text0Bold>
                <Address onClick={snapToComment} {...revision} />
              </Box>

              <Box mb={3}>
                <Text0Bold as="h3" mb={1}>
                  Date
                </Text0Bold>
                <Text0Light>
                  <ImageDate onClick={snapToComment} {...revision} />
                </Text0Light>
              </Box>

              {categories.length !== 0 && (
                <Box mb={3}>
                  <Text0Bold as="h3" mb={1}>
                    Categories
                  </Text0Bold>
                  <Text0Light>
                    {[...new Set(categories.map(({ name }) => name))].join(
                      ', '
                    )}
                  </Text0Light>
                </Box>
              )}
              {revision.credit && (
                <Box mb={3}>
                  <Text0Light mt={3}>Copyright @{revision.credit}</Text0Light>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box py={3}>
          <Container variant="comments">
            <Text3Medium as="h3" mb={2}>
              What’s your story of this place?
            </Text3Medium>

            {isGuest && <CommentLogin />}

            {isLoggedIn && (
              <AddComment
                ref={commentRef}
                handleComplete={handleNewComment}
                imageId={parseInt(props.match.params.id)}
              />
            )}

            <Text1Bold mb={3}>
              {`${comments.total} ${
                is_unidentified ? 'contribution' : 'comment'
              }${comments.total > 1 ? 's' : ''}`}
            </Text1Bold>

            <Comments
              showMore={comments.page.hasNextPage}
              handleShowMore={handleCommentsLoad}
              handleLike={handleLike}
              comments={comments.data.map(
                ({ id, comment, likes, dislikes, user, created_at }) => ({
                  Actions: CommentActions,
                  id,
                  comment,
                  likes: likes.length,
                  dislikes: dislikes.length,
                  user: {
                    url: `/users/${user.id}`,
                    image: { url: user.profile_src },
                    ...user
                  },
                  created_at: dayjs(created_at).format('D MMM YYYY')
                })
              )}
            />
          </Container>
        </Box>

        {data.image.relatedImages.total > 0 && (
          <RelatedImages images={data.image.relatedImages.data} />
        )}

        {data.image.collections.total > 0 && (
          <FeaturedIn collections={data.image.collections.data} />
        )}
      </Box>
    </>
  );
};

Image.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  })
};

export default Image;
export { GET_IMAGE };
