"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _color = require("@theme-ui/color");

var forms = {
  label: {
    "default": {
      fontSize: 2,
      fontWeight: 'bold',
      lineHeight: 'body'
    },
    checkbox: {
      fontSize: 1,
      lineHeight: '1.428571429'
    },
    radio: {
      fontSize: 1,
      lineHeight: '1.428571429'
    }
  },
  input: {
    "default": {
      borderColor: 'gray.2',
      '&:hover': {
        borderColor: 'gray.0'
      },
      '&:focus': {
        borderColor: 'text'
      }
    },
    valid: {
      borderColor: 'success'
    },
    invalid: {
      borderColor: 'warning'
    }
  },
  textarea: {
    "default": {
      borderColor: 'gray.2',
      '&:hover': {
        borderColor: 'gray.0'
      },
      '&:focus': {
        borderColor: 'text'
      }
    },
    valid: {
      borderColor: 'success'
    },
    invalid: {
      borderColor: 'warning'
    }
  },
  select: {
    valid: {
      borderColor: 'success'
    },
    invalid: {
      borderColor: 'warning'
    }
  },
  checkbox: {
    invalid: {
      borderColor: 'warning'
    }
  },
  radio: {
    invalid: {
      borderColor: 'warning'
    }
  },
  imageUpload: {
    foo: {
      border: function border(theme) {
        return "1px dashed ".concat(theme.colors.gray[3]);
      }
    },
    bar: {
      bg: 'gray.3'
    }
  },
  formFeedback: {
    warning: {
      bg: (0, _color.alpha)('warning', 0.5),
      color: 'text'
    },
    positive: {
      bg: (0, _color.alpha)('success', 0.3),
      color: 'text'
    }
  }
};
var _default = forms;
exports["default"] = _default;