import React from 'react';
import { BaseStyles } from 'theme-ui';
import { Meta } from '../index';

const AdminPage = ({ title, children, actions }) => (
  <div
    style={{
      margin: '0 auto',
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      maxWidth: 1366
    }}>
    <Meta title={title} />
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <h1>{title}</h1>
      {actions && actions}
    </div>
    <BaseStyles>
      <div
        style={{
          overflowX: 'scroll',
          maxWidth: 'calc(100vw - 2rem)'
        }}>
        {children}
      </div>
    </BaseStyles>
  </div>
);

export default AdminPage;
