import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getPage = location => {
  if (!location.search) {
    return 0;
  }

  return parseInt(location.search.split('=')[1]) - 1;
};

const usePage = () => {
  const location = useLocation();
  const [page, setPage] = useState(getPage(location));

  /**
   * Whenever the user selects a page, sync it with the URL.
   */
  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?page=${page + 1}`;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [page]);

  /**
   * Whenever the user goes back or forward using the browser buttons,
   * sync the URL with the page.
   */
  useEffect(() => {
    setPage(getPage(location));
  }, [location]);

  return [page, setPage];
};

export default usePage;
