import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@tso/tso-components';

import { useAlert } from '../../hooks/index';

const REMOVE_ADMIN_ROLE = gql`
  mutation removeAdminRole($id: Int!) {
    removeAdminRole(id: $id) {
      id
    }
  }
`;

const DemoteUser = ({ id, ...rest }) => {
  const { showSuccess } = useAlert();

  const [removeAdminRole] = useMutation(REMOVE_ADMIN_ROLE, {
    refetchQueries: ['getUsers']
  });

  const handleDemote = async () => {
    const response = await removeAdminRole({ variables: { id } });

    if (response.data.removeAdminRole.id === id) {
      showSuccess('Successfully revoked user permissions');
    }
  };

  return (
    <Button
      size={'small'}
      onClick={handleDemote}
      appearance={'linkActive'}
      {...rest}>
      Revoke Admin
    </Button>
  );
};

export default DemoteUser;
