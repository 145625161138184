import {
  Home,
  Upload,
  Enrichment,
  Confirmation,
  Results,
  Image,
  Collections,
  Collection,
  SelectCollectionPhotos,
  SelectMoreCollectionPhotos,
  CreateCollection,
  CollectionHeader,
  Profile,
  MyProfile,
  Help,
  About,
  Requests,
  Request,
  Unidentified,
  Settings,
  Interests,
  CreateRequest,
  RequestCreateSuccess,
  Invite
} from '../Pages/App/index';

const routes = [
  { path: '/', component: Home },

  // Uploading an image
  { path: '/upload', component: Upload, auth: true },
  { path: '/upload/:id/edit', component: Enrichment, auth: true },
  { path: '/upload/:id/complete', component: Confirmation, auth: true },

  // Searching
  { path: '/results', component: Results },

  // Viewing an image
  { path: '/images/:id', component: Image },

  // Collections
  { path: '/collections', component: Collections },
  {
    path: '/collections/create',
    component: CreateCollection,
    auth: true
  },
  {
    path: '/collections/photos',
    component: SelectCollectionPhotos,
    auth: true
  },
  {
    path: '/collections/:id/photos',
    component: SelectMoreCollectionPhotos,
    auth: true
  },
  {
    path: '/collections/:id/header',
    component: CollectionHeader,
    auth: true
  },
  { path: '/collections/:id', component: Collection },

  // Requests
  { path: '/requests', component: Requests },
  { path: '/requests/create', component: CreateRequest, auth: true },
  {
    path: '/requests/create/:id/success',
    component: RequestCreateSuccess,
    auth: true
  },
  { path: '/requests/:id', component: Request },

  // Unidentified
  { path: '/unidentified', component: Unidentified },

  // User profile.
  { path: '/users/:id', component: Profile },

  // My profile
  { path: '/profile', component: MyProfile, auth: true },
  { path: '/settings', component: Settings, auth: true },
  { path: '/interests', component: Interests, auth: true },
  { path: '/invites', component: Invite, auth: true },

  // Textual Pages
  { path: '/help', component: Help },
  { path: '/about', component: About }
  // { path: '/landing', component: SoftLaunch }
];

export default routes;
