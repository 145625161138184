import React from 'react';
import PropTypes from 'prop-types';

import { GoogleMapProvider, GoogleMap, LocationPin } from '@tso/tso-components';

const MapPreview = ({ latitude: lat, longitude: lng, ...rest }) => (
  <GoogleMapProvider initalCenter={{ lat, lng }} intialZoom={15}>
    <GoogleMap apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} {...rest}>
      <LocationPin lat={lat} lng={lng} properties={{ id: 1 }} />
    </GoogleMap>
  </GoogleMapProvider>
);

MapPreview.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired
};

export default MapPreview;
