"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var dropdowns = {
  up: {},
  down: {},
  menu: {
    small: {
      minWidth: 'dropdown.menu.small.width'
    },
    medium: {
      minWidth: 'dropdown.menu.medium.width'
    },
    large: {
      minWidth: 'dropdown.menu.large.width'
    }
  }
};
var _default = dropdowns;
exports["default"] = _default;