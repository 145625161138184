import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Select } from '@tso/tso-components';

const WHITELISTED_CATEGORIES = gql`
  query whitelistedCategories {
    whitelistedCategories {
      id
      name
    }
  }
`;

const WhitelistedCategories = ({
  handleChange,
  value = null,
  defaultText = 'All Categories'
}) => {
  const { loading, error, data } = useQuery(WHITELISTED_CATEGORIES, {
    fetchPolicy: 'network-only'
  });

  if (loading || error) {
    return null;
  }

  const selected = data.whitelistedCategories.find(({ id }) => id === value);

  const allCategories = { label: defaultText, value: null };

  return (
    <Select
      placeholder="Misc"
      aria-label="categories"
      name="categories"
      id="category-select"
      value={
        selected
          ? { label: selected.name, value: selected.id }
          : { ...allCategories }
      }
      onChange={handleChange}
      options={[
        { ...allCategories },
        ...data.whitelistedCategories.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      ]}
    />
  );
};

WhitelistedCategories.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  defaultText: PropTypes.string
};

export default WhitelistedCategories;
export { WHITELISTED_CATEGORIES };
