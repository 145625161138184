import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_SEARCH = gql`
  {
    search @client {
      input
      global_input
      latitude
      longitude
      category_id
      year_start
      year_end
      category_id
      sort
      view
      distance
    }
  }
`;

const UPDATE_SEARCH = gql`
  mutation updateSearch(
    $input: String
    $global_input: String
    $latitude: Float
    $longitude: Float
    $year_start: String
    $year_end: String
    $category_id: Int
    $sort: String
    $view: String
    $distance: Distance
  ) {
    updateSearch(
      input: $input
      global_input: $global_input
      latitude: $latitude
      longitude: $longitude
      year_start: $year_start
      year_end: $year_end
      category_id: $category_id
      sort: $sort
      view: $view
      distance: $distance
    ) @client
  }
`;

const SEARCH_LOCATION = gql`
  mutation searchLocation($input: String, $latitude: Float, $longitude: Float) {
    searchLocation(input: $input, latitude: $latitude, longitude: $longitude)
      @client
  }
`;

const SEARCH_CATEGORY = gql`
  mutation searchCategory($category_id: Int) {
    searchCategory(category_id: $category_id) @client
  }
`;

const useSearch = () => {
  const { data = {} } = useQuery(GET_SEARCH);
  const [updateSearch] = useMutation(UPDATE_SEARCH);
  const [searchLocation] = useMutation(SEARCH_LOCATION);
  const [searchCategory] = useMutation(SEARCH_CATEGORY);

  const { input, global_input, ...rest } = data.search || {};

  return {
    params: { ...rest },
    input,
    global_input,
    updateSearch,
    searchLocation,
    searchCategory
  };
};

export default useSearch;
export { GET_SEARCH, UPDATE_SEARCH, SEARCH_CATEGORY };
