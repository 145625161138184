import React from 'react';
import { BaseStyles } from 'theme-ui';
import { Link } from 'react-router-dom';
import { Box } from '@theme-ui/components';

import {
  Heading2,
  TextPage,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Promo2,
  Button
} from '@tso/tso-components';

import { Meta } from '../../Components/index';

const Help = () => (
  <TextPage name="Help & Support">
    <Meta title="Help & Support" />
    <Box mb={4}>
      <Promo2
        name="Can’t find an answer to your question?"
        description="Get in touch with a member of the TSO team.">
        <Button as="a" href="mailto:thestoryof@archant.co.uk">
          Get in touch
        </Button>
      </Promo2>
    </Box>

    <Box mb={4}>
      <Heading2>Getting started</Heading2>
      <Accordion collapsible>
        <AccordionItem>
          <AccordionButton>What is The Story Of?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                The Story Of is a unique platform that allows any of us to
                wander through the amazing photos of places through time and to
                explore the stories behind these locations.
              </p>
              <p>On The Story Of, you can:</p>
              <ul>
                <li>
                  <p>
                    <Link to="/upload">Upload your own images:</Link> if you own
                    pictures of places in Norfolk, you can add them to the
                    platform and contribute to building the history of these
                    places.
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/collections">Create collections:</Link> curate
                    the story of a place that is important to you. Here you can
                    gather and arrange old photos, telling the story of that
                    location with the images and words that you bring together.
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/requests">Request:</Link> if you feel like a
                    place deserves to be covered on The Story Of but don&apos;t
                    have any pictures yourself, you can appeal to other users
                    for them to share the images they may have.
                  </p>
                </li>
                <li>
                  <p>
                    <Link to="/unidentified">Identify pictures:</Link> when
                    users upload a photo, they don’t necessarily know where
                    and/or when it was taken. You can help them identify these
                    images, listed in the ‘Unidentified’ section, and ensure
                    eventually all images are pinned to a location and time.
                  </p>
                </li>
              </ul>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            Does TSO have an app I can download?
          </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                There is currently no dedicated mobile app for The Story Of.
                However, you can explore all the photos that our community of
                users has uploaded on all your devices by logging into the
                browser of your choice.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    <Box mb={4}>
      <Heading2>Manage your account</Heading2>

      <Accordion collapsible>
        <AccordionItem>
          <AccordionButton>Sign in / Sign up to The Story Of</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                To unlock the full experience that TSO offers, you need to have
                an account and be logged in. This allows you to upload images,
                create collections, post or contribute to a request and comment
                on photographs.
              </p>
              <p>
                To create an account, click on the ‘Sign up’ link in the
                navigation bar and fill in the requested fields.
              </p>
              <p>
                Then, check your email inbox and click the link to confirm your
                email address.
              </p>
              <p>
                If you already have an account, click the ‘Sign in’ link and
                enter your email address and password to log in.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Forgot your password?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                Use your account&apos;s username and password to log into The
                Story Of.
              </p>
              <p>
                If that does not work or if you can’t remember the password you
                have used, click the ‘Forgotten password’ link found at the
                bottom of the Login page.
              </p>
              <p>
                Enter your email address so we can send you a link to reset your
                password.
              </p>
              <p>
                Then, check your email inbox and follow the password reset
                instructions
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>Access & Edit profile page</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                To access you profile page you will need to be logged in. Once
                you are, click on your profile picture in the main navigation
                and you will see your profile.
              </p>
              <p>
                On your profile page, click ‘Edit profile’ to change the
                information that are displayed. This includes:
              </p>

              <ul>
                <li>Your profile picture</li>
                <li>Your name and surname</li>
                <li>Your email address</li>
                <li>
                  Your website URL – this is an optional field if you want to
                  promote your business
                </li>
              </ul>
              <p>
                When you&apos;re done with making changes, click ‘Save changes’.
              </p>
              <p>
                For quicker access to account settings, click on your profile
                picture to trigger the account dropdown, then select ‘Account
                settings’.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>Adjust email notifications</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                Email notifications ensure you never miss anything that is
                published on The Story Of. You can manage the type of
                notifications and their frequency in your ‘Account Settings’.
              </p>
              <p>
                Adjust the setting by selecting your preferred option. When you
                are satisfied with the updates, ‘<b>Save preferences</b>’ by
                clicking the button at the bottom of the page.
              </p>
              <p>
                You may deactivate email notifications, but you will still get
                in-service notifications accessed under the notification menu.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>Customise your interests</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                We want to make sure you see photographs of the places that
                you’re interested in: you can choose preferences that will make
                certain topics, places or time periods feature more prominently
                in your feed.
              </p>

              <p>
                In order to do so, click on your profile picture in the top bar
                and navigate your way to the ‘Customise your interest’ section.
                Then, select the themes you are interested in.
              </p>

              <p>
                If you want to see how places that matter to you have evolved
                through time, you can enter more detailed information (i.e. See
                how the school you went to looks like today)
              </p>

              <p>Once you’re happy, click on ‘Save preferences’.</p>
              <p>
                You can review this information at any time by following the
                same process.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Sign out</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                To sign out of your account, click on your profile picture in
                the top navigation and select ‘Log out’ in the account menu.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Delete your account</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                To delete your account, click on your profile picture to access
                the account menu and select ‘Account settings’. At the bottom of
                the page, click on the ‘Delete Account’ button and follow the
                instructions on screen before confirming your decision.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    <Box mb={4}>
      <Heading2>Upload / Add images</Heading2>

      <Accordion collapsible>
        <AccordionItem>
          <AccordionButton>How can I scan printed photos?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                If you have a printer at home it might have the ability to scan.
                If not, you can always go to a local library or copy point and
                use a scanner there.
              </p>
              <ul>
                <li>
                  Scan a photo using a scanner:
                  <ul>
                    <li>
                      First, remove any dust or dirt from your photos with a
                      microfiber cloth and clean the scanner glass.
                    </li>
                    <li>
                      Scan one photo at a time or multiple photos at once to cut
                      down scanning time.
                    </li>
                    <li>
                      Once your photos are scanned, open them on your computer
                      to crop them and only keep the photos themselves. If you
                      have scanned multiple photos at a time, make sure to keep
                      each photo individually before uploading it to The Story
                      Of.
                    </li>
                  </ul>
                </li>
                <li>
                  <p> To scan a photo using your phone:</p>
                  <p>
                    Most phone cameras are of good enough quality that they can
                    be used to digitse printed photos. You can choose to use the
                    camera app that comes with your phone or consider using
                    dedicated scanning apps to ensure better results.
                  </p>

                  <ul>
                    <li>
                      Dust off your photos with a microfiber cloth, or an
                      alcohol-based cleaning wipe for really dirty prints.
                    </li>
                    <li>
                      Find a well-lit area preferably with natural light and
                      avoiding shadows.
                    </li>
                    <li>
                      Clean your phone&apos;s camera lens with a microfiber
                      cloth, or a cotton swab and a drop of isopropyl alcohol.
                    </li>
                    <li>
                      When taking the photo, make sure there is no reflection or
                      objects obstructing part of the photo
                    </li>
                    <li>
                      Review the photo and crop it before uploading it to The
                      Story Of.
                    </li>
                  </ul>
                </li>
              </ul>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>How can I add photos to TSO?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <ul>
                <li>
                  To add a photo, click on ‘Add images’ in the top navigation
                  bar.
                </li>
                <li>
                  Select the image you would like to upload by clicking ‘Select
                  a photo’, choose the photo on your machine and validate.
                  Remember you can only upload one photo at a time.
                </li>
                <li>
                  Give your photo a Title and a Description to give some
                  information about the photo’s location and date. If you don’t
                  know, don’t worry you can always add more details later.
                </li>
                <li>
                  Click ‘Upload’. At this point, the system will analyse the
                  photo to try to identify any observable landmark that would
                  help identify the location and date.
                </li>
                <li>
                  Review the system’s suggestion and edit where you feel
                  appropriate. If no location or date has been found, tick the
                  ‘I don’t know’ box to ask the community for their help. The
                  image will be uploaded and flagged as ‘Unidentified’ for
                  people to contribute to.
                </li>
                <li>
                  You can also give your photo a category (e.g. ‘religious
                  building’ or ‘shop’) to improve its discovery.
                </li>
              </ul>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            Can I upload a photo that doesn’t belong to me?
          </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                You should only upload images that you own or that you&apos;re
                authorised to use. If you upload someone else&apos;s content
                without their permission, the copyright owner can submit a
                takedown request to us and action may be taken against your
                account. You could also face a copyright claim from the
                copyright owner.
              </p>
              <p>
                If someone else uploads your content without your permission,
                you can file a copyright complaint{' '}
                <a
                  href="https://www.archant.co.uk/articles/privacy-policy"
                  rel={'nofollow'}>
                  here
                </a>
                .
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>What kind of photos do we accept?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                The Story Of is predominantly a location-based living visual
                history platform. As such, the main intent is to upload
                photographs of places. While we still very much welcome photos
                with people in them, it is preferable that the places form a
                major component to the overall image.
              </p>
              <p>
                Whether archive photos or more recent footage, we’re interested
                in any kind of photos as long as it helps us collect a snapshot
                of a place at a particular time.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            Where can I see a photo I have uploaded?
          </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <ul>
                <li>
                  <b>In your account:</b>
                  <p>
                    You can access your account by clicking on your profile
                    picture in the main navigation bar and selecting ‘My
                    profile’ in the dropdown menu. On your profile, you will see
                    all the photos you have uploaded with the ability to add
                    more photos or to manage the existing ones.
                  </p>
                </li>
                <li>
                  <b>Searching for a location using the search bar:</b>
                  <p>
                    Type in the location of the photo you are looking for,
                    select from the dropdown options and search. You will be
                    able to see all of the images available for this place.
                  </p>
                </li>
                <li>
                  <b>Unidentified photos:</b>
                  <p>
                    If you have uploaded a photo without a location or date, it
                    would have been listed as part of the ‘Unidentified’
                    section. This means it is publicly shared with the community
                    for them to help fill in the missing information.
                  </p>
                </li>
              </ul>
              <p>
                Still can’t find a photo you have uploaded?{' '}
                <a
                  href="https://www.archant.co.uk/articles/privacy-policy"
                  rel={'nofollow'}>
                  Get in touch
                </a>
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Why can’t I upload my photo?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                We accept most photos but there are some cases when we may block
                you from uploading a photo if we think it can be detrimental to
                TSO concept or brand values.
              </p>
              <ul>
                <li>
                  <b>Unsupported format:</b>
                  <p>
                    we don’t accept images over 20mb to ensure we can provide
                    you with an optimal experience
                  </p>
                </li>
                <li>
                  <b>Unsupported content:</b>
                  <p>
                    this includes but is not limited to nudity, violence,
                    propaganda, false information, and content that is not in
                    the spirit of The Story Of community.
                  </p>
                </li>
              </ul>
              <p>
                If you still can’t figure out why you can’t upload a photo, we
                may be experiencing a technical issue. Please report it by{' '}
                <a
                  href="https://www.archant.co.uk/articles/privacy-policy"
                  rel={'nofollow'}>
                  getting in touch.
                </a>
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    <Box mb={4}>
      <Heading2>Request</Heading2>

      <Accordion collapsible>
        <AccordionItem>
          <AccordionButton>
            What can I request the community to help me with?
          </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                If you feel like a particular place or time period deserves to
                be covered on The Story Of but don&apos;t have any pictures of
                them, you can ask other users to help you out.
              </p>

              <p>
                To post a request, head to the ‘Request’ section available in
                the navigation bar at the top of the page. When creating a
                request, always try to explain your project and what you’re
                looking for so the other users can best contribute to your
                efforts.
              </p>
              <p>
                Examples of requests can be: ‘Help me tell the story of Cromer
                Pier in the 1960s’ or ‘Does anyone have any pictures of the
                Carrow Road football stadium in the 1990s?’.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>How can I contribute to a request?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                If you possess images that other users are looking for, you can
                contribute to their request by adding photos to their project.
              </p>
              <p>
                The photos you upload will then be added to your account as well
                as being discoverable as part of the main experience.
              </p>
              <p>
                Any contribution to a request helps build up the collection of
                visual stories that eventually will bring to life the remarkable
                history of places all around us.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            How can I see if someone has contributed to my request?
          </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <ul>
                <li>
                  <p>In the Notification panel</p>
                  <p>
                    The Notification panel can be accessed from the navigation
                    bar by clicking on the small bell-shaped button. Here you
                    can see updates on interactions, including any contributions
                    to your requests.
                  </p>
                </li>
                <li>
                  <p>In your account</p>
                  <p>
                    You can see and manage all your requests as part of the
                    ‘Request’ section in your account.
                  </p>
                </li>
              </ul>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Can I share a request?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                You can share a request to your social media channels, to make
                sure more people see it.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Can I edit a request?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                You can see and manage all your requests as part of the
                ‘Request’ section in your account.
              </p>
              <p>
                Find the request you would like to edit, click on the ‘edit’
                link. Then adjust the location and time period you would like
                the community to contribute to and save the changes.
              </p>
              <p>
                If you would like to delete a request, click on ‘Delete’ link.
                The request will not be discoverable anymore but the
                contributions will remain.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Can I delete a request?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>To ‘Delete a request’ please look at ‘Edit a request’.</p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    <Box mb={4}>
      <Heading2>Collections</Heading2>

      <Accordion collapsible multiple mb={4}>
        <AccordionItem>
          <AccordionButton>What are collections? </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                Collections are the best way to organise content around themes,
                places or time periods.
              </p>
              <p>
                Whether you’d like to capture how Great Yarmouth seafront has
                changed over time or gather photos of cinemas or famous film
                locations in Norfolk, collections are a great way to pay tribute
                to places that matter to you.
              </p>
              <p>
                By creating collections, you can tell your own story and share
                your passion about places with The Story Of community.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Create a collection</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                You can create a collection from scratch or directly from
                gathering content that is already in The Story Of.
              </p>
              <ul>
                <li>
                  <b>Creating a collection from scratch</b>
                  <p>
                    Head to your account by clicking on your profile picture in
                    the top navigation and select ‘My collections’.
                  </p>
                  <p>
                    Then click on the ‘Create a collection’ and follow the
                    process.
                  </p>
                  <p>
                    Alternatively you can also create a collection from the
                    Collection page itself. Just click on the Collection tab in
                    the top navigation. At the top of the page, you will find a
                    panel inviting you to ‘Create a collection’. Click on the
                    button and follow the process.
                  </p>
                </li>
                <li>
                  <b>Create a collection from an image</b>
                  <p>
                    Throughout your content exploration, you may come across
                    images that catch your attention that you will want to
                    collect.
                  </p>
                  <p>
                    When you find an image you like, hover over it to reveal an
                    action menu in the right hand corner of the image. Click on
                    it and select ‘Add to collection’ from the options
                    available. If you don’t have a collection created yet, you
                    can create one from there by clicking on ‘Create
                    collection’. Otherwise, just select the existing collection
                    you would like to add the image to.
                  </p>
                </li>
              </ul>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Add/Remove photos to a collection</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                You can add any image to a collection whether it is your own
                photo or someone else’s.
              </p>
              <p>
                When you find an image you like, hover over it to reveal an
                action menu in the right hand corner of the image. Click on it
                and select ‘Add to collection’ from the options available. If
                you don’t have a collection created yet, you can create one from
                there by clicking on ‘Create collection’. Otherwise, just select
                the collection you would like to add the image to.
              </p>
              <p>
                To remove an image from a collection, simply go to the
                collection in your profile. Identify the image you would like to
                remove, hover over the image to reveal the action menu and
                select ‘Remove’ from the options available.
              </p>
              <p>
                The image won’t show in the collection anymore but will still be
                available to discover as part of TSO experience.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>Edit a collection</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>A collection is made of three elements that you can edit:</p>
              <ul>
                <li>
                  <p>Cover picture</p>
                </li>
                <li>
                  <p>Title</p>
                </li>
                <li>
                  <p>Description</p>
                </li>
              </ul>
              <p>
                To edit any of these elements, navigate your way to your
                account, clicking on your profile picture in the top navigation.
                Then select ‘My collections’.
              </p>
              <p>
                Identify the collection you would like to edit, then click on
                ‘Edit collection’ and revise the information as you see fit.
              </p>
              <p>
                If you would like to delete a collection, click on ‘Delete’. The
                collection will be deleted but the images that featured in it
                will remain discoverable for the rest of the TSO users. If any
                of the photos are your own uploads, they will still feature as
                images in your account.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Who can see my collections?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                When you create a new collection, you can choose to make it:
              </p>
              <ul>
                <li>
                  <p>
                    <b>Public:</b> anyone on The Story Of will be able to
                    discover it. This mode is great if you want to publicly tell
                    a place’s story.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Private:</b> only you can see it. This is great for
                    organising photos around a topic.
                  </p>
                </li>
              </ul>
              <p>
                Whether a collection is public or private, the photos it
                contains are always visible to all users as part of the main
                experience.
              </p>
              <p>
                You can switch the access rights on a collection at any time in
                the settings section for each collection.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Share a collection</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                You can share any collections you particularly like by clicking
                on the ‘Share’ button available at the top of any collection
                page.
              </p>
              <p>
                Then select your preferred social channel, log in and share the
                collection.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Follow a collection</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                If you particularly like the images in a collection and would
                like your feed to feature more of this kind of content, you can
                also decide to follow a collection.
              </p>
              <p>
                You will get more photos you like and the collection owner will
                be notified of your support.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    <Box mb={4}>
      <Heading2>Unidentified</Heading2>

      <Accordion collapsible multiple mb={4}>
        <AccordionItem>
          <AccordionButton>What are unidentified photos?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                Information around a photo’s location or date is the key to
                ensure that everyone has the best experience revisiting these
                places in The Story Of.
              </p>
              <p>
                However, when recovering old photos, it can sometimes be hard to
                find out this information and this when the community can help.
              </p>
              <p>
                When an image has missing data, it is listed as part of the
                ‘Unidentified’ category and has a red corner visual cue for it
                to be easily recognisable amongst the rest of the content.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>How can I identify a photo?</AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                When an image has missing information, it is listed as part of
                the ‘Unidentified’ category and has a red corner visual cue for
                it to be easily recognisable amongst the rest of the content.
              </p>
              <p>
                Click on the image to get to the detail page. In the right hand
                panel, you will see the missing information highlighted along
                with a link to contribute to identifying the location and time.
              </p>
              <p>
                Click the red link to be taken to a contribution page. You will
                see other users’ contributions and suggestions as well as a
                comment field to join yourself. Any contribution posted will
                then be submitted to the image owner for them to review before
                updating the image information where they see fit.
              </p>
              <p>
                You can support the image owner in making a decision by upvoting
                or downvoting contributions.
              </p>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    <Box mb={4}>
      <Heading2>Invite</Heading2>

      <Accordion collapsible>
        <AccordionItem>
          <AccordionButton>
            How can I invite people to join TSO?
          </AccordionButton>
          <AccordionPanel>
            <BaseStyles>
              <p>
                Inviting friends to The Story Of is a fantastic way to bring the
                history of places around us to life: the more people on the
                service, the richer the experience becomes.
              </p>
              <p>
                Inviting friends is easy, just go to the ‘Invite’ section and
                decide your preferred option to invite them.
              </p>
              <ul>
                <li>
                  <p>
                    <b>Invite by email:</b> Type in the email address of the
                    person you would like to invite. If you would like to invite
                    multiple people at once, click on the ‘Add’ link and repeat
                    the previous step. Once you’re happy, click ‘Send invite’ to
                    submit your invitations.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Invite by link:</b> Click on your unique invite link and
                    paste into the messaging app of your choice and send. That’s
                    it!
                  </p>
                </li>
              </ul>
            </BaseStyles>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
    <p>&#169; 2020 Archant Community Media Ltd</p>
  </TextPage>
);

export default Help;
