import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ease from 'easy-ease';

import { ProgressCircle } from '@tso/tso-components';

const IMAGE_PROCESSED = gql`
  subscription imageProcessed($id: Int!) {
    imageProcessed(id: $id) {
      id
    }
  }
`;

const LOADING_STEPS = [
  {
    message: 'Processing image',
    start: 0,
    end: 0.15
  },
  {
    message: 'Finalising',
    start: 0.15,
    end: 0.6,
    percentage: 0.4
  },
  {
    message: 'Done',
    start: 0.8,
    end: 1
  },
  {
    message: 'This is taking longer than expected',
    start: 0.6,
    end: 0.8
  },
  {
    status: 'We think something has gone wrong',
    start: 0.8,
    end: 0.8
  }
];

const TIMES = {
  FINALISING: 10000,
  SORRY: 20000,
  FALLBACK: 30000
};

const Uploading = ({ id }) => {
  const { loading } = useSubscription(IMAGE_PROCESSED, {
    variables: { id: parseInt(id) }
  });

  const [step, setStep] = useState(0);
  const [percent, setPercent] = useState(0);

  const finalisingTimer = useRef();
  const sorryTimer = useRef();
  const fallbackTimer = useRef();

  useEffect(() => {
    const { start, end } = LOADING_STEPS[step];

    ease({
      startValue: start,
      endValue: end,
      onStep: value => setPercent(value),
      durationMs: 3000
    });
  }, [step]);

  useEffect(() => {
    if (loading) {
      finalisingTimer.current = setTimeout(() => setStep(1), TIMES.FINALISING);
      sorryTimer.current = setTimeout(() => setStep(3), TIMES.SORRY);
      fallbackTimer.current = setTimeout(() => setStep(4), TIMES.FALLBACK);
    } else {
      setStep(2);
    }

    return () => {
      clearTimeout(finalisingTimer.current);
      clearTimeout(sorryTimer.current);
      clearTimeout(fallbackTimer.current);
    };
  }, [loading]);

  return (
    <>
      {step === 2 && percent === 1 && <Redirect to={`/upload/${id}/edit`} />}
      <ProgressCircle
        status={
          LOADING_STEPS[step].message
            ? LOADING_STEPS[step].message
            : LOADING_STEPS[step].status
        }
        message={LOADING_STEPS[step].message}
        value={percent}
        icon={step === 2 ? 'check' : ''}
      />
    </>
  );
};

Uploading.propTypes = {
  match: PropTypes.object,
  id: PropTypes.number
};

export { IMAGE_PROCESSED, LOADING_STEPS, TIMES };
export default Uploading;
