import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation as useRouterLocation } from 'react-router-dom';
import { Box } from '@theme-ui/components';

import { SearchInput as Input } from '@tso/tso-components';

import {
  SearchArea,
  SearchCategories,
  SearchAutofill,
  SearchPopularLocations
} from './index';
import { useSearch, useLocation } from '../../hooks/index';

const SearchInput = () => {
  const { searchLocation, input, global_input, updateSearch } = useSearch();

  const history = useHistory();
  const location = useRouterLocation();

  // Show the search term if on the result page.
  const [searchValue, setSearchValue] = useState(
    location.pathname === '/results' ? input : ''
  );
  const [searchFocus, setSearchFocus] = useState(false);

  useEffect(() => {
    // When changing page, show the search term if traveled to results.
    setSearchValue(location.pathname === '/results' ? input : '');
  }, [location.pathname, input]);

  const node = useRef();

  const handleLocationDetails = async result => {
    await searchLocation({ variables: { ...result, input: searchValue } });
    history.push('/results');
  };

  const [getLocation] = useLocation(handleLocationDetails);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (global_input) {
      // Listen for when the global state of the search is updated.
      setSearchValue(global_input);
      setSearchFocus(true);

      // Reset the global search.
      updateSearch({ variables: { global_input: '' } });
    }
  }, [global_input, updateSearch]);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return setSearchFocus(true);
    }
    setSearchFocus(false);
  };

  const updateSearchValue = event => setSearchValue(event.target.value);

  const handleLocationSelect = async ({ value, label }) => {
    getLocation({ variables: { place_id: value } });
    setSearchValue(label);
    setSearchFocus(false);
  };

  const handlePopularLocation = location => setSearchValue(location);

  return (
    <div ref={node}>
      <form autoComplete="off">
        <Box sx={{ position: 'relative', zIndex: 0 }}>
          <Input
            name="search"
            label="Search"
            type="search"
            placeholder="Search for a place (Landmark, City, Region) in Norfolk"
            value={searchValue}
            onChange={updateSearchValue}
          />

          {searchFocus && !searchValue && (
            <SearchArea ariaLabel={'searchArea'}>
              <SearchCategories />
              <SearchPopularLocations
                handlePopularLocation={handlePopularLocation}
              />
            </SearchArea>
          )}

          {searchFocus && searchValue && (
            <SearchAutofill
              searchValue={searchValue}
              handleLocationSelect={handleLocationSelect}
            />
          )}
        </Box>
      </form>
    </div>
  );
};

export default SearchInput;
