"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _color = require("@theme-ui/color");

var messages = {
  positive: {
    bg: (0, _color.alpha)('success', 0.15)
  },
  negative: {
    bg: 'red.3'
  },
  warning: {
    bg: 'highlight'
  }
};
var _default = messages;
exports["default"] = _default;