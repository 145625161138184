const VALID_TYPES = [
  'image/jpeg',
  'image/jpeg',
  'image/tiff',
  'image/tiff',
  'image/webp',
  'image/bmp',
  'image/gif',
  'image/png'
];

const MAX_SIZES = {
  // Limit for image uploads for TSO (20MB).
  IMAGE: 20000000,
  // Limit for profile pictures (500KB).
  PROFILE: 500000
};

const isValidImageSize = image => image && image.size < MAX_SIZES.IMAGE;

const isValidProfileSize = image => image && image.size < MAX_SIZES.PROFILE;

const isValidType = image => image && VALID_TYPES.includes(image.type);

export { VALID_TYPES, isValidImageSize, isValidProfileSize, isValidType };
