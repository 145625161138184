import React from 'react';
import PropTypes from 'prop-types';

import { AsyncSelect } from '@tso/tso-components';

import { useFuzzyLocation } from '../hooks/index';

const GoogleSearch = React.forwardRef(({ onChange, ...rest }, ref) => {
  const { search } = useFuzzyLocation();

  return (
    <AsyncSelect
      ref={ref}
      isClearable
      placeholder="Give your photo a location"
      aria-label="locations"
      name="locations"
      id="location-select"
      onChange={onChange}
      loadOptions={search}
      {...rest}
    />
  );
});

GoogleSearch.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default GoogleSearch;
