"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Breakpoints
Breakpoints are CSS lengths intended for use in media queries. Commonly, the breakpoints scale is used to create mobile-first responsive media queries based on array values.
 */
var xs = '768px';
var s = '1024px';
var m = '1440px';
var l = '1600px';
var xl = '2200px';
var xxl = '2560px';
var breakpoints = [xs, s, m, l, xl, xxl];
var _default = breakpoints;
exports["default"] = _default;