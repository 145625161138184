import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text
} from '@tso/tso-components';

import { useAlert } from '../../hooks/index';

const DELETE_COLLECTION = gql`
  mutation deleteCollection($id: Int!) {
    deleteCollection(id: $id) {
      id
    }
  }
`;

const DeleteCollection = ({ id, buttonType = 'button' }) => {
  const [modal, setModal] = useState(false);

  const { showSuccess } = useAlert();

  const history = useHistory();
  const location = useLocation();
  const [deleteCollection] = useMutation(DELETE_COLLECTION, {
    refetchQueries: ['collections', 'privateCollections', 'userCollections']
  });

  const toggleModal = () => setModal(modal => !modal);

  const handleDeleteCollection = async () => {
    await deleteCollection({ variables: { id } });

    // Only redirect if on the collections page.
    if (location.pathname === `/collections/${id}`) {
      history.push('/collections');
    }

    showSuccess('Collection deleted successfully');
    toggleModal();
  };

  return (
    <>
      {buttonType === 'button' && (
        <Button onClick={toggleModal} appearance="link">
          Delete
        </Button>
      )}
      {buttonType === 'list' && (
        <ListItem label="Delete" onClick={toggleModal} aria-label="Delete">
          Delete
        </ListItem>
      )}
      <>
        <Modal isOpen={modal}>
          <ModalHeader>
            Are you sure you want to delete this collection?
          </ModalHeader>
          <ModalBody>
            <Text sx={{ textAlign: 'center' }}>
              By confirming you will be deleting the collection and all the
              photos it contains.
            </Text>
          </ModalBody>
          <ModalFooter sx={{ textAlign: 'center' }}>
            <Button
              onClick={handleDeleteCollection}
              aria-label={'deleteButton'}>
              Delete
            </Button>
            <Button onClick={toggleModal} appearance="link">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </>
  );
};

DeleteCollection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  buttonType: PropTypes.oneOf(['button', 'list'])
};

export default DeleteCollection;
export { DELETE_COLLECTION };
