import React, { useState } from 'react';
import { Box } from '@theme-ui/components';
import {
  ActionButton,
  Modal,
  ModalBody,
  ModalHeader
} from '@tso/tso-components';

import { MapPreview } from './index';

const ViewOnMap = ({ location }) => {
  const [open, setOpen] = useState(false);

  const { latitude, longitude } = location;

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => setOpen(true);

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <>
      <ActionButton
        icon="location"
        label="View on map"
        aria-label="view-on-map"
        mobile
        onClick={openModal}>
        View on map
      </ActionButton>

      <Modal isOpen={open} toggle={closeModal} size="medium" isMap>
        <ModalHeader toggle={closeModal} style={{ paddingTop: 0 }} />
        <ModalBody style={{ padding: 0 }}>
          <Box
            sx={{
              bg: 'gray.4',
              order: [null, 1],
              flex: [null, 'none'],
              height: ['90vh', null, 380],
              overflow: 'hidden'
            }}>
            <MapPreview
              sx={{
                size: '100%'
              }}
              latitude={latitude}
              longitude={longitude}
            />
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewOnMap;
