import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@theme-ui/components';

import { LineChart } from '@tso/tso-components';

const GET_YEAR_TOTAL = gql`
  query countYears($start: String, $end: String) {
    countYears(start: $start, end: $end) {
      year
      value
    }
  }
`;

const Background = ({ range }) => {
  const [start, end] = range;

  const { data, loading, error } = useQuery(GET_YEAR_TOTAL, {
    variables: { start: start.toString(), end: end.toString() }
  });

  if (loading || error) {
    return null;
  }

  return (
    <Box>
      <LineChart start={start} end={end} years={data.countYears} />
    </Box>
  );
};

Background.propTypes = {
  range: PropTypes.arrayOf(PropTypes.number)
};

export default Background;
export { GET_YEAR_TOTAL };
