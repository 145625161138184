import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  List,
  ListItem
} from '@tso/tso-components';

const options = [
  { value: 'NEWEST_CREATED', label: 'Newest Uploaded' },
  { value: 'OLDEST_CREATED', label: 'Oldest Uploaded' },
  { value: 'NEWEST', label: 'Newest Year' },
  { value: 'OLDEST', label: 'Oldest Year' }
];

const SortBy = ({ block, right, value, handleChange }) => {
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown(prevState => !prevState);

  const handleSelect = value => {
    handleChange(value);
    toggleDropdown();
  };

  return (
    <Dropdown isOpen={dropdown} toggle={toggleDropdown} block={block}>
      <DropdownToggle block={block}>{value.label}</DropdownToggle>
      <DropdownMenu right={right} block={block}>
        <List>
          {options.map(({ value, label }) => (
            <ListItem
              key={value}
              onClick={() => handleSelect({ value, label })}>
              {label}
            </ListItem>
          ))}
        </List>
      </DropdownMenu>
    </Dropdown>
  );
};

SortBy.propTypes = {
  block: PropTypes.bool,
  right: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  handleChange: PropTypes.func.isRequired
};

export default SortBy;
export { options };
