import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Link1Medium } from '@tso/tso-components';

import { useAlert } from '../../hooks/index';
import Report from '../Report';

const REPORT_USER = gql`
  mutation reportUser($id: Int!, $problem_id: Int!, $message: String!) {
    reportUser(id: $id, problem_id: $problem_id, message: $message) {
      id
      user {
        id
      }
    }
  }
`;

const ReportUser = ({ id }) => {
  const [reportUser] = useMutation(REPORT_USER);

  const { showSuccess } = useAlert();

  const handleReport = async (message, problemId) => {
    await reportUser({
      variables: { id: parseInt(id), problem_id: parseInt(problemId), message }
    });

    showSuccess('Report submitted');
  };

  return (
    <Report
      reportType={'User'}
      header="Report user"
      handleReport={handleReport}
      submitText="Report user"
      Submit={Link1Medium}
    />
  );
};

ReportUser.propTypes = {
  id: PropTypes.number.isRequired
};

export default ReportUser;
export { REPORT_USER };
