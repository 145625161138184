import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { Box } from '@theme-ui/components';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Button,
  FormGroup,
  Heading,
  Input,
  Link1Medium,
  Text1Regular
} from '@tso/tso-components';

import { AuthContainer } from '../../Components/index';
import { useEmailToken, useAlert, useAuth } from '../../hooks/index';

const RESET_PASSWORD = gql`
  mutation resetPassword($email: Email!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password)
  }
`;

const ResetPassword = ({ location }) => {
  const [password, setPassword] = useState('');

  const { email, token, isNotValid } = useEmailToken(location);
  const { isNotGuest } = useAuth();
  const { showSuccess } = useAlert();
  const [reset, { data: passwordReset }] = useMutation(RESET_PASSWORD);

  const handlePasswordChange = event => setPassword(event.target.value);

  const handleSubmit = async event => {
    event.preventDefault();
    await reset({ variables: { email, token, password } });
    showSuccess(
      'You have successfully changed your password and can now use it to login.'
    );
  };

  if (isNotValid) {
    return <Redirect to="/" />;
  }

  if (passwordReset) {
    return <Redirect to="/login" />;
  }

  return (
    <AuthContainer>
      {isNotGuest && <Redirect push to="/" />}
      <Box variant="layout.form">
        <form onSubmit={handleSubmit}>
          <Heading>Reset Password</Heading>
          <FormGroup>
            <Input
              type="email"
              placeholder="Email *"
              name="email"
              aria-label="email"
              value={email}
              disabled
            />
          </FormGroup>

          <FormGroup>
            <Input
              onChange={handlePasswordChange}
              type="password"
              placeholder="New Password *"
              name="password"
              aria-label="password"
              value={password}
            />
          </FormGroup>

          <Text1Regular mb={3}>
            <Link1Medium as={Link} to="/login" color="primary">
              Know your password?
            </Link1Medium>
          </Text1Regular>

          <Button
            disabled={!password}
            type="submit"
            aria-label="submit"
            isBlock>
            Submit
          </Button>
        </form>
      </Box>
    </AuthContainer>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired
};

export default ResetPassword;
export { RESET_PASSWORD };
