"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var images = {
  avatar: {
    borderRadius: 'circle'
  }
};
var _default = images;
exports["default"] = _default;