import React from 'react';

import { IconButton } from '@tso/tso-components';

// up represents sorting value for when arrow is up
// handleSort will call the passed in handler with the opposite sorting state to act as a switch
const SortToggle = ({ sort, handleSort }) => {
  const { current, up, down } = sort;
  const onClick = () => handleSort(current === up ? down : up);
  const icon = current === up ? 'up' : 'down';
  return <IconButton {...{ onClick, icon }} />;
};

export default SortToggle;
