"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var comments = {
  neutral: {
    bg: 'background',
    border: 2,
    borderColor: 'background',
    '[data-id="comment"]': {
      bg: 'background'
    }
  },
  warning: {
    bg: 'highlight',
    border: 2,
    borderColor: 'highlight',
    '[data-id="comment"]': {
      bg: '#fefef9'
    }
  },
  positive: {
    bg: '#d9f5d9',
    border: 2,
    borderColor: '#d9f5d9',
    '[data-id="comment"]': {
      bg: 'background' // border: 1,
      // borderColor: 'gray.4'

    }
  }
};
var _default = comments;
exports["default"] = _default;