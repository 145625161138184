"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _breakpoints = _interopRequireDefault(require("./breakpoints"));

var _colors = _interopRequireDefault(require("./colors"));

var _fonts = _interopRequireDefault(require("./fonts"));

var _fontSizes = _interopRequireDefault(require("./font-sizes"));

var _fontWeights = _interopRequireDefault(require("./font-weights"));

var _mediaQueries = _interopRequireDefault(require("./media-queries"));

var _sizes = _interopRequireDefault(require("./sizes"));

var _styles = _interopRequireDefault(require("./styles"));

var _variants = _interopRequireDefault(require("./variants"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var space = [0, 5, 10, 20, 40, 80, 160];
space.headerHeightXXS = _sizes["default"].headerHeightXXS;
space.headerHeightXS = _sizes["default"].headerHeightXS;
space.small = space[1];
space.medium = space[2];
space.large = space[3];
var lineHeights = {
  none: 1,
  body: 1.5,
  heading: 1.125,
  tight: 1.25
};
var borders = [0, '1px solid', '2px solid', '4px solid'];
var borderWidths = [0, 1, 2, 4];
borderWidths["default"] = borderWidths[1];
var borderStyles = {
  solid: 'solid',
  foo: 'dashed'
};
borderStyles["default"] = borderStyles.solid;
var radii = {
  "default": 14,
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50%',
  circle: 999999
};
var shadows = {
  "default": '0 0 14px 0 rgba(0, 0, 0, 0.10)',
  circle: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
}; // const transitions = {
//   default: 'all 100ms ease-in-out'
// };

var zIndices = [0, 1, 2, 3, 4];

var tsoDefault = _objectSpread({
  /* Enables CSS custom properties to help mitigate a flash of unstyled content 
  during rehydration */
  useCustomProperties: true,

  /* Adds styles defined in theme.styles.root to the <body> element along with color and background-color */
  useBodyStyles: true,

  /* The key used for the top-level color palette in theme.colors */
  initialColorModeName: 'light',

  /* initializes the color mode based on the prefers-color-scheme media query */
  useColorSchemeMediaQuery: false,

  /* Adds a global box-sizing: border-box style */
  useBoxSizing: false,
  space: space,
  fontSizes: _fontSizes["default"],
  colors: _colors["default"],
  fonts: _fonts["default"],
  fontWeights: _fontWeights["default"],
  lineHeights: lineHeights,
  // letterSpacings,
  sizes: _sizes["default"],
  borders: borders,
  borderWidths: borderWidths,
  borderStyles: borderStyles,
  radii: radii,
  shadows: shadows,
  zIndices: zIndices,
  breakpoints: _breakpoints["default"],
  mediaQueries: _mediaQueries["default"],
  styles: _styles["default"]
}, _variants["default"]);

var _default = tsoDefault;
exports["default"] = _default;