import gql from 'graphql-tag';

const detailedLike = gql`
  fragment DetailedLike on Like {
    id
    likeable_id
    user {
      id
    }
    positive
  }
`;

export { detailedLike };
